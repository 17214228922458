import { Button, Chip, Dialog, FormControl, InputLabel, MenuItem, Select, TextField } from "@mui/material"
import axios from "axios";
import { useEffect, useState } from "react";
import { useSnackbar } from "notistack";
import ISwitch from "Elements/ISwitch";
import AutocompleteField from "Elements/AutocompleteField";
import ImageUploader from 'Elements/ImageUploader';
import { ProjectConfig } from 'Global';

export default function UsersEditor(props : any) {
  const {
    setLoading, 
    openEditor, 
    setOpenEditor, 
    user_id,
    loadList,
    company_id = null,
  } = props;

  const { enqueueSnackbar } = useSnackbar();

  const defaultValues: any = {
    user_id: user_id ? user_id : null,
    name: "",
    last_name: "",
    phone: "",
    email: "",
    password: "",
    is_active: true,
    role_id: 2,
    company_id: company_id,
    image: "",
    regular_cost: "0.00",
    extra_cost: "0.00",
    restrictions: {},
    roles: [],
  };
  
  const [formValues, setFormValues] = useState(defaultValues);
  const [roles, setRoles] = useState([]);
  const [files, setFiles] = useState<any>([]);




  /*=======================================
  GET USER DETAILS IF USER_ID EXISTS
  =======================================*/
  useEffect(() => {
    if (user_id && openEditor) {
      axios.get(ProjectConfig.api_url + "/users/details", {
        headers: {
          "ltkn": localStorage.getItem('ltkn')
        },
        params: {
          user_id: user_id,
        },
      })
      .then((response) => {
        if (response.data.status === "success") {
          setFormValues(response.data.data);
        }
      })
      .catch((err) => {
        console.log(err.response.data);
      });
    } else {
      setFormValues(defaultValues);
      setFiles([]);
    }
  }, [user_id, openEditor]);

  /*=======================================
  HANDLE INPUT CHANGE
  =======================================*/
  const handleInputChange = (e: { target: { name: any; value: any; }; }) => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };

  /*=======================================
  SUBMIT FUNCTION
  =======================================*/
  const handleSubmit = () => {  
    setLoading(true);
    const body = new FormData();
    body.append("formValues", JSON.stringify(formValues));
    if (files) {
      body.append("image", files);
    }

    axios.post(ProjectConfig.api_url+"/users/addedit", body, {
      headers: {
        "ltkn": localStorage.getItem("ltkn"),
      },
    })
    .then((response) => {
      if(response.data.status === "success"){
        enqueueSnackbar('Usuario guardado con éxito', { variant: 'success' });
        setOpenEditor(false);
        loadList();
      }else{
        enqueueSnackbar(response.data.message, { variant: 'error' });
      }
    })
    .catch((err) => {
      console.log(err.response.data);
    })
    .finally(() => {
      setLoading(false);
    });  
  };

  return (
    <Dialog 
      open={openEditor}
      onClose={() => setOpenEditor(false)}
      maxWidth="md"
      fullWidth
    >
      <div className="DialogContainer" style={{ display: 'flex', flexDirection: 'row', padding: '20px' }}>
        <div className="UserImageContainer" style={{ flex: 1 }}>
          <ImageUploader 
            className="user_image"
            image={formValues.image} 
            setImage={(newImage) => setFormValues({ ...formValues, image: newImage })}
            setFiles={setFiles} 
          />
        </div>

        <div className="UserFormContainer" style={{ flex: 2, marginLeft: '20px' }}>
          <h2 style={{ fontWeight: 'bold' }}>USUARIO</h2>
          
          <div style={{ display: 'flex', flexWrap: 'wrap', gap: '20px' }}>
            <div className='input_row'>
              <TextField 
                name="name"
                label="Nombres"
                fullWidth
                required
                value={formValues.name}
                onChange={handleInputChange}
              />

              <TextField
                name="last_name"
                label="Apellidos"
                fullWidth
                required
                value={formValues.last_name}
                onChange={handleInputChange}
              />
            </div>

            <div className='input_row'>
              <TextField
                name="email"
                label="Email"
                fullWidth
                required
                value={formValues.email}
                onChange={handleInputChange}
              />

              <TextField
                name="password"
                label="Password"
                type="password"
                fullWidth
                value={formValues.password}
                onChange={handleInputChange}
              />
            </div>

            <div className='input_row'>
              <TextField
                name="phone"
                label="Teléfono"
                fullWidth
                required
                value={formValues.phone}
                onChange={handleInputChange}
              />

              <TextField
                name="regular_cost"
                label="Costo Regular"
                type="number"
                fullWidth
                required
                value={formValues.regular_cost}
                onChange={handleInputChange}
              />

              <TextField
                name="extra_cost"
                label="Costo Extra"
                type="number"
                fullWidth
                required
                value={formValues.extra_cost}
                onChange={handleInputChange}
              />
            </div>

            <AutocompleteField
              label="Role"
              endpoint="/users/rolesList"
              value={formValues.role_id}
              onChange={(data: any) => {
                setFormValues({ ...formValues, role_id: data.value });
              }}
            />

            <ISwitch
              label="Activo"
              checked={formValues.is_active}
              onChange={(e: any) => {
                setFormValues({
                  ...formValues,
                  is_active: e.target.checked
                });
              }}
            />
          </div>

          <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '20px' }}>
            <Button
              onClick={() => setOpenEditor(false)}
              variant="outlined"
              style={{ marginRight: '10px' }}
            >
              CANCELAR
            </Button>
            <Button onClick={handleSubmit} variant="contained" color="secondary">
              GUARDAR
            </Button>
          </div>
        </div>
      </div>
    </Dialog>
  );
}
