import { Button } from "@mui/material";
import { Link, useLocation } from "react-router-dom";

export default function MenuButton(props: any) {
  const { label, to, startIcon, onClick, key } = props;
  const location = useLocation();

  const checkActiveMenu = (href: string) => {
    return location.pathname.endsWith(href);
  };

  const styleMenu = {
    buttons: {
      textDecoration: 'none',
      padding: '10px 20px',
      justifyContent: 'start',
      color: '#637381',
      textTransform: 'none',
      '& svg': {
        width: '25px',
        height: '25px',
        marginRight: '10px',
        color: checkActiveMenu(to) ? '#ffffff' : '#637381',
      },
    },
  };

  return (
    <Button
      key={key}
      component={Link}
      startIcon={startIcon}
      to={to}
      style={{ borderRadius: '0' }}
      sx={styleMenu.buttons}
      onClick={onClick}
      className={checkActiveMenu(to) ? 'menu_button active_menu' : 'menu_button'}
    >
      {label}
    </Button>
  );
}
