import * as React from 'react';
import MenuButton from './MenuButton';
import StorefrontOutlinedIcon from '@mui/icons-material/StorefrontOutlined';
import ReceiptIcon from '@mui/icons-material/Receipt';
import { Link, useLocation } from 'react-router-dom';
import { MenuItem } from '@mui/material';

export default function CookerMenu(props: any) {
    const { setLoading } = props;
    const location = useLocation();
    const [selectedMenu, setSelectedMenu] = React.useState<string[]>(['restaurant', 'team']);

    const handleMenuToggle = (menuName: string) => {
        setSelectedMenu((prevSelectedMenu) =>
        prevSelectedMenu.includes(menuName)
            ? prevSelectedMenu.filter((name) => name !== menuName)
            : [...prevSelectedMenu, menuName]
        );
    };

    const isSubItemSelected = (menuName: string) => {
        const subItems: { [key: string]: string[] } = {
        restaurant: ['/restaurants', '/tables', '/inventory', '/categories', '/products', '/expenses', '/orders'],
        team: ['/employees', '/shifts', '/payroll']
        };
        return subItems[menuName]?.includes(location.pathname);
    };

    return (
        <>
            <div className='menu_button_container'>
                <MenuButton 
                    label="Restaurant" 
                    startIcon={<StorefrontOutlinedIcon />} 
                    setLoading={setLoading} 
                    onClick={() => handleMenuToggle('restaurant')}
                    isActive={isSubItemSelected('restaurant')}
                />
            </div>

            {selectedMenu.includes('restaurant') && (
                <div className="submenu">
                    <MenuItem component={Link} to="/orders">
                        <ReceiptIcon style={{ marginRight: '8px' }} />
                        Orders
                    </MenuItem>
                </div>
            )}
        </>
    );
}