import { useEffect, useState } from 'react';
import Box from 'Elements/Box';
import { Button, IconButton, TextField } from '@mui/material';
import { useSnackbar } from 'notistack';

import EmptyElement from 'Elements/EmptyElement';
import { ApiRequest } from 'GlobalFunctions';


import { BsGrid3X2 } from "react-icons/bs";
import { MdOutlineViewList } from "react-icons/md";

import { useParams, useNavigate } from 'react-router-dom';
import ProductEditor from './components/ProductEditor';
import Summary from './components/Summary';
import { IoRestaurantOutline } from "react-icons/io5";
import { CiCircleCheck } from "react-icons/ci";
import logo from 'Assets/img/logo3.png';
import { FiMinus, FiPlus } from 'react-icons/fi';


export default function SellOrder(props: any) {
    const { product_id, table_id, order_id } = useParams();
    const navigate = useNavigate();

    const { setLoading } = props;
    const { enqueueSnackbar } = useSnackbar();

    const [productsList, setProductsList] = useState<any[]>([]);
    const [viewMode, setViewMode] = useState<'grid' | 'list'>('grid');
    const [searchTerm, setSearchTerm] = useState('');
    const [selectedProduct, setSelectedProduct] = useState(null);
    const [openEditor, setOpenEditor] = useState(false);
    const [currentOrder, setCurrentOrder] = useState<any>({
        products: [],
        table_id: Number(table_id) || 0, // Ensure table_id is set
        total: 0
    });
    const [selectedCategory, setSelectedCategory] = useState<number | null>(null);
    const [orderId, setOrderId] = useState<number | null>(null); // Estado para order_id

    const handleImageError = (event: any) => {
        event.target.src = logo;
    };

    useEffect(() => {
        loadList();
    }, []);

    useEffect(() => {
        if (order_id) {
            // Si hay order_id en los parámetros, cargamos los detalles de la orden
            fetchOrderDetails(Number(order_id));
        }
    }, [order_id]);

    const loadList = async () => {
        setLoading(true);

        await ApiRequest({
            url: '/products/orderProducts',
            method: 'get',
            query:{
                table_id: currentOrder.table_id
            },
            setResponse: (response: any) => {
                setProductsList(response.list);
                setLoading(false);
            },
        });
    };

    const fetchOrderDetails = async (id: number) => {
        setLoading(true);
        await ApiRequest({
            url: `/orders/details`,
            method: 'get',
            query: { order_id: id },
            setResponse: (response: any) => {
                setCurrentOrder({
                    ...response.order,
                    table_id: response.order.table_id || currentOrder.table_id // Ensure table_id is set
                });
                setOrderId(id);
                setLoading(false);
            },
        });
    };

    const filteredProducts = productsList.filter(product => {
        const matchesSearch = product.product_name.toLowerCase().includes(searchTerm.toLowerCase());
        const matchesCategory = selectedCategory === null || product.category_id === selectedCategory;
        return matchesSearch && matchesCategory;
    });

    const handleProductClick = (product: any) => {
        if (currentOrder.order_status !== 'success') {
            setSelectedProduct(product);
            setOpenEditor(true);
        } else {
            enqueueSnackbar('Cannot add products to a completed order', { variant: 'warning' });
        }
    };

    const handleAddToOrder = (orderItem: any) => {
        console.log(orderItem)
        setCurrentOrder((prev: any) => ({
            ...prev,
            products: [...prev.products, orderItem],
            total: Number(prev.total) + Number(orderItem.total) // Ensure total is treated as a number
        }));
    };

    const handleUpdateItem = (updatedItem: any) => {
        const updatedProducts = currentOrder.products.map((item: any) =>
            item.product.product_id === updatedItem.product.product_id ? updatedItem : item
        );
        const newTotal = updatedProducts.reduce((sum: any, item: any) => sum + Number(item.total), 0); // Ensure total is treated as a number
        setCurrentOrder({ ...currentOrder, products: updatedProducts, total: newTotal });
        setOpenEditor(false);
    };

    const handleOrderUpdate = (updatedOrder: any) => {
        setCurrentOrder(updatedOrder);
    };

    const handleSaveOrder = (id: number) => {
        setOrderId(id); // Guardamos el order_id en el estado
        const currentUrl = window.location.pathname;
        const urlParts = currentUrl.split('/');
        if (urlParts.length === 4) {
            // URL is in the format /sell/:restaurant_id/:table_id
            navigate(`${currentUrl}/${id}`, { replace: true });
        } else if (urlParts.length === 5) {
            // URL is already in the format /sell/:restaurant_id/:table_id/:order_id
            navigate(currentUrl, { replace: true });
        }
    };

    const [categories, setCategories] = useState<any[]>([]);

    const getCategories = () => {
        ApiRequest({
            url: '/categories/orderCategories',
            method: 'get',
            setResponse: (response: any) => {
                setCategories(response.list);
            }
        });
    }

    useEffect(() => {
        getCategories();
    }, [productsList]);

    const handleQuantityChange = (product: any, quantity: number) => {
        if (quantity < 0) return;
    
        const existingProductIndex = currentOrder.products.findIndex(
            (item: any) => item.product.product_id === product.product_id
        );
    
        if (existingProductIndex !== -1) {
            if (quantity === 0) {
                const updatedProducts = currentOrder.products.filter(
                    (item: any) => item.product.product_id !== product.product_id
                );
                const newTotal = updatedProducts.reduce((sum: any, item: any) => sum + Number(item.total), 0);
                setCurrentOrder({ ...currentOrder, products: updatedProducts, total: newTotal });
            } else {
                const updatedProducts = [...currentOrder.products];
                updatedProducts[existingProductIndex].quantity = quantity;
                updatedProducts[existingProductIndex].total = quantity * product.sell_price;
    
                const newTotal = updatedProducts.reduce((sum: any, item: any) => sum + Number(item.total), 0);
                setCurrentOrder({ ...currentOrder, products: updatedProducts, total: newTotal });
            }
        } else {
            const newProduct = {
                product,
                quantity,
                total: quantity * product.sell_price
            };
            setCurrentOrder((prev: any) => ({
                ...prev,
                products: [...prev.products, newProduct],
                total: Number(prev.total) + newProduct.total
            }));
        }
    };

    const handleQuantityInputChange = (product: any, value: string) => {
        const quantity = Math.max(0, Number(value));
        handleQuantityChange(product, quantity);
    };

    return (
        <>
            {order_id && (
                <div className={`table-status ${currentOrder.order_status === 'success' ? 'order-completed' : 'order-in-progress'}`}>
                    {currentOrder.order_status === 'success' ? (
                        <CiCircleCheck style={{ fontSize: '1.4rem', marginRight: '10px' }} />
                    ) : (
                        <IoRestaurantOutline style={{ fontSize: '1.4rem', marginRight: '10px' }} />
                    )}
                    {currentOrder.order_status === 'success' ? 'Order completed' : 'Order in progress'}
                </div>
            )}
            <div id="OrderList" className="ModuleContainer">
                <div className="ModuleWrapper">

                    {currentOrder.order_status !== 'success' && (
                    <div id="products_grid_in">
                        <div className="product_filters">
                            <IconButton 
                                onClick={() => setViewMode('grid')}
                                sx={{
                                    backgroundColor: viewMode === 'grid' ? '#32B86C' : '#A19B9B',
                                    color: '#fff',
                                    borderRadius: '4px',
                                    '&:hover': {
                                        backgroundColor: viewMode === 'grid' ? '#32B86C' : '#A19B9B',
                                    }
                                }}
                            >
                                <BsGrid3X2 />
                            </IconButton>
                            <IconButton 
                                onClick={() => setViewMode('list')}
                                sx={{
                                    backgroundColor: viewMode === 'list' ? '#32B86C' : '#A19B9B',
                                    color: '#fff',
                                    borderRadius: '4px',
                                    '&:hover': {
                                        backgroundColor: viewMode === 'list' ? '#32B86C' : '#A19B9B',
                                    }
                                }}
                            >
                                <MdOutlineViewList />
                            </IconButton>
                            <TextField 
                                className="product_filters_search" 
                                label="Product search"
                                fullWidth
                                size='small'
                                variant="outlined"
                                value={searchTerm}
                                onChange={(e) => setSearchTerm(e.target.value)}
                            />
                        </div>
                        

                        <div className='section'>
                            <h3 className='h3_title'>CATEGORIES</h3>
                            <div className="product_categories_filter">
                                <Button 
                                    color="primary"
                                    size="small"
                                    onClick={() => setSelectedCategory(null)}
                                    sx={{
                                        backgroundColor: selectedCategory === null ? '#32B86C' : '#fff',
                                        padding: 0,
                                        borderRadius: '5px',
                                        overflow: 'hidden',
                                        boxShadow: '0 0 5px rgba(0, 0, 0, 0.1)',
                                        '& .category_button span': {
                                            color: selectedCategory === null ? '#fff' : '#000'
                                        },
                                        '&:hover .category_button span': {
                                            color: '#000'
                                        }
                                    }}
                                >
                                    <div className='category_button'>
                                        <span>ALL</span>
                                    </div>
                                </Button>

                                {categories.map((category, i) => (
                                    <Button 
                                        key={i}
                                        color="primary"
                                        size="small"
                                        onClick={() => setSelectedCategory(category.category_id)}
                                        sx={{
                                            backgroundColor: selectedCategory === category.category_id ? '#32B86C' : '#fff',
                                            padding: 0,
                                            borderRadius: '5px',
                                            overflow: 'hidden',
                                            boxShadow: '0 0 5px rgba(0, 0, 0, 0.1)',
                                            '& .category_button_text': {
                                                color: selectedCategory === category.category_id ? '#fff' : '#000'
                                            },
                                            '&:hover .category_button_text': {
                                                color: '#000'
                                            }
                                        }}
                                    >
                                        <div className='category_button'>
                                            <img 
                                                src={category.image || logo} 
                                                alt={category.category_name} 
                                                style={{ width: '50px', height: '50px', objectFit: 'cover' }}
                                                onError={handleImageError}
                                            />
                                            <span className='category_button_text'>{category.name}</span>
                                        </div>
                                    </Button>
                                ))}
                            </div>
                        </div>
                        

                        <div className='section'>
                            <h3 className='h3_title'>PRODUCTS</h3>
                            {filteredProducts.length !== 0 ? (
                                <div className={`products_${viewMode}`}>
                                    {filteredProducts.map((product, i) => {
                                        const existingProduct = currentOrder.products.find(
                                            (item: any) => item.product.product_id === product.product_id
                                        );
                                        const quantity = existingProduct ? existingProduct.quantity : 0;
                                
                                        return (
                                            <div className="product_card_wrapper" key={i}>
                                                <Box
                                                    className="product_card"
                                                    onClick={() => handleProductClick(product)}
                                                >
                                                    <img 
                                                        src={product.image || logo} 
                                                        alt="product" 
                                                        onError={handleImageError} 
                                                    />
                                                    <div className='product_information'>
                                                        <h2>{product.product_name}</h2>
                                                        <span className="product_price">$ {product.sell_price.toLocaleString()}</span>
                                                    </div>
                                                    <div className='product_actions'>
                                                        <Button
                                                            variant="contained"
                                                            color="error"
                                                            size='small'
                                                            sx={{
                                                                minWidth: '40px',
                                                                minHeight: '33px',
                                                                backgroundColor: 'black',
                                                                '&:hover': {
                                                                    backgroundColor: 'black',
                                                                }
                                                            }}
                                                            onClick={(e) => { e.stopPropagation(); handleQuantityChange(product, quantity - 1); }}
                                                        >
                                                            <FiMinus/>
                                                        </Button>
                                                        <input 
                                                            type="number" 
                                                            value={quantity} 
                                                            onClick={(e) => e.stopPropagation()}
                                                            onChange={(e) => handleQuantityInputChange(product, e.target.value)}
                                                            style={{ 
                                                                width: '45px', 
                                                                textAlign: 'end', 
                                                                border: '1px solid #ccc', 
                                                                borderRadius: '5px',
                                                                margin: '0 5px' 
                                                            }} 
                                                        />
                                                        <Button
                                                            variant="contained"
                                                            color="secondary"
                                                            size='small'
                                                            sx={{
                                                                minWidth: '40px',
                                                                minHeight: '33px'
                                                            }}
                                                            onClick={(e) => { e.stopPropagation(); handleQuantityChange(product, quantity + 1); }}
                                                        >
                                                            <FiPlus />
                                                        </Button>
                                                    </div>
                                                </Box>
                                            </div>
                                        );
                                    })}
                                </div>
                            ) : (
                                <div className="emptyList">
                                    <EmptyElement title="No products registered" subtitle="Start by adding a new product" />
                                </div>
                            )}
                        </div>
                    </div>
                    )}

                    <Summary 
                        currentOrder={currentOrder} 
                        setCurrentOrder={setCurrentOrder}
                        onSaveOrder={handleSaveOrder} 
                        table_id={currentOrder.table_id} // Ensure table_id is passed
                    />
                </div>
            </div>

            <ProductEditor
                open={openEditor}
                onClose={() => setOpenEditor(false)}
                selectedProduct={selectedProduct}
                onAddToOrder={handleAddToOrder}
            />
        </>
    );
}