import React, { useState, useEffect } from 'react';
import { Button, Dialog, Divider } from '@mui/material';
import { ApiRequest } from 'GlobalFunctions';

interface OrderViewerDialogProps {
  open: boolean;
  onClose: () => void;
  orderId: number;
}

export default function OrderViewerDialog({ open, onClose, orderId }: OrderViewerDialogProps) {
  const [orderDetails, setOrderDetails] = useState<any>(null);

  useEffect(() => {
    if (orderId && open) {
      fetchOrderDetails();
    }
  }, [orderId, open]);

  const fetchOrderDetails = async () => {
    await ApiRequest({
      url: '/orders/details',
      method: 'get',
      query: { order_id: orderId },
      setResponse: (response: any) => {
        if (response.status === 'success') {
          setOrderDetails(response.order);
        }
      },
    });
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <div className="DialogContainer" style={{ padding: '20px' }}>
        {orderDetails && (
          <>
            <div className="input_group">
              <h2 className='h2_title'>Summary</h2>
              <div className="ordered-products">
                {orderDetails.products.map((item: any, index: number) => (
                  <div key={index} className="ordered-product-item">
                    <div className="product-row-info">
                      <img src={item.product.image} alt={item.product.product_name} className="product-thumbnail" />
                      <div className="product-details"> 
                        <h4>{item.product.product_name} x {item.quantity}</h4>
                        <span className="summary-product-price">$ {item.total.toLocaleString()}</span>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <Divider />
            <div className="summary_total">
              <span className="summary_total_label">Total:</span>
              <span className="summary_total_value">$ {orderDetails.total.toLocaleString()}</span>
            </div>

            <div className='input_group'>
              <h2 className='h2_title'>Client Info</h2>
              <div className='client_info_dialog'>
                <div>
                  <strong>Name:</strong> {orderDetails.client.name}
                </div>
                {/* <div>
                  <strong>Email:</strong> {orderDetails.client.email}
                </div>
                <div>
                  <strong>Phone:</strong> {orderDetails.client.phone}
                </div> */}
              </div>
            </div>

            <div className="dialogButtons">
              <Button
                onClick={onClose}
                variant="outlined"
                style={{ marginRight: '10px' }}
              >
                Close
              </Button>
            </div>
          </>
        )}
      </div>
    </Dialog>
  );
}
