import Box from "Elements/Box";
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, Filler } from 'chart.js';
import { Line } from 'react-chartjs-2';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, Filler);

export default function IncomesPerMonth(props: any) {
  const { data } = props;

  const chartData = {
    labels: data.labels,
    datasets: [
      {
        label: 'Incomes Per Month',
        data: data.values,
        fill: true,
        backgroundColor: 'rgba(75, 192, 192, 0.2)',
        borderColor: '#4BC0C0',
        tension: 0.4,
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false, 
    plugins: {
      legend: {
        display: false,
      },
    },
  };

  return (
    <Box className='incomesPerMonth' title="Incomes Per Month" subtitle="Last 6 months">
      <Line data={chartData} options={options} />
    </Box>
  );
}
