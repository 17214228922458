import { useEffect, useState } from 'react';
import Box from 'Elements/Box';
import {Pagination } from '@mui/material';
import { useSnackbar } from 'notistack';
import EmptyElement from 'Elements/EmptyElement';
import { ApiRequest } from 'GlobalFunctions';
import AlertDialog from 'Elements/AlertDialog';
import { Link } from 'react-router-dom';
import { LiaStoreAltSolid } from "react-icons/lia";

export default function RestaurantList(props: any) {
  const { setLoading } = props;
  const { enqueueSnackbar } = useSnackbar();

  const [restaurantList, setRestaurantList] = useState<any[]>([]);
  const [pagination, setPagination] = useState({
    total_pages: 1,
    total_items: 0,
  });
  const [page, setPage] = useState(1);
  const [openEditor, setOpenEditor] = useState(false);
  const [activeItem, setActiveItem] = useState<any>(null);
  const [openAlert, setOpenAlert] = useState(false);
  const [menuAnchor, setMenuAnchor] = useState<null | HTMLElement>(null);
  const [filtersList, setFiltersList] = useState<any[]>([]);
  const [filtersValues, setFiltersValues] = useState<any>([]);

  useEffect(() => {
    loadList();
  }, [page, filtersValues]);

  const loadList = async () => {
    setLoading(true);

    await ApiRequest({
      url: '/restaurants/list',
      method: 'get',
      query: { page, filters: filtersValues },
      setResponse: (response: any) => {
        setRestaurantList(response.list);
        setPagination(response.pagination)
        setFiltersList(response.filtersList);
        setLoading(false);
      },
    });
  };

  const handleDelete = (restaurant_id: number) => {
    setActiveItem(restaurant_id);
    setOpenAlert(true);
  };

  const deleteItem = async () => {
    await ApiRequest({
      url: '/restaurants/deleted',
      method: 'post',
      body: { restaurant_id: activeItem },
      setResponse: (response: any) => {
        if (response.status === 'success') {
          enqueueSnackbar('Restaurant deleted successfully', { variant: 'success' });
          loadList();
        } else {
          enqueueSnackbar(response.message, { variant: 'error' });
        }
        setOpenAlert(false);
      },
    });
  };

  const handleMenuOpen = (event: React.MouseEvent<HTMLElement>, restaurant_id: number) => {
    setMenuAnchor(event.currentTarget);
    setActiveItem(restaurant_id);
  };

  const handleMenuClose = () => {
    setMenuAnchor(null);
  };

  return (
    <>

      <div id="RestaurantList" className="ModuleContainer">
        <div className="ModuleWrapper">
          <div id="RestaurantListIn">
            
              

              {restaurantList.length !== 0 ? (
                <div className="restaurants_grid">
                  {restaurantList.map((restaurant, i) => (
                    <Link to={`/sell/${restaurant.restaurant_id}`} key={i}>
                      
                        <Box
                        className="restaurant_card"
                        >
                        <LiaStoreAltSolid size={50} style={{color: '#ccc'}} />
                        <h2>{restaurant.restaurant_name}</h2>
                        <p>{restaurant.address}</p>
                        </Box>
                    </Link>
                  ))}
                </div>
              ) : (
                <div className="emptyList">
                  <EmptyElement title="No restaurants registered" subtitle="Start by adding a new restaurant" />
                </div>
              )}
              {pagination.total_pages > 1 && (
                <div className="pagination">
                  <Pagination
                    count={pagination.total_pages}
                    page={page}
                    onChange={(event, value) => {
                      setPage(value);
                    }}
                    color="secondary"
                    style={{ marginTop: '20px', alignSelf: 'center' }}
                  />
                </div>
              )}
          </div>
        </div>

        

        <AlertDialog
          open={openAlert}
          setOpen={setOpenAlert}
          subtitle="Are you sure you want to delete this restaurant?"
          send={deleteItem}
        />
      </div>
    </>
  );
}