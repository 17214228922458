import React, { useState, useEffect } from 'react';
import { Button, Dialog, TextField, Switch, FormControlLabel } from '@mui/material';
import { useSnackbar } from 'notistack';
import { ApiRequest } from 'GlobalFunctions';
import ImageUploader from 'Elements/ImageUploader';
import AutocompleteField from 'Elements/AutocompleteField';

export default function ProductEditor(props: any) {
  const {
    setLoading,
    openEditor,
    setOpenEditor,
    product_id,
    loadList,
  } = props;

  const { enqueueSnackbar } = useSnackbar();

  const defaultValues: any = {
    product_id: product_id ? product_id : null,
    product_name: '',
    category_id: '',
    stock: '',
    description: '',
    cost: '',
    unit_price: '',
    tax_rate: '',
    status: true,
    image: '',
  };

  const [formValues, setFormValues] = useState(defaultValues);
  const [files, setFiles] = useState<any>([]);
  const [categories, setCategories] = useState([]);

  /*=======================================
  LOAD PRODUCT DETAILS IF EDITING
  =======================================*/
  useEffect(() => {
    if (product_id && openEditor) {
      loadProductDetails();
    } else {
      setFormValues(defaultValues);
      setFiles([]);
    }
  }, [product_id, openEditor]);

  const loadProductDetails = async () => {
    setLoading(true);

    await ApiRequest({
      url: '/products/details',
      method: 'get',
      query: { product_id },
      setResponse: (response: any) => {
        if (response.status === 'success') {
          setFormValues(response.data);
        } else {
          enqueueSnackbar('Error loading product details', { variant: 'error' });
        }
        setLoading(false);
      },
    });
  };

  /*=======================================
  HANDLE INPUT CHANGE
  =======================================*/
  const handleInputChange = (e: { target: { name: any; value: any; }; }) => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };

  /*=======================================
  SUBMIT FUNCTION
  =======================================*/
  const handleSubmit = async () => {
    const body = new FormData();
    body.append('formValues', JSON.stringify(formValues));
    if (files) {
      body.append("image", files);
    }

    setLoading(true);

    await ApiRequest({
      url: '/products/addedit',
      method: 'post',
      formData: true,
      body: body,
      setResponse: (response: any) => {
        if (response.status === 'success') {
          enqueueSnackbar('Product saved successfully', { variant: 'success' });
          setOpenEditor(false);
          loadList();
        } else {
          enqueueSnackbar(response.message, { variant: 'error' });
        }
        setLoading(false);
      },
    });
  };

  return (
    <Dialog
      open={openEditor}
      onClose={() => setOpenEditor(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      maxWidth="md"
      fullWidth
    >
      <div className="DialogContainer" style={{ display: 'flex', flexDirection: 'row', padding: '20px' }}>
        
        {/* Imagen del producto */}
        <div className="ProductImageContainer" style={{ flex: 1 }}>
          <ImageUploader 
            className="product_image"
            image={formValues.image} 
            setImage={(newImage) => setFormValues({ ...formValues, image: newImage })}
            setFiles={setFiles} 
          />
        </div>

        {/* Formulario de producto */}
        <div className="ProductFormContainer" style={{ flex: 2, marginLeft: '20px' }}>
          <h2 style={{ fontWeight: 'bold' }}>ADD PRODUCT</h2>
          <div style={{ display: 'flex', flexWrap: 'wrap', gap: '20px' }}>
            

            <div className='input_row'>

              <TextField
                id="product_name"
                name="product_name"
                label="Name"
                fullWidth
                required
                value={formValues.product_name}
                onChange={handleInputChange}
              />

              <AutocompleteField
                label="Category"
                endpoint="/categories/list"
                multiple={false}
                value={formValues.category_id}
                onChange={(data: any) => {
                  setFormValues({ ...formValues, category_id: data.value });
                }}
              />

              <TextField
                id="stock"
                name="stock"
                label="Stock"
                type="number"
                fullWidth
                value={formValues.stock}
                onChange={handleInputChange}
              />
            </div>

            <div className='input_row'>
              <TextField
                id="cost"
                name="cost"
                label="Cost"
                type="number"
                fullWidth
                value={formValues.cost}
                onChange={handleInputChange}
              />

              <TextField
                id="unit_price"
                name="unit_price"
                label="Price"
                type="number"
                fullWidth
                value={formValues.unit_price}
                onChange={handleInputChange}
              />
              <TextField
                id="tax_rate"
                name="tax_rate"
                label="Tax Rate"
                type="number"
                fullWidth
                value={formValues.tax_rate}
                onChange={handleInputChange}
                //% symbol
                InputProps={{
                  endAdornment: '%',
                }}
              />
            </div>

            <TextField
              id="description"
              name="description"
              label="Description"
              fullWidth
              multiline
              rows={4}
              value={formValues.description}
              onChange={handleInputChange}
            />

            <FormControlLabel
              control={
                <Switch
                  checked={formValues.status}
                  onChange={(e) => setFormValues({ ...formValues, status: e.target.checked })}
                />
              }
              label="Status"
            />
            
          </div>


          {/* Botones de acción */}
          <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '20px' }}>
            <Button
              onClick={() => setOpenEditor(false)}
              variant="outlined"
              style={{ marginRight: '10px' }}
            >
              CANCEL
            </Button>
            <Button onClick={handleSubmit} variant="contained" color="secondary">
              CONFIRM
            </Button>
          </div>
        </div>
      </div>
    </Dialog>
  );
}
