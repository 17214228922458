import {BrowserRouter as Router , Route, Routes, Navigate} from 'react-router-dom'
import { Fragment } from 'react';
import {Permissions} from './Permissions'; 

import CustomerOrder from 'Components/Customer/CustomerOrder/Order';
import OrderSuccess from 'Components/Customer/CustomerOrder/components/OrderSuccess';

export default function CustomerRoutes(props:any) {
  const role_id = Number(localStorage.getItem('role_id'));
  const {setLoading} = props;
  
  return (
    <>
      <Routes>
        <Route path="customer/order/:restaurant_id/:table_id" element={<CustomerOrder setLoading={setLoading} />} />
        <Route path="/customer/order-success/:order_id" element={<OrderSuccess />} />
      </Routes>
    </>
  );
}

 