import * as React from 'react';
import {Link, Avatar, Button, Container, TextField, Typography, Dialog, DialogContent, InputAdornment, IconButton, SwitchProps, Switch, styled, FormControlLabel} from '@mui/material';
import logo from '../../assets/img/logo.png';
import Box from "Elements/Box";
import { ProjectConfig } from 'Global';
import axios from 'axios';
import { useSnackbar, withSnackbar } from 'notistack';
import LoadingButton from '@mui/lab/LoadingButton';
import { useEffect, useState } from 'react';
import { resolve } from 'path';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import ISwitch from 'Elements/ISwitch';
import { ApiRequest } from 'GlobalFunctions';





export default withSnackbar(
  function SignUp(props: any) {

    const defaultValues: any = {
      name: '',
      last_name: '',
      email: '',
      phone: '',
      password: '',
      password_confirmation: '',
      company_name: '',
    };

    const {openRegister, setOpenRegister, setLoading, sign_in, loginData, setLoginData} = props;

    
  /*SNACKBAR FUNCTION FOR NOTIFICATIONS*/
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  /*============================================= */


  const [formValues, setFormValues] = React.useState(defaultValues);
  const [showPassword, setShowPassword] = React.useState(false);


  useEffect(() => {
    setFormValues(defaultValues);
  }, [openRegister]);


  const handleInputChange = (e: { target: { name: any; value: any; required: any}; }) => {

    const { name, value } = e.target;

    setFormValues({
      ...formValues,
      [name]: value,
    });
    
    
  };



  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };


  const checkEmail = (email: string) => {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  };

  
    

  /*================================================
  SIGN UP (SUBMIT)
  ================================================*/
  async function handleRegister(){

    if(formValues.name === '' || formValues.last_name === '' || formValues.email === '' || formValues.password === '' || formValues.password_confirmation === ''){
      //snackbar error
      enqueueSnackbar('All fields are required', { variant: 'error' });
      return;
    }
      
    if(formValues.password !== '' && formValues.password !== formValues.password_confirmation){
      //snackbar error
      enqueueSnackbar('Passwords do not match', { variant: 'error' });
      return;
    }

    if(!checkEmail(formValues.email)){
      //snackbar error
      enqueueSnackbar('Invalid email', { variant: 'error' });
      return;
    }


    
    
  

    setLoading(true);


    ApiRequest({
      url: '/users/signup', 
      method: 'POST',
      body: formValues,
      setResponse: (response: any) => {
        if(response.status === "success"){
          enqueueSnackbar(response.message, { variant: 'success' });
          loginData.email = formValues.email;
          loginData.password = formValues.password;
          setLoginData(loginData);

          sign_in(formValues.email, formValues.password);
        }
        else
        {
          enqueueSnackbar(response.message, { variant: 'error' });
          setLoading(false);
        }
      }
    })

    


    return
  }



  




  return (
    <>
      <Dialog open={openRegister} onClose={()=>{setOpenRegister(false)}}>
        <DialogContent>
          <h2 className='dialog_h2'>User Registration</h2>
          <p className='subtitle'>Complete the following information to register</p>
          <form className='form' id='registerForm'>

            <TextField
              required
              fullWidth
              id="company_name"
              label="Company Name"
              name="company_name"
              type="text"
              value={formValues.company_name}
              autoComplete="company_name"
              onChange={handleInputChange}
            />
            <div className='input_row'>
              <TextField
                required
                fullWidth
                id="name"
                label="First Name"
                name="name"
                type="text"
                value={formValues.name}
                autoComplete="name"
                onChange={handleInputChange}
              />

              <TextField
                required
                fullWidth
                id="last_name"
                label="Last Name"
                name="last_name"
                type="text"
                autoComplete="last_name"
                onChange={handleInputChange}
                value={formValues.last_name}
              />
            </div>

            <TextField
              required
              fullWidth
              id="email"
              label="Email"
              name="email"
              type="text"
              value={formValues.email}
              autoComplete="email"
              onChange={handleInputChange}
              error={formValues.email !== '' && !checkEmail(formValues.email)}
              helperText={formValues.email !== '' && !checkEmail(formValues.email) ? 'Invalid email' : ''}
            />

            <TextField
              required
              fullWidth
              name="phone"
              label="Phone"
              type="text"
              id="phone"
              value={formValues.phone}
              autoComplete="phone"
              onChange={handleInputChange}
            />

            <div className='input_row'>
              <TextField
                required
                fullWidth
                name="password"
                label="Password"
                type={showPassword ? 'text' : 'password'}
                id="password"
                value={formValues.password}
                autoComplete="current-password"
                onChange={handleInputChange}
                // eye icon to show password
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                
              />

              

              <TextField
                required
                fullWidth
                name="password_confirmation"
                label="Confirm Password"
                type={showPassword ? 'text' : 'password'}
                id="password_confirmation"
                value={formValues.password_confirmation}
                onChange={handleInputChange}
                error={formValues.password_confirmation !="" && formValues.password !== formValues.password_confirmation}
                helperText={formValues.password_confirmation !="" && formValues.password !== formValues.password_confirmation ? 'Passwords do not match' : ''}
                // eye icon to show password
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />

              
            </div>

            

            



            <Button
              fullWidth
              variant="contained"
              onClick={handleRegister}
            >
              Register
            </Button>

            <Button
              fullWidth
              variant="outlined"
              onClick={()=>{setOpenRegister(false)}}
            >
              I already have an account
            </Button>
            
          </form>

        </DialogContent>
      </Dialog>
    </>
  );
}
);