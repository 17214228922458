import React from 'react';
import Box from 'Elements/Box';
import { Avatar, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import ImageIcon from '@mui/icons-material/Image';

const TopSellers = ({ data }: { data: any[] }) => {
  return (
    <Box className="TopSellers" title="SELLERS OF THE MONTH" subtitle='Top 5'>
      <TableContainer>
        <Table aria-label="top sellers table">
          <TableHead>
            <TableRow>
              <TableCell>IMAGE</TableCell>
              <TableCell>NAME</TableCell>
              <TableCell>SALES</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((seller, index) => (
              <TableRow key={index} hover role="checkbox">
                <TableCell>
                  <Avatar src={seller.image}>
                    {!seller.image && <ImageIcon />}
                  </Avatar>
                </TableCell>
                <TableCell>{seller.name} {seller.last_name}</TableCell>
                <TableCell>{seller.sales_count}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default TopSellers;
