import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { Fragment } from 'react';
import { Permissions } from './Permissions';
import NotAuthorized from 'Components/NotAuthorized/NotAuthorized';

import Dashboard from 'Components/Companies/Dashboard/Dashboard';
import Tables from 'Components/Companies/Tables/TableList';
import Payroll from 'Components/Companies/Payroll/Payroll';
import Shifts from 'Components/Companies/Shifts/ShiftsList';
import Products from 'Components/Companies/Products/ProductList';
import Inventory from 'Components/Companies/Inventory/InventoryList';
import Users from 'Components/Companies/Users/UsersList';
import Restaurants from 'Components/Companies/Restaurants/RestaurantList';
import Categories from 'Components/Companies/Categories/CategoriesList';
import Employees from 'Components/Companies/Employees/EmployeeList';
import Sell from 'Components/Companies/Orders/Sell';
import OrderTables from 'Components/Companies/Orders/components/Tables';
import SellOrder from 'Components/Companies/Orders/Order';
import Settings from 'Components/Companies/Settings/Settings';
import Expenses from 'Components/Companies/Expenses/ExpensesList';
import Orders from 'Components/Companies/Orders/OrdersList';
import Account from 'Components/Companies/Account/AccountEditor';

export default function AppRoutes(props: any) {
  const role_id = Number(localStorage.getItem('role_id'));
  const { setLoading, permissions } = props;

  const isAuthorized = (path: string) => {
    const authorized = Array.isArray(permissions) && permissions.some((permission: any) => path.includes(permission.path));
    return authorized;
  };

  return (
    <>
      <Routes>
        <Route path="/" element={<Navigate to="/dashboard" />} />
        <Route path="/dashboard" element={isAuthorized('/dashboard') ? <Dashboard setLoading={setLoading} /> : <NotAuthorized />} />
        <Route path="/tables" element={isAuthorized('/tables') ? <Tables setLoading={setLoading} /> : <NotAuthorized />} />
        <Route path="/payroll" element={isAuthorized('/payroll') ? <Payroll setLoading={setLoading} /> : <NotAuthorized />} />
        <Route path="/shifts" element={isAuthorized('/shifts') ? <Shifts setLoading={setLoading} /> : <NotAuthorized />} />
        <Route path="/categories" element={isAuthorized('/categories') ? <Categories setLoading={setLoading} /> : <NotAuthorized />} />
        <Route path="/products" element={isAuthorized('/products') ? <Products setLoading={setLoading} /> : <NotAuthorized />} />
        <Route path="/inventory" element={isAuthorized('/inventory') ? <Inventory setLoading={setLoading} /> : <NotAuthorized />} />
        <Route path="/employees" element={isAuthorized('/employees') ? <Users setLoading={setLoading} /> : <NotAuthorized />} />
        <Route path="/restaurants" element={isAuthorized('/restaurants') ? <Restaurants setLoading={setLoading} /> : <NotAuthorized />} />
        <Route path="/employees" element={isAuthorized('/employees') ? <Employees setLoading={setLoading} /> : <NotAuthorized />} />
        <Route path="/sell" element={isAuthorized('/sell') ? <Sell setLoading={setLoading} /> : <NotAuthorized />} />
        <Route path="/sell/:restaurant_id" element={isAuthorized('/sell/:restaurant_id') ? <OrderTables setLoading={setLoading} /> : <NotAuthorized />} />
        <Route path="/sell/:restaurant_id/:table_id" element={isAuthorized('/sell/:restaurant_id/:table_id') ? <SellOrder setLoading={setLoading} /> : <NotAuthorized />} />
        <Route path="/sell/:restaurant_id/:table_id/:order_id" element={isAuthorized('/sell/:restaurant_id/:table_id/:order_id') ? <SellOrder setLoading={setLoading} /> : <NotAuthorized />} />
        <Route path="/settings" element={isAuthorized('/settings') ? <Settings setLoading={setLoading} /> : <NotAuthorized />} />
        <Route path="/expenses" element={isAuthorized('/expenses') ? <Expenses setLoading={setLoading} /> : <NotAuthorized />} />
        <Route path="/orders" element={isAuthorized('/orders') ? <Orders setLoading={setLoading} /> : <NotAuthorized />} />
        <Route path="/account" element={isAuthorized('/account') ? <Account setLoading={setLoading} /> : <NotAuthorized />} />
      </Routes>
    </>
  );
}