import Box from "Elements/Box";
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import { Bar } from 'react-chartjs-2';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

export default function IncomesVsExpenses(props: any) {

    const { data } = props;

    const incomesData = data?.incomes || [4000];
    const expensesData = data?.expenses || [2000];
    const xLabels = data?.labels || ['-'];

    const chartData = {
        labels: xLabels,
        datasets: [
            {
                label: 'Incomes',
                data: incomesData,
                backgroundColor: 'rgba(50, 184, 108, 0.8)',
            },
            {
                label: 'Expenses',
                data: expensesData,
                backgroundColor: 'rgba(209, 47, 47, 0.8)',
            },
        ],
    };

    const options = {
        responsive: true,
        maintainAspectRatio: false, 
        plugins: {
            legend: {
                display: true,
                position: 'bottom' as const,
                labels: {
                    usePointStyle: true,
                    pointStyle: 'rectRounded',
                },
            },
        },
    };

    return (
        <Box className='incomesVsExpenses' title="Incomes vs Expenses" subtitle="Last 6 months" style={{ overflow: 'hidden' }}>
            <div style={{ height: '100%' }}>
                <Bar data={chartData} options={options} />
            </div>
        </Box>
    )
}