import axios from 'axios';
import { useEffect, useState } from 'react';
import Box from 'Elements/Box';
import { Avatar, Button, Chip, IconButton, Menu, MenuItem, Pagination, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { useSnackbar } from 'notistack';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import AddIcon from '@mui/icons-material/Add';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import CategoriesEditor from './CategoriesEditor';
import EmptyElement from 'Elements/EmptyElement';
import { ApiRequest } from 'GlobalFunctions';
import AlertDialog from 'Elements/AlertDialog';
import ImageIcon from '@mui/icons-material/Image';

export default function CategoriesList(props: any) {
  const { setLoading } = props;
  const { enqueueSnackbar } = useSnackbar();

  const [categoryList, setCategoryList] = useState<any[]>([]);
  const [totalPages, setTotalPages] = useState(0);
  const [page, setPage] = useState(1);
  const [openEditor, setOpenEditor] = useState(false);
  const [activeItem, setActiveItem] = useState<any>(null);
  const [openAlert, setOpenAlert] = useState(false);
  const [menuAnchor, setMenuAnchor] = useState<null | HTMLElement>(null);

  useEffect(() => {
    loadList();
  }, [page]);

  /*=========================================================
  LOAD LIST
  =========================================================*/
  const loadList = async () => {
    setLoading(true);

    await ApiRequest({
      url: '/categories/list',
      method: 'get',
      query: { page },
      setResponse: (response: any) => {
        setCategoryList(response.list);
        setTotalPages(response.total_pages);
        setLoading(false);
      },
    });
  };

  /*=========================================================
  DELETE ITEM
  =========================================================*/
  const handleDelete = (category_id: number) => {
    setActiveItem(category_id);
    setOpenAlert(true);
  };

  const deleteItem = async () => {
    await ApiRequest({
      url: '/categories/deleteItem',
      method: 'post',
      body: { category_id: activeItem },
      setResponse: (response: any) => {
        if (response.status === 'success') {
          enqueueSnackbar('Item deleted', { variant: 'success' });
          loadList();
        } else {
          enqueueSnackbar(response.message, { variant: 'error' });
        }
        setOpenAlert(false);
      },
    });
  };

  /*=========================================================
  HANDLE MENU OPEN
  =========================================================*/
  const handleMenuOpen = (event: React.MouseEvent<HTMLElement>, category_id: number) => {
    setMenuAnchor(event.currentTarget);
    setActiveItem(category_id);
  };

  /*=========================================================
  HANDLE MENU CLOSE
  =========================================================*/
  const handleMenuClose = () => {
    setMenuAnchor(null);
  };

  return (
    <>
      <div className="moduleHeader">
        <h1 className="component_title">Categories</h1>
        <div className="boxContainerActions">
              <Button
                variant="text"
                color="primary"
                onClick={() => {
                  setActiveItem(null);
                  setOpenEditor(true);
                }}
                startIcon={<AddIcon />}
              >
                Add item
              </Button>
            </div>
      </div>


      <div id="CategoryList" className="ModuleContainer">
        <div className="ModuleWrapper">

          <div id="CategoryListIn">
            <Box id="AdminList" className="box100">
              {categoryList.length !== 0 ? (
                <TableContainer>
                  <Table aria-label="sticky table">
                    <TableHead>
                      <TableRow>
                        <TableCell>ID</TableCell>
                        <TableCell>IMAGE</TableCell>
                        <TableCell>NAME</TableCell>
                        <TableCell>DESCRIPTION</TableCell>
                        <TableCell>ACTIONS</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {categoryList.map((category, i) => (
                        <TableRow key={i} hover role="checkbox">
                          <TableCell>{category.category_id}</TableCell>
                          <TableCell>
                          <Avatar 
                            src={category.image} 
                            sx={{ marginRight: 2 }}
                          >
                            {!category.image && <ImageIcon />}
                          </Avatar>
                          </TableCell>
                          <TableCell>{category.name}</TableCell>
                          <TableCell>{category.description}</TableCell>
                          <TableCell align="right">
                            <IconButton aria-label="edit" color="primary" onClick={(event) => handleMenuOpen(event, category.category_id)}>
                              <MoreHorizIcon color='secondary' />
                            </IconButton>
                            <Menu
                              anchorEl={menuAnchor}
                              open={Boolean(menuAnchor && activeItem === category.category_id)}
                              onClose={handleMenuClose}
                            >
                              <MenuItem onClick={() => {
                                setOpenEditor(true);
                                handleMenuClose();
                              }}>
                                <ModeEditIcon fontSize="small" />
                                Edit
                              </MenuItem>
                              <MenuItem onClick={() => handleDelete(category.category_id)}>
                                <DeleteOutlineOutlinedIcon fontSize="small" />
                                Remove
                              </MenuItem>
                            </Menu>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              ) : (
                <div className="emptyList">
                  <EmptyElement title="No categories" subtitle="Create a new category to start" />
                </div>
              )}
              {totalPages > 1 && (
                <div className="pagination">
                  <Pagination
                    count={totalPages}
                    page={page}
                    onChange={(event, value) => {
                      setPage(value);
                    }}
                    color="secondary"
                    style={{ marginTop: '20px', alignSelf: 'center' }}
                  />
                </div>
              )}
            </Box>
          </div>
        </div>

        <CategoriesEditor
          setLoading={setLoading}
          openEditor={openEditor}
          setOpenEditor={setOpenEditor}
          category_id={activeItem}
          loadList={loadList}
        />

        <AlertDialog
          open={openAlert}
          setOpen={setOpenAlert}
          subtitle="Are you sure you want to delete this item?"
          send={deleteItem}
        />
      </div>
    </>
  );
}
