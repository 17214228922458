import axios from 'axios';
import { useEffect, useState } from 'react';
import Box from 'Elements/Box';
import { Avatar, Button, Chip, IconButton, Pagination, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { useSnackbar } from 'notistack';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import AddIcon from '@mui/icons-material/Add';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import ImageIcon from '@mui/icons-material/Image';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ProductEditor from './ProductEditor';
import EmptyElement from 'Elements/EmptyElement';
import { ApiRequest } from 'GlobalFunctions';
import AlertDialog from 'Elements/AlertDialog';
import FiltersByTable from 'Elements/Filters/FiltersByTable';
import FiltersApplied from 'Elements/Filters/FiltersApplied';

export default function ProductList(props: any) {
  const { setLoading } = props;
  const { enqueueSnackbar } = useSnackbar();

  const [productList, setProductList] = useState<any[]>([]);
  const [pagination, setPagination] = useState({
    total_pages: 1,
    total_items: 0,
  });
  const [page, setPage] = useState(1);
  const [openEditor, setOpenEditor] = useState(false);
  const [activeItem, setActiveItem] = useState<any>(null);
  const [openAlert, setOpenAlert] = useState(false);
  const [menuAnchor, setMenuAnchor] = useState<null | HTMLElement>(null);
  const [filtersList, setFiltersList] = useState<any[]>([]);
  const [filtersValues, setFiltersValues] = useState<any>([]);

  useEffect(() => {
    loadList();
  }, [page, filtersValues]);

  /*=========================================================
  LOAD LIST
  =========================================================*/
  const loadList = async () => {
    setLoading(true);

    await ApiRequest({
      url: '/products/list',
      method: 'get',
      query: { page, filters: filtersValues },
      setResponse: (response: any) => {
        setProductList(response.list);
        setPagination(response.pagination)
        setFiltersList(response.filtersList);
        setLoading(false);
      },
    });
  };

  /*=========================================================
  DELETE ITEM
  =========================================================*/
  const handleDelete = (product_id: number) => {
    setActiveItem(product_id);
    setOpenAlert(true);
  };

  const deleteItem = async () => {
    await ApiRequest({
      url: '/products/deleted',
      method: 'post',
      body: { product_id: activeItem },
      setResponse: (response: any) => {
        if (response.status === 'success') {
          enqueueSnackbar('Product deleted successfully', { variant: 'success' });
          loadList();
        } else {
          enqueueSnackbar(response.message, { variant: 'error' });
        }
        setOpenAlert(false);
      },
    });
  };

  const handleMenuOpen = (event: React.MouseEvent<HTMLElement>, product_id: number) => {
    setMenuAnchor(event.currentTarget);
    setActiveItem(product_id);
  };

  const handleMenuClose = () => {
    setMenuAnchor(null);
  };

  return (
    <>
      <div className="moduleHeader">
        <h1 className="component_title">Products</h1>
        <div className="boxContainerActions">
          <Button
            variant="text"
            color="primary"
            onClick={() => {
              setActiveItem(null);
              setOpenEditor(true);
            }}
            startIcon={<AddIcon />}
          >
            Add Product
          </Button>

          <FiltersByTable 
            filters={filtersList} 
            setFilters={setFiltersValues} 
            searchBy={['products.product_id', 'product_name']}
          />
        </div>
        
      </div>


      <div id="ProductList" className="ModuleContainer">
        <div className="ModuleWrapper">
          <div id="ProductListIn">
            <Box id="AdminList" className="box100">
              <FiltersApplied filters={filtersValues} setFilters={setFiltersValues} />

              {productList.length !== 0 ? (
                <TableContainer>
                  <Table aria-label="sticky table">
                    <TableHead>
                      <TableRow>
                        <TableCell>ID</TableCell>
                        <TableCell>IMAGE</TableCell>
                        <TableCell>CATEGORY</TableCell>
                        <TableCell>NAME</TableCell>
                        <TableCell>PRICE</TableCell>
                        <TableCell>COST</TableCell>
                        <TableCell>TAX</TableCell>
                        <TableCell>UTILITY</TableCell>
                        <TableCell>STOCK</TableCell>
                        <TableCell>STATUS</TableCell>
                        <TableCell>ACTIONS</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {productList.map((product, i) => (
                        <TableRow key={i} hover role="checkbox">
                          <TableCell>{product.product_id}</TableCell>
                          <TableCell>
                            <Avatar src={product.image} sx={{ marginRight: 2 }}>
                              {!product.image && <ImageIcon />}
                            </Avatar>
                          </TableCell>
                          <TableCell>{product.category_name}</TableCell>
                          <TableCell>{product.product_name}</TableCell>
                          <TableCell>${Number(product.unit_price).toLocaleString()}</TableCell>
                          <TableCell>${Number(product.cost).toLocaleString()}</TableCell>
                          <TableCell>${Number(product.tax_amount).toLocaleString()}</TableCell>
                          <TableCell>{product.utility_percentage}%</TableCell>
                          <TableCell>{product.stock}</TableCell>
                          <TableCell><Chip label={product.status ? 'Active' : 'Inactive'} color={product.status ? "primary" : "secondary"} /></TableCell>
                          <TableCell align="right">
                            <IconButton aria-label="edit" color="primary" onClick={(event) => handleMenuOpen(event, product.product_id)}>
                              <MoreHorizIcon color='secondary' />
                            </IconButton>
                            <Menu
                              anchorEl={menuAnchor}
                              open={Boolean(menuAnchor && activeItem === product.product_id)}
                              onClose={handleMenuClose}
                            >
                              <MenuItem onClick={() => {
                                setOpenEditor(true);
                                handleMenuClose();
                              }}>
                                <ModeEditIcon fontSize="small" />
                                Edit
                              </MenuItem>
                              <MenuItem onClick={() => handleDelete(product.product_id)}>
                                <DeleteOutlineOutlinedIcon fontSize="small" />
                                Remove
                              </MenuItem>
                            </Menu>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              ) : (
                <div className="emptyList">
                  <EmptyElement title="No products registered" subtitle="Start by creating a new product" />
                </div>
              )}
              {pagination.total_pages > 1 && (
                <div className="pagination">
                  <Pagination
                    count={pagination.total_pages}
                    page={page}
                    onChange={(event, value) => {
                      setPage(value);
                    }}
                    color="secondary"
                    style={{ marginTop: '20px', alignSelf: 'center' }}
                  />
                </div>
              )}
            </Box>
          </div>
        </div>

        <ProductEditor
          setLoading={setLoading}
          openEditor={openEditor}
          setOpenEditor={setOpenEditor}
          product_id={activeItem}
          loadList={loadList}
        />

        <AlertDialog
          open={openAlert}
          setOpen={setOpenAlert}
          subtitle="Are you sure you want to delete this product?"
          send={deleteItem}
        />
      </div>
    </>
  );
}
