import { ApiRequest } from "GlobalFunctions";
import { useEffect, useState } from "react";
import CompaniesPerMonth from "./components/CompaniesPerMonth";
import NumberOfCompanies from "./components/NumberOfCompanies";
import IncomesPerMonth from "./components/IncomesPerMonth";
import Incomes from "./components/Incomes";
import AdminOrdersList from "../Orders/AdminOrdersList";

export default function AdminDasboard(props: any) {
  const { setLoading } = props;

  const [companiesPerMonth, setCompaniesPerMonth] = useState<any>({
    labels: [],
    values: []
  });

  const [numberOfCompanies, setNumberOfCompanies] = useState<any>({
    last_6_months: 0,
    last_7_days: 0,
    month: 0,
    year: 0
  });

  const [incomesPerMonth, setIncomesPerMonth] = useState<any>({
    labels: [],
    values: []
  });

  const [incomes, setIncomes] = useState<any>({
    last_6_months: 0,
    last_7_days: 0,
    month: 0,
    year: 0
  });

  const loadData = async () => {
    setLoading(true);

    await ApiRequest({
      url: '/admin_dashboard/getDashboard',
      method: 'get',
      query: {},
      setResponse: (response: any) => {
        setCompaniesPerMonth(response.companiesPerMonth);
        setNumberOfCompanies(response.numberOfCompanies);
        setIncomesPerMonth(response.incomesPerMonth);
        setIncomes(response.incomes);
        setLoading(false);
      },
    });
  }

  useEffect(() => {
    loadData();
  }, []);

  return (
    <div id="Dashboard" className="ModuleContainer">
      
      <h2>Incomes</h2>
      <div className="dashboard_row">
        <Incomes data={incomes} />
        <AdminOrdersList setLoading={setLoading} widget={true} />
      </div>

      
      <div className="dashboard_row">
        <IncomesPerMonth data={incomesPerMonth} />
        <CompaniesPerMonth data={companiesPerMonth} />
      </div>

      <h2>New Restaurants</h2>
      <NumberOfCompanies data={numberOfCompanies} />
    </div>
  );
}
