import React, { useState, useEffect } from 'react';
import { Button, Dialog, TextField } from '@mui/material';
import { useSnackbar } from 'notistack';
import { ApiRequest } from 'GlobalFunctions';

export default function RestaurantEditor(props: any) {
  const {
    setLoading,
    openEditor,
    setOpenEditor,
    restaurant_id,
    loadList,
  } = props;

  const { enqueueSnackbar } = useSnackbar();

  const defaultValues: any = {
    restaurant_id: restaurant_id ? restaurant_id : null,
    restaurant_name: '',
    address: '',
    phone: '',
    manager: '',
  };

  const [formValues, setFormValues] = useState(defaultValues);

  useEffect(() => {
    if (restaurant_id && openEditor) {
      loadRestaurantDetails();
    } else {
      setFormValues(defaultValues);
    }
  }, [restaurant_id, openEditor]);

  const loadRestaurantDetails = async () => {
    setLoading(true);

    await ApiRequest({
      url: '/restaurants/details',
      method: 'get',
      query: { restaurant_id },
      setResponse: (response: any) => {
        if (response.status === 'success') {
          setFormValues(response.data);
        } else {
          enqueueSnackbar('Error loading restaurant details', { variant: 'error' });
        }
        setLoading(false);
      },
    });
  };

  const handleInputChange = (e: { target: { name: any; value: any; }; }) => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };

  const handleSubmit = async () => {

    const body = new FormData();
    body.append('formValues', JSON.stringify(formValues));


    setLoading(true);

    await ApiRequest({
      url: '/restaurants/addedit',
      method: 'post',
      formData: true,
      body: body,
      setResponse: (response: any) => {
        if (response.status === 'success') {
          enqueueSnackbar('Restaurant saved successfully', { variant: 'success' });
          setOpenEditor(false);
          loadList();
        } else {
          enqueueSnackbar(response.message, { variant: 'error' });
        }
        setLoading(false);
      },
    });
  };

  return (
    <Dialog
      open={openEditor}
      onClose={() => setOpenEditor(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      maxWidth="sm"
      fullWidth
    >
      <div className="DialogContainer" style={{ padding: '20px' }}>
        <h2 style={{ fontWeight: 'bold' }}>{restaurant_id ? 'Edit Restaurant' : 'Add Restaurant'}</h2>
        <div style={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
          <TextField
            id="restaurant_name"
            name="restaurant_name"
            label="Name"
            fullWidth
            required
            value={formValues.restaurant_name}
            onChange={handleInputChange}
          />
          <TextField
            id="address"
            name="address"
            label="Address"
            fullWidth
            required
            value={formValues.address}
            onChange={handleInputChange}
          />
          <TextField
            id="phone"
            name="phone"
            label="Phone"
            fullWidth
            required
            value={formValues.phone}
            onChange={handleInputChange}
          />
          <TextField
            id="manager"
            name="manager"
            label="Manager"
            fullWidth
            required
            value={formValues.manager}
            onChange={handleInputChange}
          />
        </div>

        <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '20px' }}>
          <Button
            onClick={() => setOpenEditor(false)}
            variant="outlined"
            style={{ marginRight: '10px' }}
          >
            CANCEL
          </Button>
          <Button onClick={handleSubmit} variant="contained" color="secondary">
            CONFIRM
          </Button>
        </div>
      </div>
    </Dialog>
  );
}
