import React, { useEffect, useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, RadioGroup, FormControlLabel, Radio } from '@mui/material';
import AlertDialog from 'Elements/AlertDialog'; // Import AlertDialog
import StripeForm from './StripeForm';
import PaymentForm from 'Components/PaymentForm'; // Import PaymentForm
import { ApiRequest } from 'GlobalFunctions';
import { useSnackbar } from "notistack";
import { MdOutlinePaid } from "react-icons/md";
import { LoadingButton } from '@mui/lab';

interface PaymentDialogProps {
  open: boolean;
  order_id: any;
  onClose: () => void;
  currentOrder: any;
}

export default function PaymentDialog({ open, currentOrder, onClose, order_id }: PaymentDialogProps) {
  const [paymentMethod, setPaymentMethod] = useState('cash');
  const [openAlert, setOpenAlert] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [isPrinting, setIsPrinting] = useState(false);

  const handlePaymentMethodChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPaymentMethod((event.target as HTMLInputElement).value);
  };

  const handleConfirmPayment = () => {
    setOpenAlert(true); // Open alert dialog
  };



  const handleAlertConfirm = async () => {
    await OfflinePayment();
    // setOpenAlert(false);
    // onClose();
  };

  const setPaymentStatus = async (status: any, message: string) => {
    if(status === 'success') {
      await OfflinePayment();
      enqueueSnackbar(message, { variant: 'success' });
      // onClose();
    } else {
      enqueueSnackbar(message, { variant: 'error' });
    }
  };

  const ShowLoader = (show: boolean) => {
  }

  const [paymentInfo, setPaymentInfo] = useState<any>({});

  const getPaymentForm = async () => {
    ShowLoader(true);
    ApiRequest({
      url: "/payments/getPaymentForm",
      method: "get",
      query: {
        order_id,
      },
      setResponse: async (response: any) => {
        if(response.status === 'error') {
          enqueueSnackbar(response.message, { variant: 'error' });
          return;
        }
        console.log("Payment Info:", response); // Add this line to debug paymentInfo
        setPaymentInfo(response);
      },
    });
  };

  useEffect(() => {
    open && order_id && getPaymentForm();
  }, [order_id, open]);

  const OfflinePayment = async () => {

    setIsPrinting(true);

    ApiRequest({
      url: "/orders/OfflinePayment",
      method: "post",
      body: {
        order_id,
        payment_method: 'cash',
      },
      setResponse: async (response: any) => {
        setIsPrinting(false);
        if(response.status === 'error') {
          enqueueSnackbar(response.message, { variant: 'error' });
          return;
        }
        
        if(response.fileUrl) {
          window.open(response.fileUrl, '_blank');
          setTimeout(() => {
            window.location.reload();
          }, 1000);

          enqueueSnackbar(response.message, { variant: 'success' });
          onClose();
        }
      },
    });
  }

  return (
    <>
      <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
        <DialogTitle>Select Payment Method</DialogTitle>
        <DialogContent>
          <RadioGroup value={paymentMethod} onChange={handlePaymentMethodChange}>
            <FormControlLabel value="cash" control={<Radio />} label="Offline" />
            <FormControlLabel value="credit_card" control={<Radio />} label="Credit Card Payment" />
          </RadioGroup>

          {paymentMethod === 'credit_card' && currentOrder.total ? (
            <PaymentForm amount={currentOrder.total} />
          ) : (

            <LoadingButton 
                variant="contained"
                color='secondary'
                fullWidth
                startIcon={<MdOutlinePaid />}
                sx={{ marginTop: '12px', padding: '12px' }}
                onClick={handleConfirmPayment}
                loading={isPrinting}
            >
                Place Order
            </LoadingButton>
            
          )}

          <Button 
              variant="outlined" 
              color="primary" 
              fullWidth 
              onClick={onClose}
              sx={{ marginTop: '12px', padding: '12px' }}
            >
              Cancel
            </Button>


        </DialogContent>
      </Dialog>

      <AlertDialog
        open={openAlert}
        setOpen={setOpenAlert}
        subtitle="Are you sure you want to proceed?."
        send={handleAlertConfirm}
      />
    </>
  );
}
