import React, { useState, useEffect } from 'react';
import { Button, Dialog, TextField, FormControlLabel, Switch } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useSnackbar } from 'notistack';
import { ApiRequest } from 'GlobalFunctions';
import AutocompleteField from 'Elements/AutocompleteField';
import { LuHardDriveDownload } from "react-icons/lu";


export default function TableEditor(props: any) {
  const {
    setLoading,
    openEditor,
    setOpenEditor,
    table_id,
    loadList,
    status
  } = props;

  const { enqueueSnackbar } = useSnackbar();

  const defaultValues: any = {
    table_id: table_id ? table_id : null,
    description: '',
    max_seats: '',
    status: true,
    restaurant_id: '',
  };

  const [formValues, setFormValues] = useState(defaultValues);
  const [qrCodeImage, setQrCodeImage] = useState<string | null>(null);
  const [loadingQR, setLoadingQR] = useState(false);

  useEffect(() => {
    if (table_id && openEditor) {
      loadTableDetails();
    } else {
      setFormValues(defaultValues);
      setQrCodeImage(null); // Reset QR code image when adding a new table
    }
  }, [table_id, openEditor]);

  const loadTableDetails = async () => {
    setLoading(true);

    await ApiRequest({
      url: '/tables/details',
      method: 'get',
      query: { table_id },
      setResponse: (response: any) => {
        if (response.status === 'success') {
          setFormValues(response.data);
          setQrCodeImage(response.data.qrCodeImage || null);
        } else {
          enqueueSnackbar('Error loading table details', { variant: 'error' });
        }
        setLoading(false);
      },
    });
  };

  const handleInputChange = (e: { target: { name: any; value: any; }; }) => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };

  const handleSubmit = async () => {

    const body = new FormData();
    body.append('formValues', JSON.stringify(formValues));

    setLoading(true);

    await ApiRequest({
      url: '/tables/addedit',
      method: 'post',
      formData: true,
      body: body,
      setResponse: (response: any) => {
        if (response.status === 'success') {
          enqueueSnackbar('Table saved successfully', { variant: 'success' });
          setOpenEditor(false);
          loadList();
        } else {
          enqueueSnackbar(response.message, { variant: 'error' });
        }
        setLoading(false);
      },
    });
  };

  const handleDownloadQR = async () => {
    setLoadingQR(true);

    await ApiRequest({
      url: '/tables/downloadQR',
      method: 'get',
      query: { table_id },
      setResponse: (response: any) => {
        if (response.status === 'success') {
          window.open(response.fileUrl, '_blank');
        } else {
          enqueueSnackbar('Error downloading QR code', { variant: 'error' });
        }
        setLoadingQR(false);
      },
    });
  };

  return (
    <Dialog
      open={openEditor}
      onClose={() => setOpenEditor(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      maxWidth="sm"
      fullWidth
    >
      <div className="DialogContainer" style={{ padding: '20px' }}>
        {qrCodeImage && table_id && ( // Only show QR code if editing an existing table
          <div className='QRCodeContainer'>
            <img src={`data:image/png;base64,${qrCodeImage}`} alt="QR Code" style={{ maxWidth: '300px' }} />
            <LoadingButton
              startIcon={<LuHardDriveDownload />}  
              onClick={handleDownloadQR} 
              variant="contained" 
              color="primary" 
              loading={loadingQR}
              style={{ marginTop: '10px' }}
            >
              Download QR Code
            </LoadingButton>
          </div>
        )}
        <h2 style={{ fontWeight: 'bold' }}>{table_id ? 'Edit Table' : 'Add Table'}</h2>
        <div style={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
          
          <AutocompleteField
            label="Restaurant"
            endpoint="/restaurants/list"
            multiple={false}
            value={formValues.restaurant_id}
            onChange={(data: any) => {
              setFormValues({ ...formValues, restaurant_id: data.value });
            }}
          />
          <TextField
            id="description"
            name="description"
            label="Description"
            fullWidth
            required
            value={formValues.description}
            onChange={handleInputChange}
          />
          <TextField
            id="max_seats"
            name="max_seats"
            label="Max Seats"
            fullWidth
            required
            value={formValues.max_seats}
            onChange={handleInputChange}
          />
          <FormControlLabel
            control={
              <Switch
                checked={formValues.status}
                onChange={(e) => setFormValues({ ...formValues, status: e.target.checked })}
              />
            }
            label="Status"
          />
        </div>

        <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '20px' }}>
          <Button
            onClick={() => setOpenEditor(false)}
            variant="outlined"
            style={{ marginRight: '10px' }}
          >
            CANCEL
          </Button>
          <Button onClick={handleSubmit} variant="contained" color="secondary">
            CONFIRM
          </Button>
        </div>
      </div>
    </Dialog>
  );
}