import axios from 'axios';
import { useEffect, useState } from 'react';
import { Button, IconButton, Menu, MenuItem, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Pagination } from '@mui/material';
import { useSnackbar } from 'notistack';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import AddIcon from '@mui/icons-material/Add';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import { ProjectConfig } from 'Global';
import AlertDialog from 'Elements/AlertDialog';
import Box from 'Elements/Box';
import PlanEditor from './PlanEditor';

export default function PlanList(props: any) {
  const { enqueueSnackbar } = useSnackbar();
  const { setLoading } = props;

  useEffect(() => {
    setLoading(false);
  }, []);

  const [list, setList] = useState<any[]>([]);
  const [pagination, setPagination] = useState({
    total_pages: 1,
    total_items: 0,
  });

  const currentPage = parseInt(localStorage.getItem('page') || '1');
  const [page, setPage] = useState(currentPage);

  const [openEditor, setOpenEditor] = useState(false);
  const [menuAnchor, setMenuAnchor] = useState<null | HTMLElement>(null);
  const [activeItem, setActiveItem] = useState<any>(null);
  const [openAlert, setOpenAlert] = useState(false);

  useEffect(() => {
    setLoading(true);
    loadList();
  }, [page]);

  const loadList = () => {
    setLoading(true);
    axios
      .get(ProjectConfig.api_url + '/plans/list', {
        params: {
          page: page,
          role_id: 1,
        },
      })
      .then((response) => {
        setList(response.data.list);
        setPagination(response.data.pagination);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err.response.data);
      })
      .finally(() => {
        setLoading(false);
      });

    localStorage.setItem('page', page.toString());
  };

  const handleDelete = (plan_id: number) => {
    setActiveItem(plan_id);
    setOpenAlert(true);
  };

  const deleteItem = () => {
    const body = {
      plan_id: activeItem,
    };

    axios
      .post(ProjectConfig.api_url + '/plans/deleteItem', body)
      .then((response) => {
        if (response.data.status === 'success') {
          enqueueSnackbar('Package Deleted', { variant: 'success' });
          loadList();
        }
      })
      .catch((err) => {
        console.log(err.data);
      })
      .finally(() => {
        setOpenAlert(false);
      });
  };

  const handleMenuOpen = (event: React.MouseEvent<HTMLElement>, item: any) => {
    setMenuAnchor(event.currentTarget);
    setActiveItem(item);
  };

  const handleMenuClose = () => {
    setMenuAnchor(null);
  };

  return (
    <>
      <div className="moduleHeader">
        <h1 className="component_title">Packages</h1>
        <div className="boxContainerActions">
          <Button
            variant="text"
            color="primary"
            onClick={() => {
              setActiveItem(null);
              setOpenEditor(true);
            }}
            startIcon={<AddIcon />}
          >
            Add Package
          </Button>
        </div>
      </div>

      <div className="ModuleContainer">
        <Box className="box100">
          {list.length !== 0 ? (
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Plan</TableCell>
                    <TableCell>ID</TableCell>
                    <TableCell>Users</TableCell>
                    <TableCell>Monthly price</TableCell>
                    <TableCell>Annual price</TableCell>
                    <TableCell>Status</TableCell>
                    <TableCell align="right">Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {list.map((item, i) => (
                    <TableRow key={i} hover>
                      <TableCell>{item.plan_name}</TableCell>
                      <TableCell>{item.plan_id}</TableCell>
                      <TableCell>{item.active_subscriptions}</TableCell>
                      <TableCell>${item.price_monthly}</TableCell>
                      <TableCell>${item.price_yearly}</TableCell>
                      <TableCell style={{ color: item.status === 'Active' ? '#0A7715' : '#CCCCCC' }}>
                        {item.status}
                      </TableCell>
                      <TableCell align="right">
                        <IconButton aria-label="edit" color="primary" onClick={(event) => handleMenuOpen(event, item)}>
                          <MoreHorizIcon />
                        </IconButton>
                        <Menu
                          anchorEl={menuAnchor}
                          open={Boolean(menuAnchor && activeItem && activeItem.plan_id === item.plan_id)}
                          onClose={handleMenuClose}
                        >
                          <MenuItem
                            onClick={() => {
                              setOpenEditor(true);
                              handleMenuClose();
                            }}
                          >
                            <ModeEditIcon fontSize="small" /> Edit
                          </MenuItem>
                          <MenuItem onClick={() => handleDelete(item.plan_id)}>
                            <DeleteOutlineOutlinedIcon fontSize="small" /> Remove
                          </MenuItem>
                        </Menu>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          ) : (
            <div>No packages found</div>
          )}
          {pagination.total_pages > 1 && (
            <div className="pagination">
              <Pagination
                count={pagination.total_pages}
                page={page}
                onChange={(event, value) => setPage(value)}
                color="secondary"
              />
            </div>
          )}
        </Box>
      </div>

      <PlanEditor
        setLoading={setLoading}
        openEditor={openEditor}
        setOpenEditor={setOpenEditor}
        plan_id={activeItem?.plan_id}
        loadList={loadList}
      />

      <AlertDialog
        open={openAlert}
        setOpen={setOpenAlert}
        subtitle="Are you sure you want to delete this item?"
        send={deleteItem}
      />
    </>
  );
}
