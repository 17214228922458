import React, { useEffect, useState } from 'react';
import Box from 'Elements/Box';
import { Button, Pagination, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Menu, MenuItem, IconButton, Avatar } from '@mui/material';
import { useSnackbar } from 'notistack';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import AddIcon from '@mui/icons-material/Add';
import ImageIcon from '@mui/icons-material/Image';
import { ApiRequest } from 'GlobalFunctions';
import AlertDialog from 'Elements/AlertDialog';
import FiltersByTable from 'Elements/Filters/FiltersByTable';
import FiltersApplied from 'Elements/Filters/FiltersApplied';
import EmptyElement from 'Elements/EmptyElement';
import CompaniesEditor from './CompaniesEditor';

export default function CompaniesList(props: any) {
  const { setLoading } = props;
  const { enqueueSnackbar } = useSnackbar();

  const [companiesList, setCompaniesList] = useState<any[]>([]);
  const [pagination, setPagination] = useState({ total_pages: 1, total_items: 0 });
  const [page, setPage] = useState(1);
  const [openEditor, setOpenEditor] = useState(false);
  const [activeItem, setActiveItem] = useState<any>(null);
  const [openAlert, setOpenAlert] = useState(false);
  const [menuAnchor, setMenuAnchor] = useState<null | HTMLElement>(null);
  const [filtersList, setFiltersList] = useState<any[]>([]);
  const [filtersValues, setFiltersValues] = useState<any>([]);

  useEffect(() => {
    loadList();
  }, [page, filtersValues]);

  const loadList = async () => {
    setLoading(true);
    await ApiRequest({
      url: '/companies/list',
      method: 'get',
      query: { page, filters: filtersValues },
      setResponse: (response: any) => {
        setCompaniesList(response.list);
        setPagination(response.pagination);
        setFiltersList(response.filtersList);
        setLoading(false);
      },
    });
  };

  const handleDelete = (company_id: number) => {
    setActiveItem(company_id);
    setOpenAlert(true);
  };

  const deleteItem = async () => {
    await ApiRequest({
      url: '/companies/deleteItem',
      method: 'post',
      body: { company_id: activeItem },
      setResponse: (response: any) => {
        if (response.status === 'success') {
          enqueueSnackbar(response.message, { variant: 'success' });
          loadList();
        } else {
          enqueueSnackbar(response.message, { variant: 'error' });
        }
        setOpenAlert(false);
      },
    });
  };

  const handleMenuOpen = (event: React.MouseEvent<HTMLElement>, company_id: number) => {
    setMenuAnchor(event.currentTarget);
    setActiveItem(company_id);
  };

  const handleMenuClose = () => {
    setMenuAnchor(null);
  };

  return (
    <>
      <div className="moduleHeader">
        <h1 className="component_title">Companies</h1>
        <div className="boxContainerActions">
          <Button
            variant="text"
            color="primary"
            onClick={() => {
              setActiveItem(null);
              setOpenEditor(true);
            }}
            startIcon={<AddIcon />}
          >
            Add Company
          </Button>

          <FiltersByTable
            filters={filtersList}
            setFilters={setFiltersValues}
            searchBy={['companies.company_id', 'company_name']}
          />
        </div>
      </div>

      <div className="ModuleContainer">
        <Box className="box100">
          <FiltersApplied filters={filtersValues} setFilters={setFiltersValues} />
          {companiesList.length !== 0 ? (
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell width={50}>ID</TableCell>
                    <TableCell width={50}>IMAGE</TableCell>
                    <TableCell>NAME</TableCell>
                    <TableCell align="right">ACTIONS</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {companiesList.map((company, i) => (
                    <TableRow key={i} hover>
                      <TableCell>{company.company_id}</TableCell>
                      <TableCell>
                        <Avatar src={company.image} sx={{ marginRight: 2 }}>
                          {!company.image && <ImageIcon />}
                        </Avatar>
                      </TableCell>
                      <TableCell>{company.company_name}</TableCell>
                      <TableCell align="right">
                        <IconButton onClick={(e) => handleMenuOpen(e, company.company_id)}>
                          <MoreHorizIcon color="secondary" />
                        </IconButton>
                        <Menu
                          anchorEl={menuAnchor}
                          open={Boolean(menuAnchor && activeItem === company.company_id)}
                          onClose={handleMenuClose}
                        >
                          <MenuItem onClick={() => {
                            setOpenEditor(true);
                            handleMenuClose();
                          }}>
                            <ModeEditIcon fontSize="small" />
                            Edit
                          </MenuItem>
                          <MenuItem onClick={() => handleDelete(company.company_id)}>
                            <DeleteOutlineOutlinedIcon fontSize="small" />
                            Remove
                          </MenuItem>
                        </Menu>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          ) : (
            <EmptyElement title="No companies found" subtitle="Add a company to get started" />
          )}
          {pagination.total_pages > 1 && (
            <div className="pagination">
              <Pagination
                count={pagination.total_pages}
                page={page}
                onChange={(event, value) => setPage(value)}
                color="secondary"
              />
            </div>
          )}
        </Box>
      </div>

      <CompaniesEditor
        setLoading={setLoading}
        openEditor={openEditor}
        setOpenEditor={setOpenEditor}
        company_id={activeItem}
        loadList={loadList}
      />

      <AlertDialog
        open={openAlert}
        setOpen={setOpenAlert}
        subtitle="Are you sure you want to delete this company?"
        send={deleteItem}
      />
    </>
  );
}

