import Box from "Elements/Box";
import Incomes from "./components/Incomes";
import { ApiRequest } from "GlobalFunctions";
import { useEffect, useState } from "react";
import OrdersPerHour from "./components/OrdersPerHour";
import OrdersWeekly from "./components/OrdersWeekly";
import IncomesVsExpenses from "./components/IncomesVsExpenses";
import OrdersList from "../Orders/OrdersList";
import TopSellers from "./components/TopSellers";


export default function Dashboard(props: any) {
  const { setLoading } = props;

  const [incomes, setIncomes] = useState<any>({
    today: { total: 0, percentage: 0 },
    last_7_days: { total: 0, percentage: 0 },
    month: { total: 0, percentage: 0 },
    year: { total: 0, percentage: 0 }
  });

  const [ordersPerHour, setOrdersPerHour] = useState<any>({
    labels: [],
    values: []
  });
  
  const [ordersWeekly, setOrdersWeekly] = useState<any>({
    labels: [],
    values: []
  });

  const [incomesVsExpenses, setIncomesVsExpenses] = useState<any>({
    labels: [],
    incomes: [],
    expenses: []
  });

  const [topSellers, setTopSellers] = useState<any[]>([]);

  const loadData = async () => {
    setLoading(true);

    await ApiRequest({
      url: '/dashboard/getDashboard',
      method: 'get',
      query: {},
      setResponse: (response: any) => {
        setIncomes(response.incomes);
        setOrdersPerHour(response.ordersPerHour);
        setOrdersWeekly(response.ordersWeekly);
        setIncomesVsExpenses(response.incomesVsExpenses);
        setTopSellers(response.topSellers);
        setLoading(false);
      },
    });
  }

  useEffect(() => {
    loadData();
  }, []);

  return (
    <div id="Dashboard" className="ModuleContainer">
      <Incomes data={incomes} />

      <div className="dashboard_row">
        <OrdersPerHour data={ordersPerHour} />
        <OrdersWeekly data={ordersWeekly} />
      </div>

      <IncomesVsExpenses data={incomesVsExpenses} />

      <div className="dashboard_row">
        <OrdersList setLoading={setLoading} widget={true} />
        <TopSellers data={topSellers} />
      </div>

    </div>
  );
}
