import Box from "Elements/Box";

export default function Incomes(props: any) {
  const { data = { last_7_days: 0, month: 0, last_6_months: 0, year: 0 } } = props;

  return (
    <div className="dashboard_circle_indicators grid">
      <Box className='dashboard_circle_indicator dashboard_indicator_today'>
        <div className="indicator_info">
          <span className='indicator_value'>${data.last_7_days}</span>
          <span className='indicator_label'>LAST 7 DAYS</span>
        </div>
      </Box>
      <Box className='dashboard_circle_indicator'>
        <div className='circle_graph'></div>
        <div className="indicator_info">
          <span className='indicator_value'>${data.month}</span>
          <span className='indicator_label'>CURRENT MONTH</span>
        </div>
      </Box>
      <Box className='dashboard_circle_indicator'>
        <div className="indicator_info">
          <span className='indicator_value'>${data.last_6_months}</span>
          <span className='indicator_label'>LAST 6 MONTHS</span>
        </div>
      </Box>
      <Box className='dashboard_circle_indicator'>
        <div className="indicator_info">
          <span className='indicator_value'>${data.year}</span>
          <span className='indicator_label'>CURRENT YEAR</span>
        </div>
      </Box>
    </div>
  );
}
