import { useEffect, useState } from 'react';
import Box from 'Elements/Box';
import { Button, IconButton, Pagination, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Menu, MenuItem, Chip } from '@mui/material';
import { useSnackbar } from 'notistack';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import AddIcon from '@mui/icons-material/Add';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { ApiRequest } from 'GlobalFunctions';
import EmptyElement from 'Elements/EmptyElement';
import AlertDialog from 'Elements/AlertDialog';
import FiltersByTable from 'Elements/Filters/FiltersByTable';
import FiltersApplied from 'Elements/Filters/FiltersApplied';
import ShiftsEditor from './ShiftsEditor';

export default function ShiftsList(props: any) {
  const { company_id = null, is_popup = false, setLoading } = props;
  const { enqueueSnackbar } = useSnackbar();

  const [shift_list, setShiftList] = useState<any[]>([]);
  const [pagination, setPagination] = useState({
    total_pages: 1,
    total_items: 0,
  });
  const [page, setPage] = useState(1);
  const [openEditor, setOpenEditor] = useState(false);
  const [activeItem, setActiveItem] = useState<any>(null);
  const [openAlert, setOpenAlert] = useState(false);
  const [menuAnchor, setMenuAnchor] = useState<null | HTMLElement>(null);
  const [filtersList, setFiltersList] = useState<any[]>([]);
  const [filtersValues, setFiltersValues] = useState<any>([]);

  useEffect(() => {
    loadList();
  }, [page, filtersValues]);

  const loadList = async () => {
    setLoading(true);
    await ApiRequest({
      url: "/shifts/list",
      method: "get",
      headers: {
        'ltkn': localStorage.getItem('ltkn')
      },
      query: {
        page: page,
        filters: filtersValues,
        company_id: company_id
      },
      setResponse: (response: any) => {
        setShiftList(response.list);
        setPagination(response.pagination);
        setFiltersList(response.filtersList);
        setLoading(false);
      }
    });
    localStorage.setItem('page', page.toString());
  };

  const handleDelete = (shift_id: number) => {
    setActiveItem(shift_id);
    setOpenAlert(true);
  };

  const deleteItem = async () => {
    await ApiRequest({
      url: "/shifts/deleteItem",
      method: "post",
      body: { shift_id: activeItem },
      setResponse: (response: any) => {
        if (response.status === "success") {
          enqueueSnackbar('Turno eliminado con éxito', { variant: 'success' });
          loadList();
        }
        setOpenAlert(false);
      }
    });
  };

  const handleMenuOpen = (event: React.MouseEvent<HTMLElement>, shift_id: number) => {
    setMenuAnchor(event.currentTarget);
    setActiveItem(shift_id);
  };

  const handleMenuClose = () => {
    setMenuAnchor(null);
  };

  return (
<>
    <div className="moduleHeader">
    <h1 className="component_title">Turnos</h1>
    <div className="boxContainerActions">
      <Button
        variant="text"
        color="primary"
        onClick={() => {
          setActiveItem(null);
          setOpenEditor(true);
        }}
        startIcon={<AddIcon />}
      >
        Crear Turno
      </Button>

      <FiltersByTable 
        filters={filtersList} 
        setFilters={setFiltersValues} 
        searchBy={['shifts.shift_id', 'users.name']}
      />
    </div>
  </div>

    <div id='ShiftsList' className='ModuleContainer'>


      <div className="ModuleWrapper">
        <div id='ShiftsListIn'>
          <Box id='AdminList' className={!is_popup ? 'box100' : 'boxModal'}>
            <FiltersApplied filters={filtersValues} setFilters={setFiltersValues} />

            {shift_list.length !== 0 ? (
              <TableContainer>
                <Table aria-label="sticky table">
                  <TableHead>
                    <TableRow>
                      <TableCell>SHIFT ID</TableCell>
                      <TableCell>EMPLOYEE</TableCell>
                      <TableCell>TIME</TableCell>
                      <TableCell>STATUS</TableCell>
                      <TableCell>MODIFIED</TableCell>
                      <TableCell>ACTIONS</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {shift_list.map((shift, i) => (
                      <TableRow key={i} hover role="checkbox">
                        <TableCell>{shift.shift_id}</TableCell>
                        <TableCell>
                          {shift.name} {shift.last_name}<br />
                          <small>{shift.role}</small>
                        </TableCell>
                        <TableCell>
                          {shift.hours_diff} hours<br />
                          <small>{shift.minutes_diff} minutes</small>
                        </TableCell>
                        <TableCell>
                          <Chip label={shift.shift_end_time !== '00:00:00' ? 'Completed' : 'In Progress'} color={shift.shift_end_time !== '00:00:00' ? "primary" : "secondary"} variant="outlined" size='small' />
                        </TableCell>
                        <TableCell>{shift.modified_time}</TableCell>
                        <TableCell align="right">
                          <IconButton aria-label="edit" color="primary" onClick={(event) => handleMenuOpen(event, shift.shift_id)}>
                            <MoreHorizIcon color='secondary' />
                          </IconButton>
                          <Menu
                            anchorEl={menuAnchor}
                            open={Boolean(menuAnchor && activeItem === shift.shift_id)}
                            onClose={handleMenuClose}
                          >
                            <MenuItem onClick={() => {
                              setOpenEditor(true);
                              handleMenuClose();
                            }}>
                              <ModeEditIcon fontSize="small" />
                              Editar
                            </MenuItem>
                            <MenuItem onClick={() => handleDelete(shift.shift_id)}>
                              <DeleteOutlineOutlinedIcon fontSize="small" />
                              Eliminar
                            </MenuItem>
                          </Menu>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            ) : (
              <div className="emptyList">
                <EmptyElement title="No hay turnos registrados" subtitle="Empieza creando un nuevo turno" />
              </div>
            )}
            {pagination.total_pages > 1 && (
              <div className="pagination">
                <Pagination
                  count={pagination.total_pages}
                  page={page}
                  onChange={(event, value) => setPage(value)}
                  color="primary"
                  style={{ marginTop: '20px', alignSelf: 'center' }}
                />
              </div>
            )}
          </Box>
        </div>
      </div>

      <AlertDialog
        open={openAlert}
        setOpen={setOpenAlert}
        subtitle="¿Está seguro que desea eliminar este turno?"
        send={deleteItem}
      />

      <ShiftsEditor
        setLoading={setLoading}
        openEditor={openEditor}
        setOpenEditor={setOpenEditor}
        shift_id={activeItem}
        loadList={loadList}
        company_id={company_id}
      />
    </div>
    </>
  );
}
