import { useEffect, useState } from 'react';
import Box from 'Elements/Box';
import { Avatar, Button, IconButton, Pagination, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { useSnackbar } from 'notistack';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import AddIcon from '@mui/icons-material/Add';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import ImageIcon from '@mui/icons-material/Image';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import EmployeeEditor from './EmployeeEditor';
import EmptyElement from 'Elements/EmptyElement';
import { ApiRequest } from 'GlobalFunctions';
import AlertDialog from 'Elements/AlertDialog';
import FiltersByTable from 'Elements/Filters/FiltersByTable';
import FiltersApplied from 'Elements/Filters/FiltersApplied';

export default function EmployeeList(props: any) {
  const { setLoading } = props;
  const { enqueueSnackbar } = useSnackbar();

  const [employeeList, setEmployeeList] = useState<any[]>([]);
  const [pagination, setPagination] = useState({
    total_pages: 1,
    total_items: 0,
  });
  const [page, setPage] = useState(1);
  const [openEditor, setOpenEditor] = useState(false);
  const [activeItem, setActiveItem] = useState<any>(null);
  const [openAlert, setOpenAlert] = useState(false);
  const [menuAnchor, setMenuAnchor] = useState<null | HTMLElement>(null);
  const [filtersList, setFiltersList] = useState<any[]>([]);
  const [filtersValues, setFiltersValues] = useState<any>([]);

  useEffect(() => {
    loadList();
  }, [page, filtersValues]);

  const loadList = async () => {
    setLoading(true);

    await ApiRequest({
      url: '/employees/list',
      method: 'get',
      query: { page, filters: filtersValues },
      setResponse: (response: any) => {
        setEmployeeList(response.list);
        setPagination(response.pagination)
        setFiltersList(response.filtersList);
        setLoading(false);
      },
    });
  };

  const handleDelete = (employe_id: number) => {
    setActiveItem(employe_id);
    setOpenAlert(true);
  };

  const deleteItem = async () => {
    await ApiRequest({
      url: '/employees/deleted',
      method: 'post',
      body: { employe_id: activeItem },
      setResponse: (response: any) => {
        if (response.status === 'success') {
          enqueueSnackbar('Employee deleted successfully', { variant: 'success' });
          loadList();
        } else {
          enqueueSnackbar(response.message, { variant: 'error' });
        }
        setOpenAlert(false);
      },
    });
  };

  const handleMenuOpen = (event: React.MouseEvent<HTMLElement>, employe_id: number) => {
    setMenuAnchor(event.currentTarget);
    setActiveItem(employe_id);
  };

  const handleMenuClose = () => {
    setMenuAnchor(null);
  };

  return (
    <>
      <div className="moduleHeader">
        <h1 className="component_title">Employees</h1>
        <div className="boxContainerActions">
          <Button
            variant="text"
            color="primary"
            onClick={() => {
              setActiveItem(null);
              setOpenEditor(true);
            }}
            startIcon={<AddIcon />}
          >
            Add Employee
          </Button>

          <FiltersByTable 
            filters={filtersList} 
            setFilters={setFiltersValues} 
            searchBy={['e.employe_id', 'e.name']}
          />
        </div>
      </div>

      <div id="EmployeeList" className="ModuleContainer">
        <div className="ModuleWrapper">
          <div id="EmployeeListIn">
            <Box id="AdminList" className="box100">
              <FiltersApplied filters={filtersValues} setFilters={setFiltersValues} />

              {employeeList.length !== 0 ? (
                <TableContainer>
                  <Table aria-label="sticky table">
                    <TableHead>
                      <TableRow>
                        <TableCell>ID</TableCell>
                        <TableCell>IMAGE</TableCell>
                        <TableCell>NAME</TableCell>
                        <TableCell>EMAIL</TableCell>
                        <TableCell>PHONE</TableCell>
                        <TableCell>POSITION</TableCell>
                        <TableCell>REGULAR COST</TableCell>
                        <TableCell>EXTRA COST</TableCell>
                        <TableCell>PLATFORM ACCESS</TableCell>
                        <TableCell>ACTIONS</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {employeeList.map((employee, i) => (
                        <TableRow key={i} hover role="checkbox">
                          <TableCell>{employee.employe_id}</TableCell>
                          <TableCell>
                            <Avatar src={employee.image} sx={{ marginRight: 2 }}>
                              {!employee.image && <ImageIcon />}
                            </Avatar>
                          </TableCell>
                          <TableCell>{`${employee.name} ${employee.last_name}`}</TableCell>
                          <TableCell>{employee.email}</TableCell>
                          <TableCell>{employee.phone}</TableCell>
                          <TableCell>{employee.position}</TableCell>
                          <TableCell>${Number(employee.regular_cost).toLocaleString()}</TableCell>
                          <TableCell>${Number(employee.extra_cost).toLocaleString()}</TableCell>
                          <TableCell>{employee.platform_access ? 'Yes' : 'No'}</TableCell>
                          <TableCell align="right">
                            <IconButton aria-label="edit" color="primary" onClick={(event) => handleMenuOpen(event, employee.employe_id)}>
                              <MoreHorizIcon color='secondary' />
                            </IconButton>
                            <Menu
                              anchorEl={menuAnchor}
                              open={Boolean(menuAnchor && activeItem === employee.employe_id)}
                              onClose={handleMenuClose}
                            >
                              <MenuItem onClick={() => {
                                setOpenEditor(true);
                                handleMenuClose();
                              }}>
                                <ModeEditIcon fontSize="small" />
                                Edit
                              </MenuItem>
                              <MenuItem onClick={() => handleDelete(employee.employe_id)}>
                                <DeleteOutlineOutlinedIcon fontSize="small" />
                                Remove
                              </MenuItem>
                            </Menu>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              ) : (
                <div className="emptyList">
                  <EmptyElement title="No employees registered" subtitle="Start by creating a new employee" />
                </div>
              )}
              {pagination.total_pages > 1 && (
                <div className="pagination">
                  <Pagination
                    count={pagination.total_pages}
                    page={page}
                    onChange={(event, value) => {
                      setPage(value);
                    }}
                    color="secondary"
                    style={{ marginTop: '20px', alignSelf: 'center' }}
                  />
                </div>
              )}
            </Box>
          </div>
        </div>

        <EmployeeEditor
          setLoading={setLoading}
          openEditor={openEditor}
          setOpenEditor={setOpenEditor}
          employe_id={activeItem}
          loadList={loadList}
        />

        <AlertDialog
          open={openAlert}
          setOpen={setOpenAlert}
          subtitle="Are you sure you want to delete this employee?"
          send={deleteItem}
        />
      </div>
    </>
  );
}
