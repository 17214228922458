import Box from "Elements/Box";
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, Filler } from 'chart.js';
import { Line } from 'react-chartjs-2';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, Filler);

export default function OrdersPerHour(props: any) {

    const { data } = props;

    const uData = data?.values || [4000];
    const xLabels = data?.labels || [ 
      '-',
    ];

    const chartData = {
        labels: xLabels,
        datasets: [
            {
                label: 'Orders Per Hour',
                data: uData,
                fill: true,
                backgroundColor: 'rgba(50, 184, 108, 0.2)',
                borderColor: '#32B86C',
                tension: 0.4,
            },
        ],
    };

    const options = {
        responsive: true,
        plugins: {
            legend: {
                display: false,
            },
        },
    };

    return (
        <Box className='' title="Orders Per Hour" subtitle="Last 24 hours">
            <Line data={chartData} options={options} />
        </Box>
    )
}