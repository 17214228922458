import React from 'react';
import './NotAuthorized.scss';
import notAuthorizedImage from 'Assets/img/notAuthorized.png';

const NotAuthorized = () => {
  return (
    <div className="not-authorized-container">
      <div className="not-authorized-box">
        <img src={notAuthorizedImage} alt="Not authorized" />
        <div className="not-authorized-header">
            <h2>Not authorized</h2>
        </div>
        <div className="not-authorized-text">
            <p>Sorry, you do not have permission to view this page.</p>
        </div>
      </div>
    </div>
  );
};

export default NotAuthorized;
