import React, { useState, useEffect } from 'react';
import { Button, Dialog, TextField } from '@mui/material';
import { useSnackbar } from 'notistack';
import { ApiRequest } from 'GlobalFunctions';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

export default function ExpensesEditor(props: any) {
  const {
    setLoading,
    openEditor,
    setOpenEditor,
    expense_id,
    loadList,
  } = props;

  const { enqueueSnackbar } = useSnackbar();

  const defaultValues: any = {
    expense_id: expense_id ? expense_id : null,
    expense_name: '',
    amount: '',
    date: null,
  };

  const [formValues, setFormValues] = useState(defaultValues);

  useEffect(() => {
    if (expense_id && openEditor) {
      loadExpenseDetails();
    } else {
      setFormValues(defaultValues);
    }
  }, [expense_id, openEditor]);

  const loadExpenseDetails = async () => {
    setLoading(true);

    await ApiRequest({
      url: '/expenses/details',
      method: 'get',
      query: { expense_id },
      setResponse: (response: any) => {
        if (response.status === 'success') {
          setFormValues(response.data);
        } else {
          enqueueSnackbar('Error loading expense details', { variant: 'error' });
        }
        setLoading(false);
      },
    });
  };

  const handleInputChange = (e: { target: { name: any; value: any; }; }) => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };

  const handleDateChange = (date: any) => {
    setFormValues({
      ...formValues,
      date: date,
    });
  };

  const handleSubmit = async () => {

    const body = new FormData();
    body.append('formValues', JSON.stringify(formValues));

    setLoading(true);

    await ApiRequest({
      url: '/expenses/addedit',
      method: 'post',
      formData: true,
      body: body,
      setResponse: (response: any) => {
        if (response.status === 'success') {
          enqueueSnackbar('Expense saved successfully', { variant: 'success' });
          setOpenEditor(false);
          loadList();
        } else {
          enqueueSnackbar(response.message, { variant: 'error' });
        }
        setLoading(false);
      },
    });
  };

  return (
    <Dialog
      open={openEditor}
      onClose={() => setOpenEditor(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      maxWidth="sm"
      fullWidth
    >
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <div className="DialogContainer" style={{ padding: '20px' }}>
          <h2 style={{ fontWeight: 'bold' }}>{expense_id ? 'Edit Expense' : 'Add Expense'}</h2>
          <div style={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
            <TextField
              id="expense_name"
              name="expense_name"
              label="Name"
              fullWidth
              required
              value={formValues.expense_name}
              onChange={handleInputChange}
            />
            <TextField
              id="amount"
              name="amount"
              label="Amount"
              fullWidth
              required
              value={formValues.amount}
              onChange={handleInputChange}
            />
            <DatePicker
              label="Date"
              value={formValues.date}
              onChange={handleDateChange}
              renderInput={(params: any) => <TextField {...params} fullWidth required />}
            />
          </div>

          <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '20px' }}>
            <Button
              onClick={() => setOpenEditor(false)}
              variant="outlined"
              style={{ marginRight: '10px' }}
            >
              CANCEL
            </Button>
            <Button onClick={handleSubmit} variant="contained" color="secondary">
              CONFIRM
            </Button>
          </div>
        </div>
      </LocalizationProvider>
    </Dialog>
  );
}