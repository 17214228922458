import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { LoadingButton } from '@mui/lab';
import { ApiRequest } from 'GlobalFunctions';

export default function OrderSuccess(props: any) {
  const { order_id } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const [isPrintingInvoice, setIsPrintingInvoice] = useState(false);

  const handlePrintInvoice = () => {
    setIsPrintingInvoice(true);
    ApiRequest({
      url: '/orders/print-invoice',
      method: 'get',
      query: { order_id },
      setResponse: (response: any) => {
        if (response.status === 'success' && response.fileUrl) {
          window.open(response.fileUrl, '_blank');
        } else {
          enqueueSnackbar('Error generating invoice', { variant: 'error' });
        }
        setIsPrintingInvoice(false);
      },
    }).catch(() => {
      enqueueSnackbar('Error generating invoice', { variant: 'error' });
      setIsPrintingInvoice(false);
    });
  };

  return (
    <div className="order-success">
      <h1>ORDER SUCCESS</h1>
      <p>Your order has been successfully placed.</p>
      <p>Please wait while we process your order...</p>
      <LoadingButton
        loading={isPrintingInvoice}
        variant="contained"
        color="secondary"
        onClick={handlePrintInvoice}
      >
        Print Invoice
      </LoadingButton>
    </div>
  );
}