import React, { useState, useEffect } from 'react';
import { Button, Dialog, TextField, Switch, FormControlLabel } from '@mui/material';
import { useSnackbar } from 'notistack';
import { ApiRequest } from 'GlobalFunctions';
import ImageUploader from 'Elements/ImageUploader';

export default function EmployeeEditor(props: any) {
  const {
    setLoading,
    openEditor,
    setOpenEditor,
    employe_id,
    loadList,
  } = props;

  const { enqueueSnackbar } = useSnackbar();

  const defaultValues: any = {
    employe_id: employe_id ? employe_id : null,
    name: '',
    last_name: '',
    email: '',
    phone: '',
    regular_cost: '',
    extra_cost: '',
    position: '',
    platform_access: false,
    image: '',
  };

  const [formValues, setFormValues] = useState(defaultValues);
  const [files, setFiles] = useState<any>([]);

  useEffect(() => {
    if (employe_id && openEditor) {
      loadEmployeeDetails();
    } else {
      setFormValues(defaultValues);
      setFiles([]);
    }
  }, [employe_id, openEditor]);

  const loadEmployeeDetails = async () => {
    setLoading(true);

    await ApiRequest({
      url: '/employees/details',
      method: 'get',
      query: { employe_id },
      setResponse: (response: any) => {
        if (response.status === 'success') {
          setFormValues(response.data);
        } else {
          enqueueSnackbar('Error loading employee details', { variant: 'error' });
        }
        setLoading(false);
      },
    });
  };

  const handleInputChange = (e: { target: { name: any; value: any; }; }) => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };

  const handleSubmit = async () => {
    const body = new FormData();
    body.append('formValues', JSON.stringify(formValues));
    if (files) {
      body.append("image", files);
    }

    setLoading(true);

    await ApiRequest({
      url: '/employees/addedit',
      method: 'post',
      formData: true,
      body: body,
      setResponse: (response: any) => {
        if (response.status === 'success') {
          enqueueSnackbar('Employee saved successfully', { variant: 'success' });
          setOpenEditor(false);
          loadList();
        } else {
          enqueueSnackbar(response.message, { variant: 'error' });
        }
        setLoading(false);
      },
    });
  };

  return (
    <Dialog
      open={openEditor}
      onClose={() => setOpenEditor(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      maxWidth="md"
      fullWidth
    >
      <div className="DialogContainer" style={{ display: 'flex', flexDirection: 'row', padding: '20px' }}>
        <div className="EmployeeImageContainer" style={{ flex: 1 }}>
          <ImageUploader 
            className="employee_image"
            image={formValues.image} 
            setImage={(newImage) => setFormValues({ ...formValues, image: newImage })}
            setFiles={setFiles} 
          />
        </div>

        <div className="EmployeeFormContainer" style={{ flex: 2, marginLeft: '20px' }}>
          <h2 style={{ fontWeight: 'bold' }}>ADD EMPLOYEE</h2>
          <div style={{ display: 'flex', flexWrap: 'wrap', gap: '20px' }}>
            <div className='input_row'>
              <TextField
                name="name"
                label="Name"
                fullWidth
                required
                value={formValues.name}
                onChange={handleInputChange}
              />

              <TextField
                name="last_name"
                label="Last Name"
                fullWidth
                required
                value={formValues.last_name}
                onChange={handleInputChange}
              />

              <TextField
                name="position"
                label="Position"
                fullWidth
                required
                value={formValues.position}
                onChange={handleInputChange}
              />
            </div>

            <div className='input_row'>
              <TextField
                name="email"
                label="Email"
                type="email"
                fullWidth
                required
                value={formValues.email}
                onChange={handleInputChange}
              />

              <TextField
                name="phone"
                label="Phone"
                type="number"
                fullWidth
                required
                value={formValues.phone}
                onChange={handleInputChange}
              />
            </div>

            <div className='input_row'>
              <TextField
                name="regular_cost"
                label="Regular Cost"
                type="number"
                fullWidth
                required
                value={formValues.regular_cost}
                onChange={handleInputChange}
              />

              <TextField
                name="extra_cost"
                label="Extra Cost"
                type="number"
                fullWidth
                required
                value={formValues.extra_cost}
                onChange={handleInputChange}
              />
            </div>

            <FormControlLabel
              control={
                <Switch
                  checked={formValues.platform_access}
                  onChange={(e) => setFormValues({ ...formValues, platform_access: e.target.checked })}
                />
              }
              label="Platform Access"
            />
          </div>

          <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '20px' }}>
            <Button
              onClick={() => setOpenEditor(false)}
              variant="outlined"
              style={{ marginRight: '10px' }}
            >
              CANCEL
            </Button>
            <Button onClick={handleSubmit} variant="contained" color="secondary">
              CONFIRM
            </Button>
          </div>
        </div>
      </div>
    </Dialog>
  );
}
