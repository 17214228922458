import React, { useEffect, useState } from 'react';
import Box from 'Elements/Box';
import { Button, Pagination, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Menu, MenuItem, IconButton, Avatar, Chip } from '@mui/material';
import { useSnackbar } from 'notistack';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import AddIcon from '@mui/icons-material/Add';
import ImageIcon from '@mui/icons-material/Image';
import { ApiRequest } from 'GlobalFunctions';
import AlertDialog from 'Elements/AlertDialog';
import FiltersByTable from 'Elements/Filters/FiltersByTable';
import FiltersApplied from 'Elements/Filters/FiltersApplied';
import EmptyElement from 'Elements/EmptyElement';
import AdminOrdersEditor from './AdminOrdersEditor';

export default function AdminOrdersList(props: any) {
  const { setLoading, widget = false} = props;
  const { enqueueSnackbar } = useSnackbar();

  const [ordersList, setOrdersList] = useState<any[]>([]);
  const [pagination, setPagination] = useState({ total_pages: 1, total_items: 0 });
  const [page, setPage] = useState(1);
  const [openEditor, setOpenEditor] = useState(false);
  const [activeItem, setActiveItem] = useState<any>(null);
  const [openAlert, setOpenAlert] = useState(false);
  const [menuAnchor, setMenuAnchor] = useState<null | HTMLElement>(null);
  const [filtersList, setFiltersList] = useState<any[]>([]);
  const [filtersValues, setFiltersValues] = useState<any>([]);

  useEffect(() => {
    loadList();
  }, [page, filtersValues]);

  const loadList = async () => {
    setLoading(true);
    await ApiRequest({
      url: '/admin_orders/list',
      method: 'get',
      query: { page, filters: filtersValues },
      setResponse: (response: any) => {
        setOrdersList(response.list);
        setPagination(response.pagination);
        setFiltersList(response.filtersList);
        setLoading(false);
      },
    });
  };

  const handleDelete = (order_id: number) => {
    setActiveItem(order_id);
    setOpenAlert(true);
  };

  const deleteItem = async () => {
    await ApiRequest({
      url: '/admin_orders/deleteItem',
      method: 'post',
      body: { order_id: activeItem },
      setResponse: (response: any) => {
        if (response.status === 'success') {
          enqueueSnackbar(response.message, { variant: 'success' });
          loadList();
        } else {
          enqueueSnackbar(response.message, { variant: 'error' });
        }
        setOpenAlert(false);
      },
    });
  };

  const handleMenuOpen = (event: React.MouseEvent<HTMLElement>, order_id: number) => {
    setMenuAnchor(event.currentTarget);
    setActiveItem(order_id);
  };

  const handleMenuClose = () => {
    setMenuAnchor(null);
  };

  const statusColors: { [key: string]: 'default' | 'primary' | 'secondary' | 'error' | 'warning' | 'info' | 'success' } = {
    active: 'success',
    upgraded: 'primary',
    downgraded: 'error',
    downgrading: 'error',
    pending_downgrade: 'error',
    inactive: 'default',
    renewed: 'success',
    pending_renewal: 'warning',
    upgrading: 'info',
    pending_upgrade: 'info',
  };

  const capitalize = (str: string) => str.charAt(0).toUpperCase() + str.slice(1);

  const formatStatus = (str: string) => {
    const capitalized = str.charAt(0).toUpperCase() + str.slice(1);
    return capitalized.replace(/_/g, ' ');
  };

  const formatDateTime = (dateTime: string) => {
    const [date, time] = dateTime.split(' ');
    return (
      <>
        <div>{date}</div>
        <div>{time}</div>
      </>
    );
  };

  return (
    <>
      {!widget && (
        <div className="moduleHeader">
          <h1 className="component_title">Admin Orders</h1>
          <div className="boxContainerActions">
            <FiltersByTable
              filters={filtersList}
              setFilters={setFiltersValues}
              searchBy={['company_name', 'plan_name', 'created_at']}
            />
          </div>
        </div>
      )}

      <div className={`ModuleContainer ${widget ? 'widget' : ''}`}>
        <Box className="box100">
          <FiltersApplied filters={filtersValues} setFilters={setFiltersValues} />
          {ordersList.length !== 0 ? (
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>ID</TableCell>
                    <TableCell>Company</TableCell>
                    <TableCell>Plan</TableCell>
                    <TableCell>Created At</TableCell>
                    {!widget && <TableCell>Plan Type</TableCell>}
                    <TableCell>Amount Paid</TableCell>
                    {!widget && <TableCell>Recurring</TableCell>}
                    <TableCell>Status</TableCell>
                    {/* ...add any needed columns (active_since, active_until...) */}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {ordersList.map((item, i) => (
                    <TableRow key={i} hover>
                      <TableCell>{item.company_plan_history_id}</TableCell>
                      <TableCell>{item.company_name}</TableCell>
                      <TableCell>{item.plan_name}</TableCell>
                      <TableCell>{formatDateTime(item.created_at)}</TableCell>
                      {!widget && <TableCell>{item.plan_type}</TableCell>}
                      <TableCell>${item.amount_paid}</TableCell>
                      {!widget && <TableCell>${item.recurring_amount}</TableCell>}
                      <TableCell>
                        <Chip variant='outlined' label={formatStatus(item.status)} color={statusColors[item.status] || 'default'} />
                      </TableCell>
                      {/* ...similarly show other required fields... */}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          ) : (
            <EmptyElement title="No orders found" subtitle="Add an order to get started" />
          )}
          {pagination.total_pages > 1 && (
            <div className="pagination">
              <Pagination
                count={pagination.total_pages}
                page={page}
                onChange={(event, value) => setPage(value)}
                color="secondary"
              />
            </div>
          )}
        </Box>
      </div>

      <AdminOrdersEditor
        setLoading={setLoading}
        openEditor={openEditor}
        setOpenEditor={setOpenEditor}
        order_id={activeItem}
        loadList={loadList}
      />

      <AlertDialog
        open={openAlert}
        setOpen={setOpenAlert}
        subtitle="Are you sure you want to delete this order?"
        send={deleteItem}
      />
    </>
  );
}