import { useEffect, useState } from 'react';
import Box from 'Elements/Box';
import { Button, IconButton, Pagination, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Menu, MenuItem, Chip } from '@mui/material';
import { useSnackbar } from 'notistack';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import AddIcon from '@mui/icons-material/Add';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { ApiRequest } from 'GlobalFunctions';
import EmptyElement from 'Elements/EmptyElement';
import AlertDialog from 'Elements/AlertDialog';
import FiltersByTable from 'Elements/Filters/FiltersByTable';
import FiltersApplied from 'Elements/Filters/FiltersApplied';
import PayrollEditor from './PayrollEditor';

export default function Payroll(props: any) {
  const { company_id = null, is_popup = false, setLoading } = props;
  const { enqueueSnackbar } = useSnackbar();

  const [payroll_list, setPayrollList] = useState<any[]>([]);
  const [pagination, setPagination] = useState({
    total_pages: 1,
    total_items: 0,
  });
  const [page, setPage] = useState(1);
  const [openEditor, setOpenEditor] = useState(false);
  const [activeItem, setActiveItem] = useState<any>(null);
  const [openAlert, setOpenAlert] = useState(false);
  const [menuAnchor, setMenuAnchor] = useState<null | HTMLElement>(null);
  const [filtersList, setFiltersList] = useState<any[]>([]);
  const [filtersValues, setFiltersValues] = useState<any[]>([]);
  const [openStatusAlert, setOpenStatusAlert] = useState(false);
  const [selectedPayroll, setSelectedPayroll] = useState<any>(null);
  const [totalPendingPayment, setTotalPendingPayment] = useState(0);
  const [totalPendingHours, setTotalPendingHours] = useState(0);
  const [daysUntilPayment, setDaysUntilPayment] = useState(0);
  const [nextPaymentDate, setNextPaymentDate] = useState('');
  const [estimatedPaymentValue, setEstimatedPaymentValue] = useState(0);

  useEffect(() => {
    loadList();
  }, [page, filtersValues]);

  const loadList = async () => {
    setLoading(true);
    await ApiRequest({
      url: "/payroll/list",
      method: "get",
      headers: {
        'ltkn': localStorage.getItem('ltkn')
      },
      query: {
        page: page,
        filters: filtersValues,
        company_id: company_id
      },
      setResponse: (response: any) => {
        setPayrollList(response.list);
        setPagination(response.pagination);
        setFiltersList(response.filtersList);
        setTotalPendingPayment(response.total_pending_payment || 0);
        setTotalPendingHours(response.total_pending_hours || 0);
        setDaysUntilPayment(response.days_until_payment || 0);
        setNextPaymentDate(response.next_payment_date || '');
        setEstimatedPaymentValue(response.estimated_payment_value || 0);
        setLoading(false);
      }
    });
    localStorage.setItem('page', page.toString());
  };

  const handleDelete = (payroll_id: number) => {
    setActiveItem(payroll_id);
    setOpenAlert(true);
  };

  const deleteItem = async () => {
    await ApiRequest({
      url: "/payroll/delete",
      method: "post",
      body: { payroll_id: activeItem },
      setResponse: (response: any) => {
        if (response.status === "success") {
          enqueueSnackbar('Payroll successfully deleted', { variant: 'success' });
          loadList();
        }
        setOpenAlert(false);
      }
    });
  };

  const handleMenuOpen = (event: React.MouseEvent<HTMLElement>, payroll_id: number) => {
    setMenuAnchor(event.currentTarget);
    setActiveItem(payroll_id);
  };

  const handleMenuClose = () => {
    setMenuAnchor(null);
  };

  const handleStatusChange = (payroll: any) => {
    setSelectedPayroll(payroll);
    setOpenStatusAlert(true);
  };

  const changeStatus = async () => {
    await ApiRequest({
      url: "/payroll/change-status",
      method: "post",
      body: { payroll_id: selectedPayroll.payroll_id },
      setResponse: (response: any) => {
        if (response.status === "success") {
          enqueueSnackbar('Payroll status successfully changed', { variant: 'success' });
          loadList();
        }
        setOpenStatusAlert(false);
      }
    });
  };

  return (
    <>
      <div className="moduleHeader">
        <h1 className="component_title">Payroll</h1>
        <div className="boxContainerActions">
          <Button
            variant="text"
            color="primary"
            onClick={() => {
              setActiveItem(null);
              setOpenEditor(true);
            }}
            startIcon={<AddIcon />}
          >
            Create Payroll
          </Button>

          <FiltersByTable 
            filters={filtersList} 
            setFilters={setFiltersValues} 
            searchBy={['payroll.payroll_id', 'users.name']}
          />
        </div>
      </div>

      <div id='PayrollList' className='ModuleContainer'>
        <div className="ModuleWrapper">
          <div id='PayrollListIn'>
            

            <div className="payroll_indicators">
              <Box className='current_debt'>
                <span className='indicator_value'>${totalPendingPayment}</span>
                <span className='indicator_label'>CURRENT DEBT</span>
                
              </Box>
              <Box className='current_worked_hours'>
                <span className='indicator_value'>{totalPendingHours}</span>
                <span className='indicator_label'>CURRENT WORKED HOURS</span>
              </Box>
              <Box className='next_payment'>
                <span className='indicator_label indicator_label_highlight'>${estimatedPaymentValue} / ESTIMATE</span>
                <span className='indicator_value paid-date'>{daysUntilPayment} DAYS UNTIL PAID DATE</span>
                <span className='indicator_label'>{nextPaymentDate}</span>
                
              </Box>
            </div>
            <Box id='AdminList' className={!is_popup ? 'box100' : 'boxModal'}>
              <FiltersApplied filters={filtersValues} setFilters={setFiltersValues} />
              
              {payroll_list.length !== 0 ? (
                <TableContainer>
                  <Table aria-label="sticky table">
                    <TableHead>
                      <TableRow>
                        <TableCell>ID</TableCell>
                        <TableCell>EMPLOYEE</TableCell>
                        <TableCell>HOURS</TableCell>
                        <TableCell align='right'>COST</TableCell>
                        <TableCell>DATE</TableCell>
                        <TableCell>STATUS</TableCell>
                        <TableCell align='right'>ACTIONS</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {payroll_list.map((payroll, i) => (
                        <TableRow key={i} hover role="checkbox">
                          <TableCell>{payroll.payroll_id}</TableCell>
                          <TableCell>
                            {payroll.name} {payroll.last_name}<br />
                            <small>{payroll.role}</small>
                          </TableCell>
                          <TableCell>
                            {payroll.regular_time} hours<br />
                            <small>{payroll.extra_time} extra hours</small>
                          </TableCell>
                          <TableCell align='right'>${payroll.total_time_cost}</TableCell>
                          <TableCell>{payroll.date}</TableCell>
                          <TableCell>
                            <Chip
                              label={payroll.status}
                              color={payroll.status === 'pending' ? 'warning' : 'secondary'}
                              sx={{ textTransform: 'uppercase', cursor: 'pointer' }}
                              onClick={() => handleStatusChange(payroll)}
                            />
                          </TableCell>
                          <TableCell align="right">
                            <IconButton aria-label="edit" color="primary" onClick={(event) => handleMenuOpen(event, payroll.payroll_id)}>
                              <MoreHorizIcon color='secondary' />
                            </IconButton>
                            <Menu
                              anchorEl={menuAnchor}
                              open={Boolean(menuAnchor && activeItem === payroll.payroll_id)}
                              onClose={handleMenuClose}
                            >
                              <MenuItem onClick={() => {
                                setOpenEditor(true);
                                handleMenuClose();
                              }}>
                                <ModeEditIcon fontSize="small" />
                                Details
                              </MenuItem>
                              <MenuItem onClick={() => handleDelete(payroll.payroll_id)}>
                                <DeleteOutlineOutlinedIcon fontSize="small" />
                                Delete
                              </MenuItem>
                            </Menu>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              ) : (
                <div className="emptyList">
                  <EmptyElement title="No payrolls registered" subtitle="Start by creating a new payroll" />
                </div>
              )}
              {pagination.total_pages > 1 && (
                <div className="pagination">
                  <Pagination
                    count={pagination.total_pages}
                    page={page}
                    onChange={(event, value) => setPage(value)}
                    color="primary"
                    style={{ marginTop: '20px', alignSelf: 'center' }}
                  />
                </div>
              )}
            </Box>
          </div>
        </div>

        <AlertDialog
          open={openAlert}
          setOpen={setOpenAlert}
          subtitle="Are you sure you want to delete this payroll?"
          send={deleteItem}
        />

        <AlertDialog
          open={openStatusAlert}
          setOpen={setOpenStatusAlert}
          subtitle="Are you sure you want to change the status of this payroll?"
          send={changeStatus}
        />

        <PayrollEditor
          setLoading={setLoading}
          openEditor={openEditor}
          setOpenEditor={setOpenEditor}
          payroll_id={activeItem}
        />
      </div>
    </>
  );
}