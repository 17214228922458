import React, { useState, useEffect } from 'react';
import { Button, Dialog, TextField, Switch, FormControlLabel } from '@mui/material';
import { useSnackbar } from 'notistack';
import { ApiRequest } from 'GlobalFunctions';
import ImageUploader from 'Elements/ImageUploader';
import Avatar from '@mui/material';

export default function CategoriesEditor(props: any) {
  const {
    setLoading,
    openEditor,
    setOpenEditor,
    category_id,
    loadList,
  } = props;

  console.log('category_id', category_id);
  const { enqueueSnackbar } = useSnackbar();

  const defaultValues: any = {
    category_id: category_id ? category_id : null,
    name: '',
    description: '',
    image: '',
    deleted: 0,
  };

  const [formValues, setFormValues] = useState(defaultValues);
  const [files, setFiles] = useState<any>([]);

  /*=======================================
  LOAD PRODUCT DETAILS IF EDITING
  =======================================*/
  useEffect(() => {
    if (category_id && openEditor) {
      loadProductDetails();
    } else {
      setFormValues(defaultValues);
    }
  }, [category_id, openEditor]);

  const loadProductDetails = async () => {
    setLoading(true);

    await ApiRequest({
      url: '/categories/details',
      method: 'get',
      query: { category_id },
      setResponse: (response: any) => {
        if (response.status === 'success') {
          setFormValues(response.data);
        } else {
          enqueueSnackbar('Error loading category details', { variant: 'error' });
        }
        setLoading(false);
      },
    });
  };

  /*=======================================
  HANDLE INPUT CHANGE
  =======================================*/
  const handleInputChange = (e: { target: { name: any; value: any; }; }) => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };

  /*=======================================
  SUBMIT FUNCTION
  =======================================*/
  const handleSubmit = async () => {
    const body = new FormData();
    body.append('formValues', JSON.stringify(formValues));
    if (files) {
      body.append("image", files);
    }

    setLoading(true);

    await ApiRequest({  
      url: '/categories/addedit',
      method: 'post',
      formData: true,
      body: body,
      setResponse: (response: any) => {
        if (response.status === 'success') {
          enqueueSnackbar('Category saved successfully', { variant: 'success' });
          setOpenEditor(false);
          loadList();
        } else {
          enqueueSnackbar(response.message, { variant: 'error' });
        }
        setLoading(false);
      },
    });
  };

  return (
    <Dialog
      open={openEditor}
      onClose={() => setOpenEditor(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      maxWidth="md"
      fullWidth
    >
      <div className="DialogContainer ">
        

      <h2 className='editor_title'>ADD CATEGORY</h2>

        <div className="flex_row">
          <div className="ProductImageContainer">
            <ImageUploader 
              className="category_image"
              image={formValues.image} 
              setImage={(newImage) => setFormValues({ ...formValues, image: newImage })}
              setFiles={setFiles} 
            />
          </div>

        
          <div className="ProductFormContainer">
            
            <div className='input_group'>
              <TextField
                id="name"
                name="name"
                label="Name"
                fullWidth
                required
                value={formValues.name}
                onChange={handleInputChange}
              />
              <TextField
                id="description"
                name="description"
                label="Description"
                fullWidth
                multiline
                rows={4}
                value={formValues.description}
                onChange={handleInputChange}
              />
            </div>

            {/* Estado del producto */}
          

            
          </div>
        </div>
        

        <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '20px' }}>
          <Button
            onClick={() => setOpenEditor(false)}
            variant="outlined"
            style={{ marginRight: '10px' }}
          >
            CANCEL
          </Button>
          <Button onClick={handleSubmit} variant="contained" color="secondary">
            CONFIRM
          </Button>
        </div>
        
      </div>
    </Dialog>
  );
}
