import * as React from 'react';
import { Link, Avatar, Button, Container, TextField, Typography, Dialog, DialogContent, InputAdornment, IconButton } from '@mui/material';
import Box from "Elements/Box";
import { ProjectConfig } from 'Global';
import axios from 'axios';
import { useSnackbar, withSnackbar } from 'notistack';
import LoadingButton from '@mui/lab/LoadingButton';
import { useEffect, useState } from 'react';
import { resolve } from 'path';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import SignUp from './SignUp';
import { useSearchParams } from 'react-router-dom';
import FormLogin from './FormLogin';
import FormCredentialsSent from './FormCredentialsSent';
import FormChangePass from './FormChangePass';
import FormForgotPass from './FormForgotPass';
import { platform } from 'os';
import logo from 'Assets/img/logonegativo.png';
import 'Assets/css/main.scss';
import './Login.scss';
import { useNavigate } from 'react-router-dom';

export default withSnackbar(
  function SignIn(props: any) {

    const { setLoading } = props;
    const navigate = useNavigate();

    useEffect(() => {
      setLoading(false);
    }, []);

    /*SNACKBAR FUNCTION FOR NOTIFICATIONS*/
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    /*============================================= */

    const [loadingButton, setLoadingButton] = useState(false);
    const [openRegister, setOpenRegister] = useState(false);
    const [state, setState] = useState('login');
    const [userEmail, setUserEmail] = useState('');
    const [email, setEmail] = useState('');
    const [code, setCode] = useState('');
    const [worstationCode, setWorstationCode] = useState('');

    const [searchParams, setSearchParams] = useSearchParams();

    //IF TOKEN MOBILE APP EXISTS IN URL 
    const tokenApp = localStorage.getItem('tokenApp') ? localStorage.getItem('tokenApp') : '';

    const invitation = localStorage.getItem('invitation') ? localStorage.getItem('invitation') : '';

    useEffect(() => {
      const emailVar = localStorage.getItem('email') ? localStorage.getItem('email') : '';
      setLoading(false);
      setEmail(emailVar || searchParams.get('email') || '');
      setCode(searchParams.get('code') || '');
      setWorstationCode(searchParams.get('workstation_code') || localStorage.getItem('workstation_code') || '')
      // const workstation_code = localStorage.getItem('workstation_code')? localStorage.getItem('workstation_code') : '';
    }, []);

    useEffect(() => {
      if (invitation === "1" && email !== '' && worstationCode !== '') {
        setOpenRegister(true);
      }
    }, [worstationCode]);

    useEffect(() => {
      if (email && code) {
        setState('changePass');
      }
    }, [code]);

    const loginDataDefault = {
      email: email,
      password: '',
      tokenApp: tokenApp,
    }

    const [loginData, setLoginData] = useState(loginDataDefault);


    /*==========================================
    SIGN IN 
    ==========================================*/
    const sign_in = () => {
      axios
        .post(ProjectConfig.api_url + "/auth/sign_in", {
          email: loginData.email,
          password: loginData.password,
          platform: 'web',
        })
        .then((response) => {
          if (response.data.status === "success") {
            enqueueSnackbar(response.data.message, { variant: 'success' });
            const user_info = response.data.user_info;
            localStorage.setItem('ltkn', user_info.ltkn);
            localStorage.setItem('user_id', user_info.user_id);
            localStorage.setItem('user_name', user_info.name);
            localStorage.setItem('role_id', user_info.role_id);
            localStorage.setItem('email', user_info.email);
            localStorage.setItem('subscription_active', user_info.subscription_active);
            navigate(`/${String(user_info.redirect_to)}`);
          } 
          else {
            enqueueSnackbar(response.data.message, { variant: 'error' });
            setLoadingButton(false);
          }
        })
        .catch((err) => {
          console.log(err.data);
        });
    }

    return (
      <div className='loginContainer'>
        <video autoPlay loop muted className='backgroundVideo'>
          <source src='https://jossystem.com/wp-content/uploads/2024/12/3970167-hd_1280_720_30fps.mp4' type='video/mp4' />
        </video>
        <div className='formContainer'>
          {state === 'login' && (
            <FormLogin
              logo={logo}
              setLoading={setLoading}
              setState={setState}
              loginData={loginData}
              setEmail={setEmail}
              setLoginData={setLoginData}
              loadingButton={loadingButton}
              setLoadingButton={setLoadingButton}
              setOpenRegister={setOpenRegister}
              sign_in={sign_in}
            />
          )}
          {state === 'changePass' && (
            <FormChangePass
              loadingButton={loadingButton}
              logo={logo}
              setLoading={setLoading}
              setState={setState}
              email={email}
              code={code}
            />
          )}
          {state === 'forgotPass' && (
            <FormForgotPass
              logo={logo}
              loadingButton={loadingButton}
              setLoading={setLoading}
              setState={setState}
              email={email}
              setEmail={setEmail}
            />
          )}
          {state === 'credentialsSent' && (
            <FormCredentialsSent
              logo={logo}
              setState={setState}
              setEmail={setEmail}
              setLoginData={setLoginData}
              loginDataDefault={loginDataDefault}
            />
          )}
        </div>

        <SignUp
          openRegister={openRegister}
          setOpenRegister={setOpenRegister}
          loginData={loginData}
          setLoginData={setLoginData}
          sign_in={sign_in}
          setLoading={setLoading}
        />
      </div>
    );
  }
);