
import React, { useEffect, useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@mui/material';
import { useSnackbar } from 'notistack';
import { ApiRequest } from 'GlobalFunctions';

export default function AdminOrdersEditor(props: any) {
  const { setLoading, openEditor, setOpenEditor, order_id, loadList } = props;
  const { enqueueSnackbar } = useSnackbar();
  const [formValues, setFormValues] = useState<any>({});

  useEffect(() => {
    if (order_id) {
      loadDetails();
    }
  }, [order_id]);

  const loadDetails = async () => {
    setLoading(true);
    await ApiRequest({
      url: '/admin_orders/details',
      method: 'get',
      query: { order_id },
      setResponse: (response: any) => {
        if (response.status === 'success') {
          setFormValues(response.data);
        } else {
          enqueueSnackbar('Error loading order details', { variant: 'error' });
        }
        setLoading(false);
      }
    });
  };

  const handleInputChange = (e: { target: { name: any; value: any; }; }) => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value
    });
  };

  const handleSubmit = async () => {
    const body = new FormData();
    body.append('formValues', JSON.stringify(formValues));

    setLoading(true);

    await ApiRequest({
      url: '/admin_orders/addedit',
      method: 'post',
      formData: true,
      body: body,
      setResponse: (response: any) => {
        if (response.status === 'success') {
          enqueueSnackbar('Order saved successfully', { variant: 'success' });
          loadList();
          setOpenEditor(false);
        } else {
          enqueueSnackbar(response.message, { variant: 'error' });
        }
        setLoading(false);
      }
    });
  };

  return (
    <Dialog open={openEditor} onClose={() => setOpenEditor(false)} maxWidth="md" fullWidth>
      <DialogTitle>Order Details</DialogTitle>
      <DialogContent>
        <div className="form_container">
          <TextField
            id="order_name"
            name="order_name"
            label="Order Name"
            fullWidth
            required
            value={formValues.order_name}
            onChange={handleInputChange}
          />
          {/* Add more fields as needed */}
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setOpenEditor(false)} color="primary">
          Cancel
        </Button>
        <Button onClick={handleSubmit} variant="contained" color="secondary">
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
}