import * as React from 'react';
import { Menu, MenuItem } from '@mui/material';
import MenuButton from './MenuButton';
import { Link } from 'react-router-dom';

import DataSaverOffIcon from '@mui/icons-material/DataSaverOff';
import PointOfSaleIcon from '@mui/icons-material/PointOfSale';
import DisplaySettingsOutlinedIcon from '@mui/icons-material/DisplaySettingsOutlined';
import StorefrontOutlinedIcon from '@mui/icons-material/StorefrontOutlined';
import WorkspacesOutlinedIcon from '@mui/icons-material/WorkspacesOutlined';
import TableRestaurantIcon from '@mui/icons-material/TableRestaurant';
import ReceiptIcon from '@mui/icons-material/Receipt';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import KitchenIcon from '@mui/icons-material/Kitchen';
import { GiCook } from "react-icons/gi";
import { PiHamburger } from "react-icons/pi";


export default function MainMenu(props: any) {

  const { setLoading } = props;
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [selectedMenu, setSelectedMenu] = React.useState<null | string>(null);
  const open = Boolean(anchorEl);

  const handleMenuOpen = (event: React.MouseEvent<HTMLElement>, menuName: string) => {
    setAnchorEl(event.currentTarget);
    setSelectedMenu(menuName);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setSelectedMenu(null);
  };

  return (
    <>
      <div className='menu_button_container'>
        <MenuButton 
          label="Dashboard" 
          to="/dashboard" 
          startIcon={<DataSaverOffIcon />} 
          setLoading={setLoading} 
          // Eliminamos el onClick que abre el submenú en el padre
        />
        
        <MenuButton 
          label="Companies" 
          to="/companies" 
          startIcon={<PointOfSaleIcon />} 
          setLoading={setLoading} 
        />

        <MenuButton 
          label="Plans" 
          to="/plans" 
          startIcon={<DisplaySettingsOutlinedIcon />} 
          setLoading={setLoading} 
        />


        <MenuButton
          label="Orders"
          to="/orders"
          startIcon={<StorefrontOutlinedIcon />}
          setLoading={setLoading}
        />
        
    
      </div>

      {/* Menu for sub-items */}
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleMenuClose}
      >
        {selectedMenu === 'dashboard' && (
          <>
            <MenuItem onClick={handleMenuClose}>Dashboard Item 1</MenuItem>
            <MenuItem onClick={handleMenuClose}>Dashboard Item 2</MenuItem>
          </>
        )}
        {selectedMenu === 'sell' && (
          <>
            <MenuItem onClick={handleMenuClose}>Sell Item 1</MenuItem>
            <MenuItem onClick={handleMenuClose}>Sell Item 2</MenuItem>
          </>
        )}
      </Menu>
    </>
  );
}
