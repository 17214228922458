import {BrowserRouter as Router , Route, Routes, Navigate} from 'react-router-dom'
import { Fragment } from 'react';
import {Permissions} from './Permissions'; 

import Dashboard from 'Components/Admin/Dashboard/AdminDasboard';
import Companies from 'Components/Admin/Companies/CompaniesList';
import Plans from 'Components/Admin/Plans/PlanList';
import AdminOrders from 'Components/Admin/Orders/AdminOrdersList';

export default function AdminRoutes(props:any) {
  const role_id = Number(localStorage.getItem('role_id'));
  const {setLoading} = props;
  
  return (
    <>
      <Routes>

        <Route path="/" element={<Navigate to="/dashboard" />} />
        <Route path="/dashboard" element={<Dashboard setLoading={setLoading} />} />
        <Route path="/companies" element={<Companies setLoading={setLoading} />} />
        <Route path="/plans" element={<Plans setLoading={setLoading} />} />
        <Route path="/orders" element={<AdminOrders setLoading={setLoading} />} />
      </Routes>
    </>
  );
}

