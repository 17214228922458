import { Button, Paper, TextField, Typography } from "@mui/material"
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Box from "Elements/Box";
import { ProjectConfig } from "Global";
import ISwitch from "Elements/ISwitch";
import ImageUploader from "Elements/ImageUploader";
import { useSnackbar } from "notistack";

const defaultValues: any = {
  user_id: "",
  name: "",
  last_name: "",
  phone: "",
  email: "",
  password: "",
  image: "",

  restrictions: {},
  roles: [],
};



export default function AccountEditor (props: any) {
  const user_id = localStorage.getItem('user_id');
    const { enqueueSnackbar } = useSnackbar();

  const {
    setLoading, 
  } = props;

  const [formValues, setFormValues] = useState(defaultValues);
  const [files, setFiles] = useState<any>([]);



  /*=======================================
  GET USER DETAILS IF USER_ID EXISTS
  =======================================*/
  useEffect(() => {
   
      axios.get(ProjectConfig.api_url + "/users/details", {
        headers: {
          "ltkn": localStorage.getItem('ltkn')
        },
        params: {
          user_id: user_id,
        },
      })
      .then((response) => {
        if (response.data.status === "success") {
          setFormValues(response.data.data);
        }
      })
      .catch((err) => {
        console.log(err.response.data);
      });
  }, [user_id]);

  /*=======================================
  HANDLE INPUT CHANGE
  =======================================*/
  const handleInputChange = (e: { target: { name: any; value: any; }; }) => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };

  /*=======================================
  SUBMIT FUNCTION
  =======================================*/
  const handleSubmit = () => {  
    setLoading(true);
    const body = new FormData();
    body.append("formValues", JSON.stringify(formValues));
    if (files) {
      body.append("image", files);
    }

    axios.post(ProjectConfig.api_url+"/users/addedit", body, {
      headers: {
        "ltkn": localStorage.getItem("ltkn"),
      },
    })
    .then((response) => {
      if(response.data.status === "success"){
        enqueueSnackbar('Usuario guardado con éxito', { variant: 'success' });
      }else{
        enqueueSnackbar(response.data.message, { variant: 'error' });
      }
    })
    .catch((err) => {
      console.log(err.response.data);
    })
    .finally(() => {
      setLoading(false);
    });  
  };
  return (
<>
    <div className="moduleHeader">
      <h1 className="component_title">Account</h1>
      <div className="boxContainerActions">
       
      </div>
    </div>
  <div id='UsersList' className='ModuleContainer'>
    

    <div className="ModuleWrapper">
      <div id='UsersListIn'>
        <Box>
          <div className="UserImageContainer" style={{ flex: 1 }}>
                    <ImageUploader 
                      className="user_image"
                      image={formValues.image} 
                      setImage={(newImage) => setFormValues({ ...formValues, image: newImage })}
                      setFiles={setFiles} 
                    />
                  </div>
          
                  <div className="UserFormContainer" style={{ flex: 2, marginLeft: '20px' }}>
                    <h2 style={{ fontWeight: 'bold' }}>USUARIO</h2>
                    
                    <div style={{ display: 'flex', flexWrap: 'wrap', gap: '20px' }}>
                      <div className='input_row'>
                        <TextField 
                          name="name"
                          label="Nombres"
                          fullWidth
                          required
                          value={formValues.name}
                          onChange={handleInputChange}
                        />
          
                        <TextField
                          name="last_name"
                          label="Apellidos"
                          fullWidth
                          required
                          value={formValues.last_name}
                          onChange={handleInputChange}
                        />
                      </div>
          
                      <div className='input_row'>
                        <TextField
                          name="email"
                          label="Email"
                          fullWidth
                          required
                          value={formValues.email}
                          onChange={handleInputChange}
                        />
          
                        <TextField
                          name="password"
                          label="Password"
                          type="password"
                          fullWidth
                          value={formValues.password}
                          onChange={handleInputChange}
                        />
                      </div>
          
                      <div className='input_row'>
                        <TextField
                          name="phone"
                          label="Teléfono"
                          fullWidth
                          required
                          value={formValues.phone}
                          onChange={handleInputChange}
                        />

          

                      </div>
          
                   
                     
                    </div>

                    <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '20px' }}></div>
                      <Button onClick={handleSubmit} variant="contained" color="secondary">
                        GUARDAR
                      </Button>
                  </div>
        </Box>
      </div>
    </div>
  </div>

</>
    
  );
};



