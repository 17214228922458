import { useEffect, useState } from 'react';
import Box from 'Elements/Box';
import { Button, IconButton, Pagination, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Menu, MenuItem, Chip } from '@mui/material';
import { useSnackbar } from 'notistack';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import AddIcon from '@mui/icons-material/Add';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import TableEditor from './TableEditor';
import EmptyElement from 'Elements/EmptyElement';
import { ApiRequest } from 'GlobalFunctions';
import AlertDialog from 'Elements/AlertDialog';
import FiltersByTable from 'Elements/Filters/FiltersByTable';
import FiltersApplied from 'Elements/Filters/FiltersApplied';

export default function TableList(props: any) {
  const { setLoading } = props;
  const { enqueueSnackbar } = useSnackbar();

  const [tableList, setTableList] = useState<any[]>([]);
  const [pagination, setPagination] = useState({
    total_pages: 1,
    total_items: 0,
  });
  const [page, setPage] = useState(1);
  const [openEditor, setOpenEditor] = useState(false);
  const [activeItem, setActiveItem] = useState<any>(null);
  const [openAlert, setOpenAlert] = useState(false);
  const [menuAnchor, setMenuAnchor] = useState<null | HTMLElement>(null);
  const [filtersList, setFiltersList] = useState<any[]>([]);
  const [filtersValues, setFiltersValues] = useState<any>([]);

  useEffect(() => {
    loadList();
  }, [page, filtersValues]);

  const loadList = async () => {
    setLoading(true);

    await ApiRequest({
      url: '/tables/list',
      method: 'get',
      query: { page, filters: filtersValues },
      setResponse: (response: any) => {
        setTableList(response.list);
        setPagination(response.pagination)
        setFiltersList(response.filtersList);
        setLoading(false);
      },
    });
  };

  const handleDelete = (table_id: number) => {
    setActiveItem(table_id);
    setOpenAlert(true);
  };

  const deleteItem = async () => {
    await ApiRequest({
      url: '/tables/deleted',
      method: 'post',
      body: { table_id: activeItem },
      setResponse: (response: any) => {
        if (response.status === 'success') {
          enqueueSnackbar('Table deleted successfully', { variant: 'success' });
          loadList();
        } else {
          enqueueSnackbar(response.message, { variant: 'error' });
        }
        setOpenAlert(false);
      },
    });
  };

  const handleMenuOpen = (event: React.MouseEvent<HTMLElement>, table_id: number) => {
    setMenuAnchor(event.currentTarget);
    setActiveItem(table_id);
  };

  const handleMenuClose = () => {
    setMenuAnchor(null);
  };

  return (
    <>
      <div className="moduleHeader">
        <h1 className="component_title">Tables</h1>
        <div className="boxContainerActions">
          <Button
            variant="text"
            color="primary"
            onClick={() => {
              setActiveItem(null);
              setOpenEditor(true);
            }}
            startIcon={<AddIcon />}
          >
            Add Table
          </Button>

          <FiltersByTable 
            filters={filtersList} 
            setFilters={setFiltersValues} 
            searchBy={['tables.table_id', 'description']}
          />
        </div>
      </div>

      <div id="TableList" className="ModuleContainer">
        <div className="ModuleWrapper">
          <div id="TableListIn">
            <Box id="AdminList" className="box100">
              <FiltersApplied filters={filtersValues} setFilters={setFiltersValues} />

              {tableList.length !== 0 ? (
                <TableContainer>
                  <Table aria-label="sticky table">
                    <TableHead>
                      <TableRow>
                        <TableCell>ID</TableCell>
                        <TableCell>DESCRIPTION</TableCell>
                        <TableCell>MAX SEATS</TableCell>
                        <TableCell>STATUS</TableCell>
                        <TableCell>ACTIONS</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {tableList.map((table, i) => (
                        <TableRow key={i} hover role="checkbox">
                          <TableCell>{table.table_id}</TableCell>
                          <TableCell>
                            <div className="flex_td">
                              <div className="flex_title">{table.description}</div>
                              <div>{table.restaurant_name}</div> 
                            </div>
                            </TableCell>
                          <TableCell>{table.max_seats}</TableCell>
                          <TableCell>
                            <Chip label={table.status ? 'Active' : 'Inactive'} color={table.status ? "primary" : "secondary"} />
                          </TableCell>
                          <TableCell align="right">
                            <IconButton aria-label="edit" color="primary" onClick={(event) => handleMenuOpen(event, table.table_id)}>
                              <MoreHorizIcon color='secondary' />
                            </IconButton>
                            <Menu
                              anchorEl={menuAnchor}
                              open={Boolean(menuAnchor && activeItem === table.table_id)}
                              onClose={handleMenuClose}
                            >
                              <MenuItem onClick={() => {
                                setOpenEditor(true);
                                handleMenuClose();
                              }}>
                                <ModeEditIcon fontSize="small" />
                                Edit
                              </MenuItem>
                              <MenuItem onClick={() => handleDelete(table.table_id)}>
                                <DeleteOutlineOutlinedIcon fontSize="small" />
                                Remove
                              </MenuItem>
                            </Menu>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              ) : (
                <div className="emptyList">
                  <EmptyElement title="No tables registered" subtitle="Start by adding a new table" />
                </div>
              )}
              {pagination.total_pages > 1 && (
                <div className="pagination">
                  <Pagination
                    count={pagination.total_pages}
                    page={page}
                    onChange={(event, value) => {
                      setPage(value);
                    }}
                    color="secondary"
                    style={{ marginTop: '20px', alignSelf: 'center' }}
                  />
                </div>
              )}
            </Box>
          </div>
        </div>

        <TableEditor
          setLoading={setLoading}
          openEditor={openEditor}
          setOpenEditor={setOpenEditor}
          table_id={activeItem}
          loadList={loadList}
        />

        <AlertDialog
          open={openAlert}
          setOpen={setOpenAlert}
          subtitle="Are you sure you want to delete this table?"
          send={deleteItem}
        />
      </div>
    </>
  );
}