import * as React from 'react';
import { Avatar } from '@mui/material';

interface Props {
    name: any;
    image?: any;
    size?: any;
    imgSize?: any;
    className?: any;
}

let name = 'Profile Home';

export default function FooterMenu(props: any) {
 
  const size = props.size ? props.size : 56;

  if (props.name){
    name = props.name;
  }

  const firstLetter = name.charAt(0).toUpperCase();;
  const secondLetter = name.split(' ').length > 1 ? name.split(' ')[1].charAt(0).toUpperCase(): '';

  return (
    <>
      <div className='footer_menu_container'>
            <div className='footer_avatar_content'>
                <div>
                    <Avatar
                        sx={{width: size, height: size}}
                        imgProps = {props.imgSize? {style: {objectFit: 'contain'}} : {}}
                        children = {firstLetter+secondLetter}
                        src={props.image}
                        className={props.className}
                    />
                </div>
                <div>
                    <h3>{name}</h3>
                </div>    
            </div>
      </div>
    </>
  );
}