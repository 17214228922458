import React, { useEffect } from "react";
import Button from '@mui/material/Button';

declare global {
  interface Window {
    PaidYET: any;
  }
}

const PaymentForm: React.FC<{ amount: number }> = ({ amount }) => {
  useEffect(() => {
    if (!document.querySelector(`script[src="https://cdn.paidyet.com/paynowv5.js"]`)) {
      const script = document.createElement("script");
      script.src = "https://cdn.paidyet.com/paynowv5.js";
      script.async = true;
      script.onload = () => initializePaidYET();
      document.body.appendChild(script);
    } else {
      initializePaidYET();
    }
  }, [amount]);

  const initializePaidYET = () => {
    if (window.PaidYET) {
      window.PaidYET.init("burger");
      window.PaidYET.setSandbox(true);
      const settings = {
        amount: amount,
        element: "card-element-cof",
        hideamount: true,
        hidenotes: true,
        hidezip: true,
      };
      window.PaidYET.renderForm(settings);
    } else {
      console.error("PaidYET script not loaded.");
    }
  };

  const handlePayment = () => {
    if (window.PaidYET) {
      window.PaidYET.processPayment(callback);
    } else {
      console.error("PaidYET is not available.");
    }
  };

  const callback = (e: any) => {
    if (e.success) {
      alert("Successful Request");
    }
  };

  return (
    <div className="container">
      <div id="card-element-cof"></div>
      <div id="card-errors"></div>
      <Button 
        variant="outlined" 
        color="primary" 
        fullWidth 
        onClick={handlePayment}
        sx={{ marginTop: '12px', padding: '12px', width: '106%', transform: 'translateX(-15px)' }}
      >
        Process
      </Button>
    </div>
  );
};

export default PaymentForm;
