import React, { useState, useEffect } from 'react';
import { Button, Dialog, TextField, FormControlLabel, Switch } from '@mui/material';
import { useSnackbar } from 'notistack';
import { ApiRequest } from 'GlobalFunctions';
import AutocompleteField from 'Elements/AutocompleteField';

export default function ShiftsEditor(props: any) {
  const {
    setLoading,
    openEditor,
    setOpenEditor,
    shift_id,
    loadList,
  } = props;

  const { enqueueSnackbar } = useSnackbar();

  const defaultValues: any = {
    shift_id: shift_id ? shift_id : null,
    user_id: '',
    shift_date: '',
    shift_start_time: '',
    shift_end_time: '',
    enable_end_time: false,
  };

  const [formValues, setFormValues] = useState(defaultValues);

  /*=======================================
  LOAD SHIFT DETAILS IF EDITING
  =======================================*/
  useEffect(() => {
    if (shift_id && openEditor) {
      loadShiftDetails();
    } else {
      setFormValues(defaultValues);
    }
  }, [shift_id, openEditor]);

  const loadShiftDetails = async () => {
    setLoading(true);

    await ApiRequest({
      url: '/shifts/details',
      method: 'get',
      query: { shift_id },
      setResponse: (response: any) => {
        if (response.status === 'success') {
          setFormValues({
            ...response.data,
            enable_end_time: response.data.shift_end_time !== '00:00:00',
          });
        } else {
          enqueueSnackbar('Error loading shift details', { variant: 'error' });
        }
        setLoading(false);
      },
    });
  };

  /*=======================================
  HANDLE INPUT CHANGE
  =======================================*/
  const handleInputChange = (e: { target: { name: any; value: any; }; }) => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };

  /*=======================================
  SUBMIT FUNCTION
  =======================================*/
  const handleSubmit = async () => {
    setLoading(true);

    const submitValues = {
      ...formValues,
      shift_end_time: formValues.enable_end_time ? formValues.shift_end_time : null,
    };

    await ApiRequest({
      url: '/shifts/addedit',
      method: 'post',
      body: submitValues,
      setResponse: (response: any) => {
        if (response.status === 'success') {
          enqueueSnackbar('Shift saved successfully', { variant: 'success' });
          setOpenEditor(false);
          loadList();
        } else {
          enqueueSnackbar(response.message, { variant: 'error' });
        }
        setLoading(false);
      },
    });
  };

  return (
    <Dialog
      open={openEditor}
      onClose={() => setOpenEditor(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      maxWidth="md"
      fullWidth
    >
      <div className="DialogContainer" style={{ display: 'flex', flexDirection: 'column', padding: '20px' }}>
        <h2 style={{ fontWeight: 'bold' }}>SHIFT</h2>
        <div style={{ display: 'flex', flexWrap: 'wrap', gap: '20px' }}>
          <AutocompleteField
            label="Employee"
            endpoint="/users/list"
            multiple={false}
            value={formValues.user_id}
            onChange={(data: any) => {
              setFormValues({ ...formValues, user_id: data.value });
            }}
          />

          <TextField
            id="shift_date"
            name="shift_date"
            label="Shift Date"
            type="date"
            fullWidth
            required
            value={formValues.shift_date}
            onChange={handleInputChange}
            InputLabelProps={{
              shrink: true,
            }}
          />

          <TextField
            id="shift_start_time"
            name="shift_start_time"
            label="Start Time"
            type="time"
            fullWidth
            required
            value={formValues.shift_start_time}
            onChange={handleInputChange}
            InputLabelProps={{
              shrink: true,
            }}
          />

          <FormControlLabel
            control={
              <Switch
                checked={formValues.enable_end_time}
                onChange={(e) => setFormValues({ ...formValues, enable_end_time: e.target.checked })}
              />
            }
            label="Enable End Time"
          />

          {formValues.enable_end_time && (
            <TextField
              id="shift_end_time"
              name="shift_end_time"
              label="End Time"
              type="time"
              fullWidth
              value={formValues.shift_end_time}
              onChange={handleInputChange}
              InputLabelProps={{
                shrink: true,
              }}
            />
          )}
        </div>

        <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '20px' }}>
          <Button
            onClick={() => setOpenEditor(false)}
            variant="outlined"
            style={{ marginRight: '10px' }}
          >
            CANCEL
          </Button>
          <Button onClick={handleSubmit} variant="contained" color="secondary">
            SAVE
          </Button>
        </div>
      </div>
    </Dialog>
  );
}