import { Button, Divider, IconButton, Menu, MenuItem, Chip, Dialog, DialogTitle, DialogContent, DialogActions, Select, MenuItem as SelectMenuItem } from '@mui/material';
import Box from 'Elements/Box';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { useState, Dispatch, SetStateAction } from 'react';
import ProductEditor from './ProductEditor';
import ConfirmOrderDialog from './ConfirmOrderDialog';
import { useNavigate } from 'react-router-dom'; 
import { useSnackbar } from 'notistack'; 
import { TbCreditCardPay } from "react-icons/tb";
import PaymentDialog from './PaymentDialog'; 
import { ApiRequest } from 'GlobalFunctions'; 
import AlertDialog  from 'Elements/AlertDialog'; 
import { LoadingButton } from '@mui/lab'; // Añade esta importación al inicio
import logo from 'Assets/img/logo3.png';

interface SummaryProps {
  currentOrder: {
    order_id?: number;
    table_id: number;
    user: {
      name: string;
      user_id: number;
      last_name: string;
    }
    products: Array<{
      product: {
        image: string;
        product_name: string;
        product_id: number;
      };
      quantity: number;
      total: number;
      observations?: string;
    }>;
    total: number;
    order_status?: string; // Add order_status to the type definition
    food_order_status?: string; // Add food_order_status to the type definition
  };
  setCurrentOrder: Dispatch<SetStateAction<any>>;
  onSaveOrder: (order_id: number) => void; // Añadimos onSaveOrder a las props
  table_id: number; // Add table_id to the props
}

export default function Summary({ currentOrder, setCurrentOrder, onSaveOrder, table_id }: SummaryProps) {
  const [menuAnchor, setMenuAnchor] = useState<null | HTMLElement>(null);
  const [activeItem, setActiveItem] = useState<any>(null);
  const [openEditor, setOpenEditor] = useState(false);
  const [selectedItem, setSelectedItem] = useState<any>(null);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false); // Add state for dialog control
  const [openPaymentDialog, setOpenPaymentDialog] = useState(false); // Add state for payment dialog control
  const [isEdited, setIsEdited] = useState(false); // Add state to track if the order is edited
  const [openStatusDialog, setOpenStatusDialog] = useState(false); // Add state for status dialog control
  const [newStatus, setNewStatus] = useState(''); // Add state for new status
  const [openReleaseDialog, setOpenReleaseDialog] = useState(false); // Add state for release dialog control
  const [isPrinting, setIsPrinting] = useState(false); // Añade este estado
  const [isPrintingInvoice, setIsPrintingInvoice] = useState(false);
  const navigate = useNavigate(); // Initialize useNavigate
  const { enqueueSnackbar } = useSnackbar(); // Initialize useSnackbar

  const handleMenuOpen = (event: React.MouseEvent<HTMLElement>, product: any) => {
    setMenuAnchor(event.currentTarget);
    setActiveItem(product);
  };

  const handleMenuClose = () => {
    setMenuAnchor(null);
    setActiveItem(null);
  };

  const handleEdit = (item: any) => {
    setSelectedItem(item);
    setOpenEditor(true);
    handleMenuClose();
  };

  const handleImageError = (event: any) => {
    event.target.src = logo;
  };

  const handleUpdateItem = (updatedItem: any) => {
    const updatedProducts = currentOrder.products.map((item) =>
      item.product.product_id === updatedItem.product.product_id ? updatedItem : item
    );
    const newTotal = updatedProducts.reduce((sum, item) => sum + item.total, 0);
    setCurrentOrder({ ...currentOrder, products: updatedProducts, total: newTotal });
    setOpenEditor(false);
    setIsEdited(true); // Set isEdited to true when an item is updated
  };

  const handleDelete = (item: any) => {
    const updatedProducts = currentOrder.products.filter(p => p !== item);
    const newTotal = updatedProducts.reduce((sum, item) => sum + item.total, 0);
    
    setCurrentOrder({
      ...currentOrder,
      table_id: currentOrder.table_id,
      products: updatedProducts,
      total: newTotal
    });
    handleMenuClose();
    setIsEdited(true); // Set isEdited to true when an item is deleted
  };

  const handleConfirmOrder = () => {
    if (currentOrder.products.length === 0) {
      enqueueSnackbar('No products added to the order.', { variant: 'error' });
      return;
    }
    setOpenConfirmDialog(true);
  };

  const handleReleaseTable = async () => {
    try {
      await ApiRequest({
        url: '/tables/changeOccupiedStatus',
        method: 'post',
        body: { table_id },
        setResponse: (response: any) => {
          enqueueSnackbar('Table released successfully.', { variant: 'success' });
          navigate(-1); // Navigate back to the previous page
        },
      });
    } catch (error) {
      enqueueSnackbar('Failed to release the table.', { variant: 'error' });
    }
  };

  const confirmReleaseTable = () => {
    setOpenReleaseDialog(true); // Open the release confirmation dialog
  };

  const handleSaveOrder = (order_id: number) => {
    onSaveOrder(order_id);
    setIsEdited(false); // Reset isEdited to false when the order is saved
  };

  const handleStatusChange = () => {
    setNewStatus(currentOrder.food_order_status || ''); // Ensure food_order_status is defined
    setOpenStatusDialog(true);
  };

  const changeStatus = async () => {
    await ApiRequest({
      url: '/orders/change-food-status',
      method: 'post',
      body: { order_id: currentOrder.order_id, food_order_status: newStatus },
      setResponse: (response: any) => {
        if (response.status === 'success') {
          enqueueSnackbar('Food order status successfully changed', { variant: 'success' });
          setCurrentOrder({ ...currentOrder, food_order_status: newStatus });
        } else {
          enqueueSnackbar(response.message, { variant: 'error' });
        }
        setOpenStatusDialog(false);
      },
    });
  };

  const getFoodOrderStatusColor = (status: string) => {
    switch (status) {
      case 'requested':
        return '#FFA500'; // Orange
      case 'cooking':
        return '#FF4500'; // OrangeRed
      case 'served':
        return '#32CD32'; // LimeGreen
      case 'completed':
        return '#4682B4'; // SteelBlue
      default:
        return '#808080'; // Gray
    }
  };
  
  const handlePrintOrder = () => {
    setIsPrinting(true);
    ApiRequest({
      url: '/orders/print-order',
      method: 'get',
      query: { order_id: currentOrder.order_id },
      setResponse: (response: any) => {
        if (response.status === 'success' && response.fileUrl) {
          window.open(response.fileUrl, '_blank');
        } else {
          enqueueSnackbar('Error generating receipt', { variant: 'error' });
        }
        setIsPrinting(false);
      }
    }).catch(() => {
      enqueueSnackbar('Error generating receipt', { variant: 'error' });
      setIsPrinting(false);
    });
  };

  const handlePrintInvoice = () => {
    setIsPrintingInvoice(true);
    ApiRequest({
      url: '/orders/print-invoice',
      method: 'get',
      query: { order_id: currentOrder.order_id },
      setResponse: (response: any) => {
        if (response.status === 'success' && response.fileUrl) {
          window.open(response.fileUrl, '_blank');
        } else {
          enqueueSnackbar('Error generating invoice', { variant: 'error' });
        }
        setIsPrintingInvoice(false);
      }
    }).catch(() => {
      enqueueSnackbar('Error generating invoice', { variant: 'error' });
      setIsPrintingInvoice(false);
    });
  };

  return (
    <div className='summary'>
      <Box className='summary_card'>

        <div className='summary_header'>
          {currentOrder.user ? (
            <>ATTENDED BY: {currentOrder.user.name + " " + currentOrder.user.last_name}</>
          ) : (
            <>ATTENDED BY: N/A</>
          )}
        </div>

        <Divider />
        
        <div className='summary_total'>
            <span className='summary_total_label'>Total: </span>
            <span className='summary_total_value'>$ {currentOrder.total.toLocaleString()}</span>
        </div>

        <span className='summary_products_label'>Added Products</span>
        <div className="ordered-products">
          {currentOrder.products.map((item, index) => (
            <div key={index} className="ordered-product-item">
              <div className="product-row-info">
                <img 
                  src={item.product.image || logo} 
                  alt={item.product.product_name} 
                  className="product-thumbnail"
                  onError={handleImageError} 
                />
                <div className="product-details"> 
                  <h4>{item.product.product_name} x {item.quantity}</h4>
                  <span className='summary-product-price'>$ {item.total.toLocaleString()}</span>
                </div>
              </div>
                <div>
                    <IconButton 
                        aria-label="more"
                        onClick={(event) => handleMenuOpen(event, item)}
                        disabled={currentOrder.order_status === 'success'}
                    >
                        <MoreHorizIcon color='secondary' />
                    </IconButton>
                </div>
            </div>
          ))}

          <Menu
            anchorEl={menuAnchor}
            open={Boolean(menuAnchor)}
            onClose={handleMenuClose}
          >
            <MenuItem onClick={() => handleEdit(activeItem)}>
              <ModeEditIcon fontSize="small" style={{ marginRight: '8px' }} />
              Edit
            </MenuItem>
            <MenuItem onClick={() => handleDelete(activeItem)}>
              <DeleteOutlineOutlinedIcon fontSize="small" style={{ marginRight: '8px' }} />
              Remove
            </MenuItem>
          </Menu>
        </div>
        {currentOrder.order_id && (
          <div className="food-order-status">
            <Chip 
              label={currentOrder.food_order_status} 
              style={{ backgroundColor: getFoodOrderStatusColor(currentOrder.food_order_status || ''), color: '#fff', textTransform: 'capitalize', cursor: 'pointer' }}
              onClick={handleStatusChange}
            />
          </div>
        )}
      </Box>

      {currentOrder.order_status !== 'success' ? (
        <>
          <Button 
            variant='contained' 
            color='secondary' 
            size='large' 
            fullWidth
            onClick={handleConfirmOrder} // Use handleConfirmOrder on click
          >
            {currentOrder.order_id ? 'Update Order' : 'Create Order'}
          </Button>



          {currentOrder.order_id && !isEdited && (
            <>
              <Button 
                variant='contained' 
                color='primary' 
                size='large' 
                fullWidth
                onClick={() => setOpenPaymentDialog(true)} // Open payment dialog on click
                startIcon={<TbCreditCardPay />} // Add the icon here
              >
                FINALIZE AND PAY ORDER
              </Button>

              <LoadingButton 
                loading={isPrinting}
                variant='contained' 
                color='secondary' 
                size='large' 
                fullWidth
                onClick={handlePrintOrder}
              >
                Print Order
              </LoadingButton>
              
              
            </>
          )}
        </>
      ) : (
        <>
          <Button 
            variant='contained' 
            color='primary' 
            size='large' 
            fullWidth
            onClick={confirmReleaseTable} // Use confirmReleaseTable on click
          >
            Release Table
          </Button>

          <LoadingButton
            loading={isPrintingInvoice}
            variant='contained'
            color='secondary'
            size='large'
            fullWidth
            onClick={handlePrintInvoice}
          >
            Print Invoice
          </LoadingButton>
        </>
      )}

      <Button 
        variant='outlined' 
        color='primary' 
        size='large' 
        fullWidth
        onClick={() => navigate(-1)} // Use navigate to go back one step
      >
        Return to tables
      </Button>
          
      <ProductEditor
        open={openEditor}
        onClose={() => setOpenEditor(false)}
        selectedProduct={selectedItem?.product}
        initialValues={{
          quantity: selectedItem?.quantity || 1,
          observations: selectedItem?.observations || ''
        }}
        onAddToOrder={handleUpdateItem}
      />

      <ConfirmOrderDialog
        open={openConfirmDialog}
        onClose={() => setOpenConfirmDialog(false)}
        currentOrder={currentOrder}
        onSave={handleSaveOrder}
        table_id={table_id}
      />

      <PaymentDialog
        open={openPaymentDialog}
        currentOrder={currentOrder}
        order_id={currentOrder.order_id} 
        onClose={() => setOpenPaymentDialog(false)}
      />

      <Dialog
        open={openStatusDialog}
        onClose={() => setOpenStatusDialog(false)}
        aria-labelledby="change-status-dialog-title"
        aria-describedby="change-status-dialog-description"
      >
        <DialogTitle id="change-status-dialog-title">Change Food Order Status</DialogTitle>
        <DialogContent>
          <Select
            value={newStatus}
            onChange={(e) => setNewStatus(e.target.value)}
            fullWidth
          >
            <SelectMenuItem value="requested">Requested</SelectMenuItem>
            <SelectMenuItem value="cooking">Cooking</SelectMenuItem>
            <SelectMenuItem value="served">Served</SelectMenuItem>
            <SelectMenuItem value="completed">Completed</SelectMenuItem>
          </Select>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenStatusDialog(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={changeStatus} color="primary">
            Confirm
          </Button>
        </DialogActions>
      </Dialog>




      <AlertDialog
        open={openReleaseDialog}
        setOpen={setOpenReleaseDialog}
        subtitle="Are you sure you want to release the table?."
        send={handleReleaseTable}
      />
    </div>
  );
}
