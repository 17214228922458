import React, { useEffect, useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, RadioGroup, FormControlLabel, Radio } from '@mui/material';
import AlertDialog from 'Elements/AlertDialog'; // Import AlertDialog
import StripeForm from './StripeForm';
import { ApiRequest } from 'GlobalFunctions';
import { useSnackbar } from "notistack";
import { useNavigate } from 'react-router-dom'; // Import useNavigate

interface PaymentDialogProps {
  open: boolean;
  order_id: any;
  onClose: () => void;
}

export default function PaymentDialog({ open, onClose, order_id }: PaymentDialogProps) {
  const [paymentMethod, setPaymentMethod] = useState('cash');
  const [openAlert, setOpenAlert] = useState(false); // Add state for alert dialog control
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate(); // Initialize useNavigate

  const handlePaymentMethodChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPaymentMethod((event.target as HTMLInputElement).value);
  };

  const handleConfirmPayment = () => {
    setOpenAlert(true); // Open alert dialog
  };

  const handleAlertConfirm = () => {
    // Handle offline payment confirmation
    navigate(`/customer/order-success/${order_id}`); // Navigate to OrderSuccess page
    onClose(); // Close the payment dialog
  };

  const setPaymentStatus = (status: any, message: string) => {
    if (status === 'success') {
      navigate(`/customer/order-success/${order_id}`); // Corregido para usar la ruta completa
      onClose();
    } else {
      enqueueSnackbar(message, { variant: 'error' });
    }
  };
  
  const ShowLoader = (show: boolean) => {
  }

  const [paymentInfo, setPaymentInfo] = useState<any>({});

  const getPaymentForm = async () => {
      ShowLoader(true);
      await ApiRequest({
          url: "/payments/getPaymentForm",
          method: "get",
          query: {
              order_id,
          },
          setResponse: async (response: any) => {
              if(response.status==='error') {
                  enqueueSnackbar(response.message, { variant: 'error' });
                  return
              }
              
              setPaymentInfo(response);
          },
      });
  };

  useEffect(() => {
    order_id && getPaymentForm();
  }, [order_id]);

  return (
    <>
      <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
        <DialogTitle>Select Payment Method</DialogTitle>
        <DialogContent>


          {paymentInfo.payment_settings &&
          <StripeForm 
              payment_settings={paymentInfo.payment_settings} 
              setPaymentStatus={setPaymentStatus}
              order_id={order_id}
          />
          }

          <Button 
            variant="outlined" 
            color="primary" 
            fullWidth 
            onClick={onClose}
            sx={{ marginTop: '12px', padding: '12px' }}
          >
            Cancel
          </Button>

        </DialogContent>

      </Dialog>

      <AlertDialog
        open={openAlert}
        setOpen={setOpenAlert}
        subtitle="Are you sure you want to proceed? The table will be released upon completion."
        send={handleAlertConfirm}
      />
    </>
  );
}
