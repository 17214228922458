import { Button, Divider, IconButton, Menu, MenuItem } from '@mui/material';
import Box from 'Elements/Box';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { useState, Dispatch, SetStateAction } from 'react';
import ProductEditor from './ProductEditor';
import ConfirmOrderDialog from './ConfirmOrderDialog'; // Import the new component
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import { useSnackbar } from 'notistack'; // Import useSnackbar
import { TbCreditCardPay } from "react-icons/tb";
import PaymentDialog from './PaymentDialog'; // Import the new component
import { ApiRequest } from 'GlobalFunctions'; // Import ApiRequest


interface SummaryProps {
  currentOrder: {
    order_id?: number; // Add order_id to the type definition
    table_id: number;
    products: Array<{
      product: {
        image: string;
        product_name: string;
        product_id: number;
      };
      quantity: number;
      total: number;
      observations?: string;
    }>;
    total: number;
    order_status?: string; // Add order_status to the type definition
  };
  setCurrentOrder: Dispatch<SetStateAction<any>>;
  onSaveOrder: (order_id: number) => void; // Añadimos onSaveOrder a las props
  table_id: number; // Add table_id to the props
}

export default function Summary({ currentOrder, setCurrentOrder, onSaveOrder, table_id }: SummaryProps) {
  const [menuAnchor, setMenuAnchor] = useState<null | HTMLElement>(null);
  const [activeItem, setActiveItem] = useState<any>(null);
  const [openEditor, setOpenEditor] = useState(false);
  const [selectedItem, setSelectedItem] = useState<any>(null);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false); // Add state for dialog control
  const [openPaymentDialog, setOpenPaymentDialog] = useState(false); // Add state for payment dialog control
  const navigate = useNavigate(); // Initialize useNavigate
  const { enqueueSnackbar } = useSnackbar(); // Initialize useSnackbar

  const handleMenuOpen = (event: React.MouseEvent<HTMLElement>, product: any) => {
    setMenuAnchor(event.currentTarget);
    setActiveItem(product);
  };

  const handleMenuClose = () => {
    setMenuAnchor(null);
    setActiveItem(null);
  };

  const handleEdit = (item: any) => {
    setSelectedItem(item);
    setOpenEditor(true);
    handleMenuClose();
  };

  const handleUpdateItem = (updatedItem: any) => {
    const updatedProducts = currentOrder.products.map((item) =>
      item.product.product_id === updatedItem.product.product_id ? updatedItem : item
    );
    const newTotal = updatedProducts.reduce((sum, item) => sum + item.total, 0);
    setCurrentOrder({ ...currentOrder, products: updatedProducts, total: newTotal });
    setOpenEditor(false);
  };

  const handleDelete = (item: any) => {
    const updatedProducts = currentOrder.products.filter(p => p !== item);
    const newTotal = updatedProducts.reduce((sum, item) => sum + item.total, 0);
    
    setCurrentOrder({
      table_id: currentOrder.table_id,
      products: updatedProducts,
      total: newTotal
    });
    handleMenuClose();
  };

  const handleConfirmOrder = () => {
    if (currentOrder.products.length === 0) {
      enqueueSnackbar('No products added to the order.', { variant: 'error' });
      return;
    }
    setOpenConfirmDialog(true);
  };

  const handleReleaseTable = async () => {
    try {
      await ApiRequest({
        url: '/tables/changeOccupiedStatus',
        method: 'post',
        body: { table_id },
        setResponse: (response: any) => {
          enqueueSnackbar('Table released successfully.', { variant: 'success' });
          navigate(-1); // Navigate back to the previous page
        },
      });
    } catch (error) {
      enqueueSnackbar('Failed to release the table.', { variant: 'error' });
    }
  };

  const handleSaveOrder = (order_id: number) => {
    onSaveOrder(order_id);
    setCurrentOrder({ ...currentOrder, order_id }); // Update currentOrder with the new order_id
    setOpenPaymentDialog(true); // Open payment dialog after saving order
  };

  return (
    <div className='summary'>
      <Box className='summary_card'>
        
        <div className='summary_total'>
            <span className='summary_total_label'>Total: </span>
            <span className='summary_total_value'>$ {currentOrder.total.toLocaleString()}</span>
        </div>

        <span className='summary_products_label'>Added Products</span>
        <div className="ordered-products">
          {currentOrder.products.map((item, index) => (
            <div key={index} className="ordered-product-item">
              <div className="product-row-info">
                <img src={item.product.image} alt={item.product.product_name} className="product-thumbnail" />
                <div className="product-details"> 
                  <h4>{item.product.product_name} x {item.quantity}</h4>
                  <span className='summary-product-price'>$ {item.total.toLocaleString()}</span>
                </div>
              </div>
                <div>
                    <IconButton 
                        aria-label="more"
                        onClick={(event) => handleMenuOpen(event, item)}
                    >
                        <MoreHorizIcon color='secondary' />
                    </IconButton>
                </div>
            </div>
          ))}

          <Menu
            anchorEl={menuAnchor}
            open={Boolean(menuAnchor)}
            onClose={handleMenuClose}
          >
            <MenuItem onClick={() => handleEdit(activeItem)}>
              <ModeEditIcon fontSize="small" style={{ marginRight: '8px' }} />
              Edit
            </MenuItem>
            <MenuItem onClick={() => handleDelete(activeItem)}>
              <DeleteOutlineOutlinedIcon fontSize="small" style={{ marginRight: '8px' }} />
              Remove
            </MenuItem>
          </Menu>
        </div>
      </Box>

      {currentOrder.order_status !== 'success' ? (
        <>
          <Button 
            variant='contained' 
            color='secondary' 
            size='large' 
            fullWidth
            onClick={handleConfirmOrder} // Use handleConfirmOrder on click
          >
            {currentOrder.order_id ? 'Update Order' : 'Create Order'}
          </Button>

          {currentOrder.order_id && (
            <Button 
              variant='contained' 
              color='primary' 
              size='large' 
              fullWidth
              onClick={() => setOpenPaymentDialog(true)} // Open payment dialog on click
              startIcon={<TbCreditCardPay />} // Add the icon here
            >
              FINALIZE AND PAY ORDER
            </Button>
          )}
        </>
      ) : (
        <Button 
          variant='contained' 
          color='primary' 
          size='large' 
          fullWidth
          onClick={handleReleaseTable} // Use handleReleaseTable on click
        >
          Release Table
        </Button>
      )}

          
      <ProductEditor
        open={openEditor}
        onClose={() => setOpenEditor(false)}
        selectedProduct={selectedItem?.product}
        initialValues={{
          quantity: selectedItem?.quantity || 1,
          observations: selectedItem?.observations || ''
        }}
        onAddToOrder={handleUpdateItem}
      />

      <ConfirmOrderDialog
        open={openConfirmDialog}
        onClose={() => setOpenConfirmDialog(false)}
        currentOrder={currentOrder}
        onSave={handleSaveOrder} // Use handleSaveOrder to open payment dialog
        table_id={table_id} // Ensure table_id is passed
      />

      <PaymentDialog
        open={openPaymentDialog}
        order_id={currentOrder.order_id} 
        onClose={() => setOpenPaymentDialog(false)} // Close payment dialog
      />
    </div>
  );
}
