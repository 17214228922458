import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom/client';
import 'Assets/css/main.scss';
import 'Assets/css/main_responsive.scss';

import logo from 'Assets/img/logo.png';
import { ThemeProvider } from '@mui/material/styles';
import { SnackbarProvider } from 'notistack';
import AppRoutes from 'AppRoutes';
import { BrowserRouter as Router, useLocation } from 'react-router-dom';

import CircularProgress from '@mui/material/CircularProgress';
import { Badge, Button } from '@mui/material';
import NotificationsNoneOutlinedIcon from '@mui/icons-material/NotificationsNoneOutlined';
import MenuIcon from '@mui/icons-material/Menu';

import { ApiRequest } from 'GlobalFunctions';
import Login from 'Components/Companies/Login/Login';
import MainMenu from 'Components/Companies/Menus/Menu';
import AdminMenu from 'Components/Admin/Menus/Menu';
import AccountMenu from 'Components/Companies/Account/AccountMenu';
import CustomerRoutes from 'CustomerRoutes';
import AdminRoutes from 'AdminRoutes';
import theme from './Theme';
import { CssBaseline } from '@mui/material';
import FooterMenu from 'Components/Companies/Menus/FooterMenu';
import useMenuStore from 'store/useMenuStore';
import CookerMenu from 'Components/Companies/Menus/CookerMenu';
import ServerMenu from 'Components/Companies/Menus/ServerMenu';
import CashierMenu from 'Components/Companies/Menus/CashierMenu';

const fetchUserPermissions = async () => {
  const response: any = await ApiRequest({
    url: '/permissions/user_permissions',
    method: 'GET',
    setResponse: (data: any) => data.userPermissions,
  });
  console.log('Fetched permissions:', response);
  return response?.userPermissions || [];
};

interface RoleMenus {
  [key: number]: JSX.Element | null;
}

const roleMenus: RoleMenus = {
  1: <MainMenu />,
  2: <CookerMenu />,
  3: <ServerMenu />, // Placeholder for Mesero menu
  4: <CashierMenu />, // Placeholder for Cajero menu
  100: <AdminMenu />
};

const Site = () => {
  const userIsLogged = localStorage.getItem('ltkn') !== null;
  const location = useLocation();
  const isCustomerOrder = location.pathname.includes('customer/');
  
  const [loading, setLoading] = useState(false);
  const role_id = Number(localStorage.getItem('role_id'));
  const { isMenuOpen, toggleMenu } = useMenuStore();
  const isMobile = window.innerWidth <= 480;
  const [permissions, setPermissions] = useState<any[]>([]);

  useEffect(() => {
    if (userIsLogged) {
      const getPermissions = async () => {
        const userPermissions = await fetchUserPermissions();
        setPermissions(userPermissions);
      };
      getPermissions();
      console.log(role_id);
    }
  }, [userIsLogged]);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <SnackbarProvider maxSnack={3}>
        {isCustomerOrder ? (
          <div id="customer-order-container">
            <CustomerRoutes setLoading={setLoading} />
          </div>
        ) : (
          !userIsLogged ? (
            <Login setLoading={setLoading} />
          ) : (
            <div id='structure'>
              {isMenuOpen && (
                <aside id="menu">
                  {roleMenus[role_id] || null}
                  <FooterMenu 
                    name={localStorage.getItem('user_name')} 
                    image={localStorage.getItem('user_image')}
                    size={45}
                  />
                </aside>
              )}
              <div id='main-container' className={isMenuOpen ? '' : 'full-width'}>
                <div id='top_bar'>
                  <div id='top_bar_left'>
                    {isMobile && (
                      <button onClick={toggleMenu} className="menu-toggle-button">
                        <MenuIcon />
                      </button>
                    )}
                    <div id='logo'>
                      <a href='/'>
                        <img src={logo}></img>
                      </a>
                    </div>
                  </div>
                  <div id='top_bar_right'>
                    <AccountMenu />
                  </div>
                </div>
                <div id="main-content">
                  <div id='loader' className={!loading ? 'loaderHide' : ''}>
                    <CircularProgress />
                  </div>
                  {role_id !== 100 ? (
                    <AppRoutes setLoading={setLoading} permissions={permissions} />
                  ) : (
                    <AdminRoutes setLoading={setLoading} permissions={permissions} />
                  )}
                </div>
              </div>
            </div>
          )
        )}
      </SnackbarProvider>
    </ThemeProvider>
  );
}

// ========================================

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <Router>
    <Site />
  </Router>
);