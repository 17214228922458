import Box from "Elements/Box";
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, Filler } from 'chart.js';
import { Line } from 'react-chartjs-2';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, Filler);

export default function OrdersWeekly(props: any) {

    const { data } = props;

    const uData = data?.values || [4000, 3000, 2000, 2780, 1890, 2390, 3490];
    const xLabels = data?.labels || [
        'Sunday',
        'Monday',
        'Tuesday',
        'Wednesday',
        'Thursday',
        'Friday',
        'Saturday'
    
    ];

    const chartData = {
        labels: xLabels,
        datasets: [
            {
                label: 'Orders Weekly',
                data: uData,
                fill: true,
                backgroundColor: 'rgba(255, 49, 49, 0.2)',
                borderColor: '#ff3131',
                tension: 0.4,
            },
        ],
    };

    const options = {
        responsive: true,
        plugins: {
            legend: {
                display: false,
            },
        },
    };

    return (
        <Box className='' title="Billing Weekly" subtitle="Sales per day">
            <Line data={chartData} options={options} />
        </Box>
    )
}