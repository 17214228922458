import { useEffect, useState } from 'react';
import Box from 'Elements/Box';
import { Avatar, Button, IconButton, Pagination, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Menu, MenuItem, Chip } from '@mui/material';
import { useSnackbar } from 'notistack';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import AddIcon from '@mui/icons-material/Add';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import ImageIcon from '@mui/icons-material/Image';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { ApiRequest } from 'GlobalFunctions';
import EmptyElement from 'Elements/EmptyElement';
import AlertDialog from 'Elements/AlertDialog';
import UsersEditor from './UsersEditor';

export default function UsersList(props: any) {
  const { company_id = null, is_popup = false, setLoading } = props;
  const { enqueueSnackbar } = useSnackbar();

  const [user_list, setUserList] = useState<any[]>([]);
  const [total_pages, setTotalPages] = useState(0);
  const [page, setPage] = useState(parseInt(localStorage.getItem('page') || "1"));
  const [openEditor, setOpenEditor] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);
  const [activeItem, setActiveItem] = useState<any>(null);
  const [menuAnchor, setMenuAnchor] = useState<null | HTMLElement>(null);

  useEffect(() => {
    loadList();
  }, [page]);

  const loadList = async () => {
    setLoading(true);
    await ApiRequest({
      url: "/users/list",
      method: "get",
      headers: {
        'ltkn': localStorage.getItem('ltkn')
      },
      query: {
        page: page,
        role_id: 2,
        company_id: company_id
      },
      setResponse: (response: any) => {
        setUserList(response.list);
        setTotalPages(response.total_pages);
        setLoading(false);
      }
    });
    localStorage.setItem('page', page.toString());
  };

  const handleDelete = (user_id: number) => {
    setActiveItem(user_id);
    setOpenAlert(true);
  };

  const deleteItem = async () => {
    await ApiRequest({
      url: "/users/delete",
      method: "post",
      body: { user_id: activeItem },
      setResponse: (response: any) => {
        if (response.status === "success") {
          enqueueSnackbar('User successfully deleted', { variant: 'success' });
          loadList();
        }
        setOpenAlert(false);
      }
    });
  };

  const handleMenuOpen = (event: React.MouseEvent<HTMLElement>, user_id: number) => {
    setMenuAnchor(event.currentTarget);
    setActiveItem(user_id);
  };

  const handleMenuClose = () => {
    setMenuAnchor(null);
  };

  return (
    <>
    <div className="moduleHeader">
        <h1 className="component_title">Users</h1>
        <div className="boxContainerActions">
          <Button
            variant="text"
            color="primary"
            onClick={() => {
              setActiveItem(null);
              setOpenEditor(true);
            }}
            startIcon={<AddIcon />}
          >
            Crear Usuario
          </Button>
        </div>
      </div>
    <div id='UsersList' className='ModuleContainer'>
      

      <div className="ModuleWrapper">
        <div id='UsersListIn'>
          <Box id='AdminList' className={!is_popup ? 'box100' : 'boxModal'}>
            {user_list.length !== 0 ? (
              <TableContainer>
                <Table aria-label="sticky table">
                  <TableHead>
                    <TableRow>
                      <TableCell>ID</TableCell>
                      <TableCell>IMAGE</TableCell>
                      <TableCell>NAME / EMAIL</TableCell>
                      <TableCell>PHONE</TableCell>
                      <TableCell>STATUS</TableCell>
                      <TableCell>ROLE</TableCell>
                      <TableCell>COSTS</TableCell>
                      <TableCell></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {user_list.map((user, i) => (
                      <TableRow key={i} hover role="checkbox">
                        <TableCell>{user.user_id}</TableCell>
                        <TableCell>
                          <Avatar src={user.image} sx={{ marginRight: 2 }}>
                            {!user.image && <ImageIcon />}
                          </Avatar>
                        </TableCell>
                        <TableCell className='flex_td'>
                          <span className='flex_title'>{user.name} {user.last_name}</span>
                          <span className='flex_subtitle'>{user.email}</span>
                        </TableCell>
                        <TableCell>{user.phone}</TableCell>
                        <TableCell>
                          <Chip label={user.is_active ? "Active" : "Inactive"} color={user.is_active ? "primary" : "error"} variant="outlined" size='small' />
                        </TableCell>
                        <TableCell>{user.role}</TableCell>
                        <TableCell>
                          Regular: ${Number(user.regular_cost).toLocaleString()}<br/>
                          Extra: ${Number(user.extra_cost).toLocaleString()}
                        </TableCell>
                        <TableCell align="right">
                          <IconButton aria-label="edit" color="primary" onClick={(event) => handleMenuOpen(event, user.user_id)}>
                            <MoreHorizIcon color='secondary' />
                          </IconButton>
                          <Menu
                            anchorEl={menuAnchor}
                            open={Boolean(menuAnchor && activeItem === user.user_id)}
                            onClose={handleMenuClose}
                          >
                            <MenuItem onClick={() => {
                              setOpenEditor(true);
                              handleMenuClose();
                            }}>
                              <ModeEditIcon fontSize="small" />
                              Edit
                            </MenuItem>
                            <MenuItem onClick={() => handleDelete(user.user_id)}>
                              <DeleteOutlineOutlinedIcon fontSize="small" />
                              Delete
                            </MenuItem>
                          </Menu>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            ) : (
              <div className="emptyList">
                <EmptyElement title="No registered users" subtitle="Start by creating a new user" />
              </div>
            )}
            {total_pages > 1 && (
              <div className="pagination">
                <Pagination
                  count={total_pages}
                  page={page}
                  onChange={(event, value) => setPage(value)}
                  color="primary"
                  style={{ marginTop: '20px', alignSelf: 'center' }}
                />
              </div>
            )}
          </Box>
        </div>
      </div>

      <AlertDialog
        open={openAlert}
        setOpen={setOpenAlert}
        subtitle="Are you sure you want to delete this user?"
        send={deleteItem}
      />

      <UsersEditor
        setLoading={setLoading}
        openEditor={openEditor}
        setOpenEditor={setOpenEditor}
        user_id={activeItem}
        loadList={loadList}
        company_id={company_id}
      />
    </div>
    </>
  );
}