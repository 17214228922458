import Box from "Elements/Box";
import { Gauge } from '@mui/x-charts/Gauge';

export default function Incomes(props: any) {

    const { data } = props;
    
    return (
      <div className='dashboard_circle_indicators'>
        <Box className='dashboard_circle_indicator color1'>
          <div className="indicator_info">
            <span className='indicator_value'>{data.last_7_days}</span>
            <span className='indicator_label'>LAST 7 DAYS</span>
          </div>
          
        </Box>
        <Box className='dashboard_circle_indicator color2'>
          <div className='circle_graph'>
            
          </div>
          <div className="indicator_info">
            <span className='indicator_value'>{data.month}</span>
            <span className='indicator_label'>CURRENT MONTH</span>
          </div>
          
        </Box>
        <Box className='dashboard_circle_indicator color3'>

          <div className="indicator_info">
            <span className='indicator_value'>{data.last_6_months}</span>
            <span className='indicator_label'>LAST 6 MONTHS</span>
          </div>
          
        </Box>
        <Box className='dashboard_circle_indicator color4'>

          <div className="indicator_info">
            <span className='indicator_value'>{data.year}</span>
            <span className='indicator_label'>CURRENT YEAR</span>
          </div>
          
        </Box>
      </div>
    )
}