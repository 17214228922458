import React, { useState, useEffect } from 'react';
import { Button, TextField, MenuItem, Select, FormControl, InputLabel, Divider } from '@mui/material';
import { useSnackbar } from 'notistack';
import { ApiRequest } from 'GlobalFunctions';
import ImageUploader from 'Elements/ImageUploader';
import PopoverPicker from 'Elements/ColorPicker';
import Box from 'Elements/Box';
import stripe_logo from 'Assets/img/stripe_logo.png';
import Payment from './Payment/Payment';

const defaultValues: any = {
  company_name: '',
  primary_color: '#000000',
  secondary_color: '#ffffff',
  image: '',
  payment_settings: {
    stripe: {
      public_key: '',
      secret_key: '',
      webhook_secret: ''
    }
  },
  payment_day: 0,
  goals: {
    daily: 0,
    weekly: 0,
    monthly: 0,
    yearly: 0
  }
};

export default function Settings(props: any) {
  const { setLoading, openEditor, setOpenEditor, loadList } = props;
  const { enqueueSnackbar } = useSnackbar();
  const [formValues, setFormValues] = useState(defaultValues);
  const [files, setFiles] = useState<any>([]);

  useEffect(() => {
    loadSettings();
  }, []);

  const loadSettings = async () => {
    setLoading(true);
    await ApiRequest({
      url: '/settings/details',
      method: 'get',
      setResponse: (response: any) => {
        if (response.status === 'success') {
          const data = response.data;
          // Ensure payment_settings and stripe are initialized properly
          data.payment_settings = data.payment_settings || {};
          data.payment_settings.stripe = data.payment_settings.stripe || {
            public_key: '',
            secret_key: '',
            webhook_secret: ''
          };
          // Ensure goals is initialized properly
          data.goals = data.goals || {
            daily: 0,
            weekly: 0,
            monthly: 0,
            yearly: 0
          };
          setFormValues(data);
        } else {
          enqueueSnackbar('Error loading settings', { variant: 'error' });
        }
        setLoading(false);
      }
    });
  };

  const handleInputChange = (e: { target: { name: any; value: any; }; }) => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value
    });
  };

  const handleColorChange = (color: any, field: string) => {
    setFormValues({
      ...formValues,
      [field]: color.hex
    });
  };

  const handlePaymentFieldChange = (e: { target: { name: any; value: any; }; }, field: string) => {
    setFormValues({
      ...formValues,
      payment_settings: {
        ...formValues.payment_settings,
        stripe: {
          ...formValues.payment_settings.stripe,
          [field]: e.target.value
        }
      }
    });
  };

  const handleGoalChange = (e: { target: { name: any; value: any; }; }, field: string) => {
    setFormValues({
      ...formValues,
      goals: {
        ...formValues.goals,
        [field]: e.target.value
      }
    });
  };

  const handleSubmit = async () => {
    const body = new FormData();
    body.append('formValues', JSON.stringify(formValues));

    /*example of formValues
    {
    "company_name": "aaaaa",
    "primary_color": "#cf1313",
    "secondary_color": "#b42d2d",
    "image": "",
    "payment_settings": {
        "stripe": {
            "public_key": "asd",
            "secret_key": "123",
            "webhook_secret": "gert"
        }
    }
}
    */

     
    if (files) {
      body.append("image", files);
    }

    if (formValues.menu_pdf) {
      body.append("menu_pdf", formValues.menu_pdf);
    }

    setLoading(true);

    await ApiRequest({
      url: '/settings/update',
      method: 'post',
      formData: true,
      body: body,
      setResponse: (response: any) => {
        if (response.status === 'success') {
          enqueueSnackbar('Settings saved successfully', { variant: 'success' });
        } else {
          enqueueSnackbar(response.message, { variant: 'error' });
        }
        setLoading(false);
      }
    });
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      setFormValues({ ...formValues, menu_pdf: e.target.files[0] });
    }
  };

  return (
    <div className="ModuleContainer">
    <Box>
      <h2 className='editor_title'>Settings</h2>
      <div className="ProductImageContainer">
          <ImageUploader 
            className="company_image"
            image={formValues.image} 
            setImage={(newImage) => setFormValues({ ...formValues, image: newImage })}
            setFiles={setFiles} 
          />
        </div>
        

        <div className="form_container">
          <section>
            <div className='input_group'>

              <h2 className='h2_title'>Company Details</h2>
              <TextField
                id="company_name"
                name="company_name"
                label="Store Name"
                fullWidth
                required
                value={formValues.company_name}
                onChange={handleInputChange}
              />

              <div className="input_row">
                <div className="color_picker">
                  <PopoverPicker
                      color={formValues.primary_color}
                      onChange={(newColor) => setFormValues({ ...formValues, primary_color: newColor })}
                  />
                  <span>Primary Color</span>
                </div>
                <div className="color_picker">
                  <PopoverPicker
                      color={formValues.secondary_color}
                      onChange={(newColor) => setFormValues({ ...formValues, secondary_color: newColor })}
                  />
                  <span>Secondary Color</span>
                </div>
              </div>
            </div>
          </section>


            <Divider />

            <section>
              <div className='input_group'>
                <h2 className='h2_title'>Payout Settings</h2>
                <FormControl fullWidth >
                  <InputLabel id="payment-day-label">Payment Day</InputLabel>
                  <Select
                    labelId="payment-day-label"
                    id="payment_day"
                    name="payment_day"
                    value={formValues.payment_day}
                    onChange={handleInputChange}
                    label="Payment Day"
                  >
                    <MenuItem value={0}>Sunday</MenuItem>
                    <MenuItem value={1}>Monday</MenuItem>
                    <MenuItem value={2}>Tuesday</MenuItem>
                    <MenuItem value={3}>Wednesday</MenuItem>
                    <MenuItem value={4}>Thursday</MenuItem>
                    <MenuItem value={5}>Friday</MenuItem>
                    <MenuItem value={6}>Saturday</MenuItem>
                  </Select>
                </FormControl>
              </div>
            </section>

            <Divider />

            <section>
              <div className='input_group'>
              <h2 className='h2_title'>Restaurant Menu</h2>
              <div className="input_row">
                <Button
                variant="contained"
                component="label"
                >
                Upload PDF
                <input
                  type="file"
                  accept="application/pdf"
                  hidden
                  onChange={handleFileChange}
                />
                </Button>
                {formValues.menu_pdf && (
                <span style={{ marginLeft: '10px' }}>{formValues.menu_pdf.name}</span>
                )}
              </div>
              </div>
            </section>

            <Divider />

            <section>
              <div className='input_group'>
                <h2 className='h2_title'>Payment Gateways</h2>
                <img src={stripe_logo} alt="stripe_logo" style={{ width: '100px', marginBottom: '20px' }} />
                <div className="input_group">
                  <div className="input_row">
                    <TextField
                      label="Public Key"
                      value={formValues.payment_settings.stripe.public_key}
                      onChange={(e) => handlePaymentFieldChange(e, 'public_key')}
                      fullWidth
                    />
                    <TextField
                      label="Secret Key"
                      value={formValues.payment_settings.stripe.secret_key}
                      onChange={(e) => handlePaymentFieldChange(e, 'secret_key')}
                      fullWidth
                    />
                    <TextField
                      label="Webhook Secret"
                      value={formValues.payment_settings.stripe.webhook_secret}
                      onChange={(e) => handlePaymentFieldChange(e, 'webhook_secret')}
                      fullWidth
                    />
                  </div>
                </div>
              </div>
            </section>

            <Divider />

            <section>
              <div className='input_group'>
                <h2 className='h2_title'>Income Goals</h2>

                <div className="input_row">
                  <TextField
                    label="Daily Goal"
                    value={formValues.goals.daily}
                    onChange={(e) => handleGoalChange(e, 'daily')}
                    fullWidth
                  />
                  <TextField
                    label="Weekly Goal"
                    value={formValues.goals.weekly}
                    onChange={(e) => handleGoalChange(e, 'weekly')}
                    fullWidth
                  />
                  <TextField
                    label="Monthly Goal"
                    value={formValues.goals.monthly}
                    onChange={(e) => handleGoalChange(e, 'monthly')}
                    fullWidth
                  />
                  <TextField
                    label="Yearly Goal"
                    value={formValues.goals.yearly}
                    onChange={(e) => handleGoalChange(e, 'yearly')}
                    fullWidth
                  />
                </div>
              </div>
            </section>

            <Divider />

            {/* <section>
              <Payment setLoading={setLoading} />
            </section>  */}

          </div>
      {/* <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '20px' }}>
        <Button onClick={handleSubmit} variant="contained" color="secondary">
          CONFIRM
        </Button>
      </div> */}
    </Box>
    </div>
  );
}
