import Box from "Elements/Box";
import { Gauge } from '@mui/x-charts/Gauge';

export default function Incomes(props: any) {

    const { data } = props;
    
    return (
      <div className="dashboard_circle_indicators">
        <Box className='dashboard_circle_indicator dashboard_indicator_today'>
          <div className='circle_graph'>
            <Gauge 
              width={100} 
              height={100} 
              value={Math.round(data.today.percentage)} 
              text={
                ({ value, valueMax }) => `${Math.round(Number(value))}%`
              }
              
            />
          </div>
          <div className="indicator_info">
            <span className='indicator_value'>${data.today.total}</span>
            <span className='indicator_label'>TODAY</span>
          </div>
          
        </Box>
        <Box className='dashboard_circle_indicator'>
          <div className='circle_graph'>
            <Gauge 
              width={100} 
              height={100} 
              value={Math.round(data.last_7_days.percentage)} 
              text={
                ({ value, valueMax }) => `${Math.round(Number(value))}%`
              }
              sx={{ color: 'green' }}
            />
          </div>
          <div className="indicator_info">
            <span className='indicator_value'>${data.last_7_days.total}</span>
            <span className='indicator_label'>WEEK</span>
          </div>
          
        </Box>
        <Box className='dashboard_circle_indicator'>
          <div className='circle_graph'>
            <Gauge 
              width={100} 
              height={100} 
              value={Math.round(data.month.percentage)} 
              text={
                ({ value, valueMax }) => `${Math.round(Number(value))}%`
              }
            />
          </div>
          <div className="indicator_info">
            <span className='indicator_value'>${data.month.total}</span>
            <span className='indicator_label'>MONTH</span>
          </div>
          
        </Box>
        <Box className='dashboard_circle_indicator'>
          <div className='circle_graph'>
            <Gauge 
              width={100} 
              height={100} 
              value={Math.round(data.year.percentage)} 
              text={
                ({ value, valueMax }) => `${Math.round(Number(value))}%`
              }
            />
          </div>
          <div className="indicator_info">
            <span className='indicator_value'>${data.year.total}</span>
            <span className='indicator_label'>YEAR</span>
          </div>
          
        </Box>
      </div>
    )
}