import { useEffect, useState } from 'react';
import Box from 'Elements/Box';
import { Button, IconButton, Pagination, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Menu, MenuItem, Chip, Dialog, DialogTitle, DialogContent, DialogActions, Select, MenuItem as SelectMenuItem } from '@mui/material';
import { useSnackbar } from 'notistack';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import AddIcon from '@mui/icons-material/Add';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import EmptyElement from 'Elements/EmptyElement';
import { ApiRequest } from 'GlobalFunctions';
import AlertDialog from 'Elements/AlertDialog';
import FiltersByTable from 'Elements/Filters/FiltersByTable';
import FiltersApplied from 'Elements/Filters/FiltersApplied';
import OrderViewerDialog from './components/OrderViewerDialog';

export default function OrdersList(props: any) {
  const { setLoading, widget } = props;
  const { enqueueSnackbar } = useSnackbar();

  const [orderList, setOrderList] = useState<any[]>([]);
  const [pagination, setPagination] = useState({
    total_pages: 1,
    total_items: 0,
  });
  const [page, setPage] = useState(1);
  const [openEditor, setOpenEditor] = useState(false);
  const [activeItem, setActiveItem] = useState<any>(null);
  const [openAlert, setOpenAlert] = useState(false);
  const [menuAnchor, setMenuAnchor] = useState<null | HTMLElement>(null);
  const [filtersList, setFiltersList] = useState<any[]>([]);
  const [filtersValues, setFiltersValues] = useState<any[]>([]);
  const [openStatusDialog, setOpenStatusDialog] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState<any>(null);
  const [newStatus, setNewStatus] = useState('');
  const [currentOrder, setCurrentOrder] = useState<any>(null);

  useEffect(() => {
    loadList();
  }, [page, filtersValues]);

  const loadList = async () => {
    setLoading(true);

    await ApiRequest({
      url: '/orders/list',
      method: 'get',
      query: { page, filters: filtersValues, widget },
      setResponse: (response: any) => {
        setOrderList(response.list);
        setPagination(response.pagination || { total_pages: 1, total_items: 0 });
        setFiltersList(response.filtersList || []);
        setLoading(false);
      },
    });
  };

  const handleDelete = (order_id: number) => {
    setActiveItem(order_id);
    setOpenAlert(true);
  };

  const deleteItem = async () => {
    await ApiRequest({
      url: '/orders/deleted',
      method: 'post',
      body: { order_id: activeItem },
      setResponse: (response: any) => {
        if (response.status === 'success') {
          enqueueSnackbar('Order deleted successfully', { variant: 'success' });
          loadList();
        } else {
          enqueueSnackbar(response.message, { variant: 'error' });
        }
        setOpenAlert(false);
      },
    });
  };

  const handleMenuOpen = (event: React.MouseEvent<HTMLElement>, order: any) => {
    setMenuAnchor(event.currentTarget);
    setActiveItem(order.order_id);
    setCurrentOrder(order);
  };

  const handleMenuClose = () => {
    setMenuAnchor(null);
  };

  const getFoodOrderStatusColor = (status: string) => {
    switch (status) {
      case 'requested':
        return '#FFA500'; // Orange
      case 'cooking':
        return '#FF4500'; // OrangeRed
      case 'served':
        return '#32CD32'; // LimeGreen
      case 'completed':
        return '#4682B4'; // SteelBlue
      default:
        return '#808080'; // Gray
    }
  };

  const handleStatusChange = (order: any) => {
    setSelectedOrder(order);
    setNewStatus(order.food_order_status || ''); // Ensure food_order_status is defined
    setOpenStatusDialog(true);
  };

  const changeStatus = async () => {
    await ApiRequest({
      url: '/orders/change-food-status',
      method: 'post',
      body: { order_id: selectedOrder.order_id, food_order_status: newStatus },
      setResponse: (response: any) => {
        if (response.status === 'success') {
          enqueueSnackbar('Food order status successfully changed', { variant: 'success' });
          loadList();
        } else {
          enqueueSnackbar(response.message, { variant: 'error' });
        }
        setOpenStatusDialog(false);
      },
    });
  };

  return (
    <>
      {!widget && (
        <div className="moduleHeader">
          <h1 className="component_title">Orders</h1>
          <div className="boxContainerActions">
            <Button
              variant="text"
              color="primary"
              onClick={() => {
                setActiveItem(null);
                setOpenEditor(true);
              }}
              startIcon={<AddIcon />}
            >
              Add Order
            </Button>

            <FiltersByTable 
              filters={filtersList} 
              setFilters={setFiltersValues} 
              searchBy={['orders.order_id', 'client_name']}
            />
          </div>
        </div>
      )}

      <div id="OrdersList" className="ModuleContainer">
        <div className="ModuleWrapper">
          <div id="OrdersListIn">
            <Box id="AdminList" className="box100" title={widget ? 'Orders' : ''} subtitle={widget ? 'List of all orders' : ''}>
              {!widget && <FiltersApplied filters={filtersValues} setFilters={setFiltersValues} />}

              {orderList.length !== 0 ? (
                <TableContainer>
                  <Table aria-label="sticky table">
                    <TableHead>
                      <TableRow>
                        <TableCell>Client</TableCell>
                        <TableCell>Date</TableCell>
                        <TableCell>Amount</TableCell>
                        <TableCell>Status</TableCell>
                        <TableCell>Food Order Status</TableCell>
                        <TableCell align='right'>Actions</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {orderList.map((order, i) => (
                        <TableRow key={i} hover role="checkbox">
                          <TableCell>{order.name}</TableCell>
                          <TableCell>
                            {new Date(order.created_at).toLocaleDateString()}
                            <br />
                            {new Date(order.created_at).toLocaleTimeString()}
                          </TableCell>
                          <TableCell>${Number(order.total).toLocaleString()}</TableCell>
                          <TableCell>
                            <Chip variant='outlined'
                              label={order.order_status === 'success' ? 'Paid' : order.order_status} 
                              color={order.order_status === 'success' ? 'secondary' : 'default'} 
                              sx={{ textTransform: 'capitalize' }}
                            />
                          </TableCell>
                          <TableCell>
                            <Chip 
                              label={order.food_order_status} 
                              style={{ backgroundColor: getFoodOrderStatusColor(order.food_order_status), color: '#fff', textTransform: 'capitalize', cursor: 'pointer' }}
                              onClick={() => handleStatusChange(order)}
                            />
                          </TableCell>
                          <TableCell align="right">
                            <IconButton aria-label="edit" color="primary" onClick={(event) => handleMenuOpen(event, order)}>
                              <MoreHorizIcon color='secondary' />
                            </IconButton>
                            <Menu
                              anchorEl={menuAnchor}
                              open={Boolean(menuAnchor && activeItem === order.order_id)}
                              onClose={handleMenuClose}
                            >
                              <MenuItem onClick={() => {
                                setOpenEditor(true);
                                handleMenuClose();
                              }}>
                                <ModeEditIcon fontSize="small" />
                                View
                              </MenuItem>
                              <MenuItem onClick={() => handleDelete(order.order_id)}>
                                <DeleteOutlineOutlinedIcon fontSize="small" />
                                Remove
                              </MenuItem>
                            </Menu>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              ) : (
                <div className="emptyList">
                  <EmptyElement title="No orders registered" subtitle="Start by adding a new order" />
                </div>
              )}
              {pagination.total_pages > 1 && (
                <div className="pagination">
                  <Pagination
                    count={pagination.total_pages}
                    page={page}
                    onChange={(event, value) => {
                      setPage(value);
                    }}
                    color="secondary"
                    style={{ marginTop: '20px', alignSelf: 'center' }}
                  />
                </div>
              )}
            </Box>
          </div>
        </div>

        <AlertDialog
          open={openAlert}
          setOpen={setOpenAlert}
          subtitle="Are you sure you want to delete this order?"
          send={deleteItem}
        />

        <Dialog
          open={openStatusDialog}
          onClose={() => setOpenStatusDialog(false)}
          aria-labelledby="change-status-dialog-title"
          aria-describedby="change-status-dialog-description"
        >
          <DialogTitle id="change-status-dialog-title">Change Food Order Status</DialogTitle>
          <DialogContent>
            <Select
              value={newStatus}
              onChange={(e) => setNewStatus(e.target.value)}
              fullWidth
            >
              <SelectMenuItem value="requested">Requested</SelectMenuItem>
              <SelectMenuItem value="cooking">Cooking</SelectMenuItem>
              <SelectMenuItem value="served">Served</SelectMenuItem>
              <SelectMenuItem value="completed">Completed</SelectMenuItem>
            </Select>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setOpenStatusDialog(false)} color="primary">
              Cancel
            </Button>
            <Button onClick={changeStatus} color="primary">
              Confirm
            </Button>
          </DialogActions>
        </Dialog>

        {currentOrder && (
          <OrderViewerDialog
            open={openEditor}
            onClose={() => setOpenEditor(false)}
            orderId={currentOrder.order_id}
          />
        )}
      </div>
    </>
  );
}
