import { Button } from '@mui/material';
import { Elements, PaymentElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { ApiRequest } from 'GlobalFunctions';
import { useSnackbar } from "notistack";
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { MdOutlinePaid } from "react-icons/md";

const CheckoutForm = (props: any) => {


    const { setPaymentStatus, order_id } = props;
    const stripe = useStripe();
    const elements = useElements();
    const { enqueueSnackbar } = useSnackbar();
    const [paymentInfo, setPaymentInfo] = useState<any>({});
    const navigate = useNavigate();

    const ShowLoader = (show: boolean) => {
    }

    const getPaymentForm = async () => {
        ShowLoader(true);
        await ApiRequest({
            url: "/payments/getPaymentForm",
            method: "get",
            query: {
                order_id
            },
            setResponse: async (response: any) => {
                if(response.status==='error') {
                    enqueueSnackbar(response.message, { variant: 'error' });
                    return
                }
                
                setPaymentInfo(response);

                
            },
        });
    };
    
    const handleClick = async () => {
        ShowLoader(true);

        if (!stripe || !elements) {
            console.error('Stripe or Elements not loaded.');
            ShowLoader(false);
            return;
        }

        const { error, paymentIntent } = await stripe.confirmPayment({
            elements,
            confirmParams: {
                return_url: window.location.href,
            },
            redirect: 'if_required'
        });

        if (error) {
            setPaymentStatus('error', "Payment failed");
            
        } else if (paymentIntent) {
            if (paymentIntent.status === 'succeeded') {
                setPaymentStatus('success', "Payment successful");
            } else {
                console.log('Payment not completed: ', paymentIntent.status);
            }
        }

        ShowLoader(false);
    };


    const [cssClassList, setCssClassList] = useState("w-input");
    const [buttonClassList, setButtonClassList] = useState("w-button");

    useEffect(() => {
        const inputElement = document.querySelector('[pc="fname"]');

        if (inputElement) {
            const classList = inputElement.classList.value;
            setCssClassList(classList);
        }

        const buttonElement = document.querySelector('[pc-step="accordion-next"]');
        if (buttonElement) {
            let classList = buttonElement.classList.value;
            classList = classList.replace("is-disabled", "").trim(); // Eliminar "is-disabled"
            setButtonClassList(classList);
        }

    } ,[]);


    return (
        <div>
            <PaymentElement />
            <Button 
                variant="contained"
                color='secondary'
                fullWidth
                disabled={!stripe}
                onClick={handleClick}
                startIcon={<MdOutlinePaid />}
                sx={{ marginTop: '12px', padding: '12px' }}
            >
                Place Order
            </Button>
        </div>
    );
};

const StripeForm = (props: any) => {

    const appearance = {
        theme: 'flat' as 'stripe' | 'night' | 'flat', 
        labels: 'floating' as 'floating' | 'above',  
        variables: {
            fontFamily: 'Arial, sans-serif',
            spacingUnit: '4px',
            borderRadius: '5px',
        },
        rules: {
            '.Label': {
            },
            '.Input': {
                borderColor: '#ced4da',
            },
            '.Error': {
                color: '#dc3545',
            },
        },
    };
    const { 
        payment_settings: {
            public_key,
            client_secret: clientSecret
        }, 
        setPaymentStatus,
        order_id
    } = props;

   

    const stripePromise = loadStripe(public_key);


    return (
        <Elements stripe={stripePromise} options={{ clientSecret, appearance }}>
            <CheckoutForm setPaymentStatus={setPaymentStatus} order_id={order_id} />
        </Elements>
    );
};

export default StripeForm;
