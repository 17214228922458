import Restaurants from "./components/Restaurants";


export default function Sell(props: any) {

    const { setLoading } = props;
    return (
        <div>
            <Restaurants setLoading={setLoading} />
        </div>
    );
}