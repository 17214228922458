
import { useEffect, useState } from 'react';
import Box from 'Elements/Box';
import { Button, IconButton, Pagination, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Menu, MenuItem } from '@mui/material';
import { useSnackbar } from 'notistack';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import AddIcon from '@mui/icons-material/Add';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import RestaurantEditor from './RestaurantEditor';
import EmptyElement from 'Elements/EmptyElement';
import { ApiRequest } from 'GlobalFunctions';
import AlertDialog from 'Elements/AlertDialog';
import FiltersByTable from 'Elements/Filters/FiltersByTable';
import FiltersApplied from 'Elements/Filters/FiltersApplied';

export default function RestaurantList(props: any) {
  const { setLoading } = props;
  const { enqueueSnackbar } = useSnackbar();

  const [restaurantList, setRestaurantList] = useState<any[]>([]);
  const [pagination, setPagination] = useState({
    total_pages: 1,
    total_items: 0,
  });
  const [page, setPage] = useState(1);
  const [openEditor, setOpenEditor] = useState(false);
  const [activeItem, setActiveItem] = useState<any>(null);
  const [openAlert, setOpenAlert] = useState(false);
  const [menuAnchor, setMenuAnchor] = useState<null | HTMLElement>(null);
  const [filtersList, setFiltersList] = useState<any[]>([]);
  const [filtersValues, setFiltersValues] = useState<any>([]);

  useEffect(() => {
    loadList();
  }, [page, filtersValues]);

  const loadList = async () => {
    setLoading(true);

    await ApiRequest({
      url: '/restaurants/list',
      method: 'get',
      query: { page, filters: filtersValues },
      setResponse: (response: any) => {
        setRestaurantList(response.list);
        setPagination(response.pagination)
        setFiltersList(response.filtersList);
        setLoading(false);
      },
    });
  };

  const handleDelete = (restaurant_id: number) => {
    setActiveItem(restaurant_id);
    setOpenAlert(true);
  };

  const deleteItem = async () => {
    await ApiRequest({
      url: '/restaurants/deleted',
      method: 'post',
      body: { restaurant_id: activeItem },
      setResponse: (response: any) => {
        if (response.status === 'success') {
          enqueueSnackbar('Restaurant deleted successfully', { variant: 'success' });
          loadList();
        } else {
          enqueueSnackbar(response.message, { variant: 'error' });
        }
        setOpenAlert(false);
      },
    });
  };

  const handleMenuOpen = (event: React.MouseEvent<HTMLElement>, restaurant_id: number) => {
    setMenuAnchor(event.currentTarget);
    setActiveItem(restaurant_id);
  };

  const handleMenuClose = () => {
    setMenuAnchor(null);
  };

  return (
    <>
      <div className="moduleHeader">
        <h1 className="component_title">Restaurants</h1>
        <div className="boxContainerActions">
          <Button
            variant="text"
            color="primary"
            onClick={() => {
              setActiveItem(null);
              setOpenEditor(true);
            }}
            startIcon={<AddIcon />}
          >
            Add Restaurant
          </Button>

          <FiltersByTable 
            filters={filtersList} 
            setFilters={setFiltersValues} 
            searchBy={['restaurants.restaurant_id', 'restaurant_name']}
          />
        </div>
      </div>

      <div id="RestaurantList" className="ModuleContainer">
        <div className="ModuleWrapper">
          <div id="RestaurantListIn">
            <Box id="AdminList" className="box100">
              <FiltersApplied filters={filtersValues} setFilters={setFiltersValues} />

              {restaurantList.length !== 0 ? (
                <TableContainer>
                  <Table aria-label="sticky table">
                    <TableHead>
                      <TableRow>
                        <TableCell>ID</TableCell>
                        <TableCell>NAME / ADDRESS</TableCell>
                        <TableCell>PHONE</TableCell>
                        <TableCell>MANAGER</TableCell>
                        <TableCell align='right'>ACTIONS</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {restaurantList.map((restaurant, i) => (
                        <TableRow key={i} hover role="checkbox">
                          <TableCell>{restaurant.restaurant_id}</TableCell>
                          <TableCell>
                            <div className='flex_td'>
                              <span className='flex_title'>{restaurant.restaurant_name}</span>
                              <span>{restaurant.address}</span>
                            </div>

                          </TableCell>
                          
                          <TableCell>{restaurant.phone}</TableCell>
                          <TableCell>{restaurant.manager}</TableCell>
                          <TableCell align="right">
                            <IconButton aria-label="edit" color="primary" onClick={(event) => handleMenuOpen(event, restaurant.restaurant_id)}>
                              <MoreHorizIcon color='secondary' />
                            </IconButton>
                            <Menu
                              anchorEl={menuAnchor}
                              open={Boolean(menuAnchor && activeItem === restaurant.restaurant_id)}
                              onClose={handleMenuClose}
                            >
                              <MenuItem onClick={() => {
                                setOpenEditor(true);
                                handleMenuClose();
                              }}>
                                <ModeEditIcon fontSize="small" />
                                Edit
                              </MenuItem>
                              <MenuItem onClick={() => handleDelete(restaurant.restaurant_id)}>
                                <DeleteOutlineOutlinedIcon fontSize="small" />
                                Remove
                              </MenuItem>
                            </Menu>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              ) : (
                <div className="emptyList">
                  <EmptyElement title="No restaurants registered" subtitle="Start by adding a new restaurant" />
                </div>
              )}
              {pagination.total_pages > 1 && (
                <div className="pagination">
                  <Pagination
                    count={pagination.total_pages}
                    page={page}
                    onChange={(event, value) => {
                      setPage(value);
                    }}
                    color="secondary"
                    style={{ marginTop: '20px', alignSelf: 'center' }}
                  />
                </div>
              )}
            </Box>
          </div>
        </div>

        <RestaurantEditor
          setLoading={setLoading}
          openEditor={openEditor}
          setOpenEditor={setOpenEditor}
          restaurant_id={activeItem}
          loadList={loadList}
        />

        <AlertDialog
          open={openAlert}
          setOpen={setOpenAlert}
          subtitle="Are you sure you want to delete this restaurant?"
          send={deleteItem}
        />
      </div>
    </>
  );
}