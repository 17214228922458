import * as React from 'react';
import { MenuItem } from '@mui/material';
import MenuButton from './MenuButton';
import { Link, useLocation } from 'react-router-dom';

import DataSaverOffIcon from '@mui/icons-material/DataSaverOff';
import PointOfSaleIcon from '@mui/icons-material/PointOfSale';
import DisplaySettingsOutlinedIcon from '@mui/icons-material/DisplaySettingsOutlined';
import StorefrontOutlinedIcon from '@mui/icons-material/StorefrontOutlined';
import TableRestaurantIcon from '@mui/icons-material/TableRestaurant';
import ReceiptIcon from '@mui/icons-material/Receipt';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import KitchenIcon from '@mui/icons-material/Kitchen';
import { GiCook } from "react-icons/gi";
import { PiHamburger } from "react-icons/pi";
import { FaPeopleGroup } from "react-icons/fa6";
import { LiaFileInvoiceDollarSolid } from "react-icons/lia";

export default function MainMenu(props: any) {
  const { setLoading } = props;
  const location = useLocation();
  const [selectedMenu, setSelectedMenu] = React.useState<string[]>(['restaurant', 'team']);

  const handleMenuToggle = (menuName: string) => {
    setSelectedMenu((prevSelectedMenu) =>
      prevSelectedMenu.includes(menuName)
        ? prevSelectedMenu.filter((name) => name !== menuName)
        : [...prevSelectedMenu, menuName]
    );
  };

  const isSubItemSelected = (menuName: string) => {
    const subItems: { [key: string]: string[] } = {
      restaurant: ['/restaurants', '/tables', '/inventory', '/categories', '/products', '/expenses', '/orders'],
      team: ['/employees', '/shifts', '/payroll']
    };
    return subItems[menuName]?.includes(location.pathname);
  };

  return (
    <>
      <div className='menu_button_container'>
        <MenuButton 
          label="Dashboard" 
          to="/dashboard" 
          startIcon={<DataSaverOffIcon />} 
          setLoading={setLoading} 
        />
        
        <MenuButton 
          label="Sell" 
          to="/sell" 
          startIcon={<PointOfSaleIcon />} 
          setLoading={setLoading} 
        />
        
        <MenuButton 
          label="Restaurant" 
          startIcon={<StorefrontOutlinedIcon />} 
          setLoading={setLoading} 
          onClick={() => handleMenuToggle('restaurant')}
          isActive={isSubItemSelected('restaurant')}
        />
        {selectedMenu.includes('restaurant') && (
          <div className="submenu">
            <MenuItem component={Link} to="/restaurants">
              <StorefrontOutlinedIcon style={{ marginRight: '8px' }} />
              Restaurants
            </MenuItem>
            <MenuItem component={Link} to="/tables">
              <TableRestaurantIcon style={{ marginRight: '8px' }} />
              Tables
            </MenuItem>
            <MenuItem component={Link} to="/inventory">
              <KitchenIcon style={{ marginRight: '8px' }} />
              Inventory
            </MenuItem>
            <MenuItem component={Link} to="/categories">
              <DisplaySettingsOutlinedIcon style={{ marginRight: '8px' }} />
              Categories
            </MenuItem>
            <MenuItem component={Link} to="/products">
              <PiHamburger style={{ marginRight: '8px' }} size={22} /> 
              Products
            </MenuItem>
            <MenuItem component={Link} to="/expenses">
              <ReceiptIcon style={{ marginRight: '8px' }} />
              Expenses
            </MenuItem>
            <MenuItem component={Link} to="/orders">
              <ReceiptIcon style={{ marginRight: '8px' }} />
              Orders
            </MenuItem>
          </div>
        )}
        
        <MenuButton 
          label="Team" 
          startIcon={<FaPeopleGroup />} 
          setLoading={setLoading} 
          onClick={() => handleMenuToggle('team')}
          isActive={isSubItemSelected('team')}
        />
        {selectedMenu.includes('team') && (
          <div className="submenu">
            <MenuItem component={Link} to="/employees">
              <GiCook style={{ marginRight: '8px' }} size={22} />
              Employees
            </MenuItem>
            <MenuItem component={Link} to="/shifts">
              <AccessTimeIcon style={{ marginRight: '8px' }} />
              Shifts
            </MenuItem>
            <MenuItem component={Link} to="/payroll">
              <LiaFileInvoiceDollarSolid style={{ marginRight: '8px' }} size={22} />
              Payroll
            </MenuItem>
          </div>
        )}
        
        <MenuButton 
          label="Settings" 
          to="/settings" 
          startIcon={<DisplaySettingsOutlinedIcon />} 
          setLoading={setLoading} 
        />
      </div>
    </>
  );
}