import React, { useState, useEffect } from 'react';
import { Button, Dialog, TextField, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';
import { FiPlus } from "react-icons/fi";
import { FiMinus } from "react-icons/fi";


interface ProductEditorProps {
  open: boolean;
  onClose: () => void;
  selectedProduct: any;
  onAddToOrder: (item: any) => void;
  initialValues?: {
    quantity: number;
    observations: string;
  };
}

export default function ProductEditor({ open, onClose, selectedProduct, onAddToOrder, initialValues }: ProductEditorProps) {
  const { enqueueSnackbar } = useSnackbar();

  const defaultValues = {
    quantity: 1,
    observations: '',
  };

  const [formValues, setFormValues] = useState(initialValues || defaultValues);
  const [total, setTotal] = useState(0);

  useEffect(() => {
    if (selectedProduct) {
      setTotal(Number(selectedProduct.sell_price) * Number(formValues.quantity)); // Ensure total is treated as a number
    }
  }, [formValues.quantity, selectedProduct]);

  useEffect(() => {
    if (open && initialValues) {
      setFormValues(initialValues);
    } else if (!open) {
      setFormValues(defaultValues);
    }
  }, [open, initialValues]);

  const handleInputChange = (e: { target: { name: string; value: any; }; }) => {
    const { name, value } = e.target;
    if (name === 'quantity') {
      const numValue = parseInt(value) || 1;
      setFormValues({
        ...formValues,
        [name]: numValue,
      });
    } else {
      setFormValues({
        ...formValues,
        [name]: value,
      });
    }
  };

  const handleSubmit = () => {
    const orderItem = {
      product: selectedProduct,
      quantity: Number(formValues.quantity),
      observations: formValues.observations,
      total: Number(total) // Ensure total is treated as a number
    };
    
    onAddToOrder(orderItem);
    onClose();
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="sm"
      fullWidth
    >
      <div className="DialogContainer" style={{ padding: '20px' }}>
        
        {selectedProduct && (
          <div className='dialog_product_info'>
            <img 
              src={selectedProduct.image} 
              alt={selectedProduct.product_name} 
              style={{ width: '200px', height: '200px', objectFit: 'cover', marginRight: '20px' }}
            />
          
            <div className='dialog_product_info_right'>

              <h2>{selectedProduct.product_name}</h2>
              

              <div className='quantity_row_input'>
                <Button
                  variant="contained"
                  color="secondary"
                  size='small'
                  sx={{
                    borderTopRightRadius: 0,
                    borderBottomRightRadius: 0,
                    minWidth: '40px'
                  }}
                  onClick={() => setFormValues(prev => ({
                    ...prev,
                    quantity: Math.max(1, prev.quantity - 1)
                  }))}
                >
                  <FiMinus />
                </Button>

                <TextField
                  type="number"
                  name="quantity"
                  size="small"
                  style={{ width: '80px' }}
                  value={formValues.quantity}
                  onChange={handleInputChange}
                  sx={{
                    '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
                      '-webkit-appearance': 'none',
                      margin: 0,
                    },
                    '& input[type=number]': {
                      '-moz-appearance': 'textfield',
                      textAlign: 'center'
                    },
                    '& .MuiOutlinedInput-root': {
                      borderRadius: 0,
                      '& fieldset': {
                        borderColor: '#32b86c',
                      },
                      '&:hover fieldset': {
                        borderColor: '#32b86c',
                      },
                      '&.Mui-focused fieldset': {
                        borderColor: '#32b86c',
                      },
                    }
                  }}
                  inputProps={{ 
                    min: 1
                  }}
                />

                <Button
                  variant="contained"
                  color="secondary"
                  size='small'
                  sx={{
                    borderTopLeftRadius: 0,
                    borderBottomLeftRadius: 0,
                    minWidth: '40px'
                  }}
                  onClick={() => setFormValues(prev => ({
                    ...prev,
                    quantity: Number(prev.quantity) + 1
                  }))}
                >
                  <FiPlus />
                </Button>
              </div>

              <span className='dialog_total_label'>Total:</span>
              <p className='dialog_total_value'>${total.toLocaleString()}</p>
            </div>
          </div>
        )}

        <TextField
          name="observations"
          label="Observations"
          fullWidth
          multiline
          rows={3}
          value={formValues.observations}
          onChange={handleInputChange}
        />

        <div className='dialogButtons'>
          <Button
            onClick={onClose}
            variant="outlined"
            style={{ marginRight: '10px' }}
          >
            CANCEL
          </Button>
          <Button 
            onClick={handleSubmit} 
            variant="contained" 
            color="secondary"
          >
            {initialValues ? 'UPDATE ITEM' : 'ADD TO ORDER'}
          </Button>
        </div>
      </div>
    </Dialog>
  );
}