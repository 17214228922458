import { Button, Dialog, TextField, Divider, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import axios from "axios";
import { useEffect, useState } from "react";
import { useSnackbar } from "notistack";
import { ProjectConfig } from 'Global';
import ISwitch from "Elements/ISwitch";

const defaultValues: any = {
  plan_id: "null",
  plan_name: "",
  status: false,
  price_monthly: 0,
  price_yearly: 0,
  description: "",
  short_description: "",
  features: [],
  plan_level: 0,
};

export default function PlanEditor(props: any) {
  const { setLoading, openEditor, setOpenEditor, plan_id, loadList } = props;
  const { enqueueSnackbar } = useSnackbar();
  const [formValues, setFormValues] = useState(defaultValues);

  useEffect(() => {
    if (plan_id && openEditor) {
      axios.get(ProjectConfig.api_url + "/plans/details", {
        params: { plan_id },
      })
      .then((response) => {
        setFormValues(response.data);
      })
      .catch((err) => {
        console.log(err.response.data);
      });
    } else {
      setFormValues(defaultValues);
    }
  }, [plan_id, openEditor]);

  const handleInputChange = (e: { target: { name: any; value: any; }; }) => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };

  const handleSubmit = () => {
    setLoading(true);
    axios.post(ProjectConfig.api_url + "/plans/addedit", formValues)
      .then((response) => {
        if (response.data.status === "success") {
          enqueueSnackbar('Success', { variant: 'success' });
          setFormValues(defaultValues);
          setOpenEditor(false);
          loadList();
        } else {
          enqueueSnackbar(response.data.message, { variant: 'error' });
        }
      })
      .catch((err) => {
        console.log(err.response.data);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Dialog open={openEditor} onClose={() => setOpenEditor(false)} maxWidth="md" fullWidth>
      <DialogTitle>{plan_id ? "Edit Package" : "Add Package"}</DialogTitle>
      <DialogContent>
        <div className="form_container">
          <section>
            <div className='input_group'>
              <TextField
                id="plan_name"
                name="plan_name"
                label="Package Name"
                fullWidth
                required
                value={formValues.plan_name}
                onChange={handleInputChange}
              />
              <TextField
                id="plan_level"
                name="plan_level"
                label="Level"
                type="number"
                fullWidth
                required
                value={formValues.plan_level}
                onChange={handleInputChange}
              />
              <h2>Prices</h2>
              <TextField
                id="price_monthly"
                name="price_monthly"
                label="Monthly Price"
                type="number"
                fullWidth
                required
                value={formValues.price_monthly}
                onChange={handleInputChange}
              />
              <TextField
                id="price_yearly"
                name="price_yearly"
                label="Annual Price"
                type="number"
                fullWidth
                required
                value={formValues.price_yearly}
                onChange={handleInputChange}
              />
              <TextField
                id="short_description"
                name="short_description"
                label="Short Description"
                fullWidth
                required
                value={formValues.short_description}
                onChange={handleInputChange}
              />
              <TextField
                id="description"
                name="description"
                label="Description"
                fullWidth
                required
                multiline
                rows={4}
                value={formValues.description}
                onChange={handleInputChange}
              />
            </div>
            <ISwitch
              label="Status"
              checked={formValues.status}
              name="status"
              switchColor="#7B1FA2"
              onChange={(e: any) => {
                setFormValues({
                  ...formValues,
                  status: e.target.checked
                });
              }}
            />
          </section>
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setOpenEditor(false)} color="primary">
          Cancel
        </Button>
        <Button onClick={handleSubmit} variant="contained" color="secondary">
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
}
