import React, { useState, useEffect } from 'react';
import { Dialog, Button, TextField, Divider } from '@mui/material';
import Box from 'Elements/Box';
import { ApiRequest } from 'GlobalFunctions';

interface ConfirmOrderDialogProps {
  open: boolean;
  onClose: () => void;
  currentOrder: any;
  onSave: (order_id: number) => void;
  table_id: number; // Add table_id to the props
}

export default function ConfirmOrderDialog({ open, onClose, currentOrder, onSave, table_id }: ConfirmOrderDialogProps) {
  const [clientInfo, setClientInfo] = useState({
    name: ''
    // email: '',
    // phone: ''
  });
  const [errors, setErrors] = useState({
    name: false,
    // email: false,
    // phone: false
  });

  useEffect(() => {
    if (currentOrder.client) {
      setClientInfo({
        name: currentOrder.client.name
        // email: currentOrder.client.email,
        // phone: currentOrder.client.phone
      });
    }
  }, [currentOrder]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setClientInfo({
      ...clientInfo,
      [e.target.name]: e.target.value
    });
    setErrors({
      ...errors,
      [e.target.name]: false
    });
  };

  const handleSaveOrder = async () => {
    const newErrors = {
      name: clientInfo.name === '',
      // email: clientInfo.email === '',
      // phone: clientInfo.phone === ''
    };
    setErrors(newErrors);

    if (Object.values(newErrors).some(error => error)) {
      return;
    }

    const body: any = {
      clientInfo,
      products: currentOrder.products,
      total: currentOrder.total,
      order_status: 'pending',
      order_id: currentOrder.order_id,
      table_id: table_id // Include table_id in the request body
    };

    await ApiRequest({
      url: '/orders/addEdit',
      method: 'post',
      body: body,
      setResponse: (response: any) => {
        const order_id = response.order_id; // Obtenemos el order_id de la respuesta
        onSave(order_id); // Llamamos a la función onSave pasando el order_id
        onClose(); // Cerramos el diálogo
      },
    });
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <div className="DialogContainer" style={{ padding: '20px' }}>
        <div className="input_group">
            <h2 className='h2_title'>Summary</h2>
            <div className="ordered-products">
            {currentOrder.products.map((item: any, index: number) => (
                <div key={index} className="ordered-product-item">
                <div className="product-row-info">
                    <img src={item.product.image} alt={item.product.product_name} className="product-thumbnail" />
                    <div className="product-details"> 
                    <h4>{item.product.product_name} x {item.quantity}</h4>
                    <span className="summary-product-price">$ {item.total.toLocaleString()}</span>
                    </div>
                </div>
                </div>
            ))}
            </div>
        </div>
        <Divider />
        <div className="summary_total">
          <span className="summary_total_label">Total:</span>
          <span className="summary_total_value">$ {currentOrder.total.toLocaleString()}</span>
        </div>

        <div className='input_group'>
            <h2 className='h2_title'>Client Info</h2>
            <div className='input_row'>
                <TextField
                name="name"
                label="Client Name"
                fullWidth
                value={clientInfo.name}
                onChange={handleInputChange}
                />
                {/* <TextField
                name="email"
                label="Client Email"
                fullWidth
                value={clientInfo.email}
                onChange={handleInputChange}
                error={errors.email}
                helperText={errors.email ? 'Client email is required' : ''}
                /> */}
            </div>
        
            {/* <TextField
            name="phone"
            label="Client Phone"
            fullWidth
            value={clientInfo.phone}
            onChange={handleInputChange}
            error={errors.phone}
            helperText={errors.phone ? 'Client phone is required' : ''}
            /> */}
        </div>

        <div className="dialogButtons">
          <Button
            onClick={onClose}
            variant="outlined"
            style={{ marginRight: '10px' }}
          >
            Cancel
          </Button>

          <Button 
            onClick={handleSaveOrder} 
            variant="contained" 
            color="secondary"
          >
            Save Order
          </Button>
        </div>
      </div>
    </Dialog>
  );
}