import React, { useState, useEffect } from 'react';
import { Button, TextField, Divider, Dialog, DialogActions, DialogContent, DialogTitle, MenuItem, Select, FormControl, InputLabel } from '@mui/material';
import { useSnackbar } from 'notistack';
import { ApiRequest } from 'GlobalFunctions';
import ImageUploader from 'Elements/ImageUploader';
import PopoverPicker from 'Elements/ColorPicker';
import Box from 'Elements/Box';
import stripe_logo from 'Assets/img/stripe_logo.png';

const defaultValues: any = {
  company_name: '',
  primary_color: '#000000',
  secondary_color: '#ffffff',
  image: '',
  payment_settings: {
    stripe: {
      public_key: '',
      secret_key: '',
      webhook_secret: ''
    }
  },
  payment_day: 0,
  goals: {
    daily: 0,
    weekly: 0,
    monthly: 0,
    yearly: 0
  }
};

export default function CompaniesEditor(props: any) {
  const { setLoading, openEditor, setOpenEditor, company_id, loadList } = props;
  const { enqueueSnackbar } = useSnackbar();
  const [formValues, setFormValues] = useState(defaultValues);
  const [files, setFiles] = useState<any>([]);

  useEffect(() => {
    if (company_id) {
      loadDetails();
    }
  }, [company_id]);

  const loadDetails = async () => {
    setLoading(true);
    await ApiRequest({
      url: '/companies/details',
      method: 'get',
      query: { company_id },
      setResponse: (response: any) => {
        if (response.status === 'success') {
          const data = response.data;
          data.payment_settings = data.payment_settings || {};
          data.payment_settings.stripe = data.payment_settings.stripe || {
            public_key: '',
            secret_key: '',
            webhook_secret: ''
          };
          data.goals = data.goals || {
            daily: 0,
            weekly: 0,
            monthly: 0,
            yearly: 0
          };
          setFormValues(data);
        } else {
          enqueueSnackbar('Error loading company details', { variant: 'error' });
        }
        setLoading(false);
      }
    });
  };

  const handleInputChange = (e: { target: { name: any; value: any; }; }) => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value
    });
  };

  const handleColorChange = (color: any, field: string) => {
    setFormValues({
      ...formValues,
      [field]: color.hex
    });
  };

  const handlePaymentFieldChange = (e: { target: { name: any; value: any; }; }, field: string) => {
    setFormValues({
      ...formValues,
      payment_settings: {
        ...formValues.payment_settings,
        stripe: {
          ...formValues.payment_settings.stripe,
          [field]: e.target.value
        }
      }
    });
  };

  const handleGoalChange = (e: { target: { name: any; value: any; }; }, field: string) => {
    setFormValues({
      ...formValues,
      goals: {
        ...formValues.goals,
        [field]: e.target.value
      }
    });
  };

  const handleSubmit = async () => {
    const body = new FormData();
    body.append('formValues', JSON.stringify(formValues));

    if (files) {
      body.append("image", files);
    }

    setLoading(true);

    await ApiRequest({
      url: '/companies/addedit',
      method: 'post',
      formData: true,
      body: body,
      setResponse: (response: any) => {
        if (response.status === 'success') {
          enqueueSnackbar('Company saved successfully', { variant: 'success' });
          loadList();
          setOpenEditor(false);
        } else {
          enqueueSnackbar(response.message, { variant: 'error' });
        }
        setLoading(false);
      }
    });
  };

  return (
    <Dialog open={openEditor} onClose={() => setOpenEditor(false)} maxWidth="md" fullWidth>
      <DialogTitle>Company Details</DialogTitle>
      <DialogContent>
        <div className="ProductImageContainer">
          <ImageUploader 
            className="company_image"
            image={formValues.image} 
            setImage={(newImage) => setFormValues({ ...formValues, image: newImage })}
            setFiles={setFiles} 
          />
        </div>

        <div className="form_container">
          <section>
            <div className='input_group'>
              <TextField
                id="company_name"
                name="company_name"
                label="Company Name"
                fullWidth
                required
                value={formValues.company_name}
                onChange={handleInputChange}
              />

              <div className="input_row">
                <div className="color_picker">
                  <PopoverPicker
                      color={formValues.primary_color}
                      onChange={(newColor) => handleColorChange(newColor, 'primary_color')}
                  />
                  <span>Primary Color</span>
                </div>
                <div className="color_picker">
                  <PopoverPicker
                      color={formValues.secondary_color}
                      onChange={(newColor) => handleColorChange(newColor, 'secondary_color')}
                  />
                  <span>Secondary Color</span>
                </div>
              </div>
            </div>
          </section>

          <Divider />

          <section>
            <div className='input_group'>
              <FormControl fullWidth>
                <InputLabel id="payment-day-label">Payment Day</InputLabel>
                <Select
                  labelId="payment-day-label"
                  id="payment_day"
                  name="payment_day"
                  value={formValues.payment_day}
                  onChange={handleInputChange}
                  label="Payment Day"
                >
                  <MenuItem value={0}>Sunday</MenuItem>
                  <MenuItem value={1}>Monday</MenuItem>
                  <MenuItem value={2}>Tuesday</MenuItem>
                  <MenuItem value={3}>Wednesday</MenuItem>
                  <MenuItem value={4}>Thursday</MenuItem>
                  <MenuItem value={5}>Friday</MenuItem>
                  <MenuItem value={6}>Saturday</MenuItem>
                </Select>
              </FormControl>
            </div>
          </section>

          <Divider />

          <section>
            <div className='input_group'>
              <h2 className='h2_title'>Payment Gateways</h2>
              <img src={stripe_logo} alt="stripe_logo" style={{ width: '100px', marginBottom: '20px' }} />
              <div className="input_group">
                <div className="input_row">
                  <TextField
                    label="Public Key"
                    value={formValues.payment_settings.stripe.public_key}
                    onChange={(e) => handlePaymentFieldChange(e, 'public_key')}
                    fullWidth
                  />
                  <TextField
                    label="Secret Key"
                    value={formValues.payment_settings.stripe.secret_key}
                    onChange={(e) => handlePaymentFieldChange(e, 'secret_key')}
                    fullWidth
                  />
                  <TextField
                    label="Webhook Secret"
                    value={formValues.payment_settings.stripe.webhook_secret}
                    onChange={(e) => handlePaymentFieldChange(e, 'webhook_secret')}
                    fullWidth
                  />
                </div>
              </div>
            </div>
          </section>

          <Divider />

          <section>
            <div className='input_group'>
              <h2 className='h2_title'>Income Goals</h2>
              <TextField
                label="Daily Goal"
                value={formValues.goals.daily}
                onChange={(e) => handleGoalChange(e, 'daily')}
                fullWidth
              />
              <TextField
                label="Weekly Goal"
                value={formValues.goals.weekly}
                onChange={(e) => handleGoalChange(e, 'weekly')}
                fullWidth
              />
              <TextField
                label="Monthly Goal"
                value={formValues.goals.monthly}
                onChange={(e) => handleGoalChange(e, 'monthly')}
                fullWidth
              />
              <TextField
                label="Yearly Goal"
                value={formValues.goals.yearly}
                onChange={(e) => handleGoalChange(e, 'yearly')}
                fullWidth
              />
            </div>
          </section>
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setOpenEditor(false)} color="primary">
          Cancel
        </Button>
        <Button onClick={handleSubmit} variant="contained" color="secondary">
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
}

