import React, { useState, useEffect } from 'react';
import { Button, Dialog, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Chip } from '@mui/material';
import { useSnackbar } from 'notistack';
import { ApiRequest } from 'GlobalFunctions';
import EmptyElement from 'Elements/EmptyElement';

export default function PayrollEditor(props: any) {
  const {
    setLoading,
    openEditor,
    setOpenEditor,
    payroll_id,
  } = props;

  const { enqueueSnackbar } = useSnackbar();

  const [shiftList, setShiftList] = useState<any[]>([]);

  useEffect(() => {
    if (payroll_id && openEditor) {
      loadShifts();
    }
  }, [payroll_id, openEditor]);

  const loadShifts = async () => {
    setLoading(true);

    await ApiRequest({
      url: '/payroll/shifts_list',
      method: 'get',
      query: { payroll_id },
      setResponse: (response: any) => {
        if (response.status === 'success') {
          setShiftList(response.list);
        } else {
          enqueueSnackbar('Error loading shifts', { variant: 'error' });
        }
        setLoading(false);
      },
    });
  };

  return (
    <Dialog
      open={openEditor}
      onClose={() => setOpenEditor(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      maxWidth="md"
      fullWidth
    >
      <div className="DialogContainer" style={{ display: 'flex', flexDirection: 'column', padding: '20px' }}>
        {shiftList.length !== 0 ? (
          <TableContainer>
            <Table aria-label="sticky table">
              <TableHead>
                <TableRow>
                  <TableCell>SHIFT ID</TableCell>
                  <TableCell>EMPLOYEE</TableCell>
                  <TableCell>TIME</TableCell>
                  <TableCell>STATUS</TableCell>
                  <TableCell>MODIFIED</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {shiftList.map((shift, i) => (
                  <TableRow key={i} hover role="checkbox">
                    <TableCell>{shift.shift_id}</TableCell>
                    <TableCell>
                      {shift.name} {shift.last_name}<br />
                      <small>{shift.role}</small>
                    </TableCell>
                    <TableCell>
                      {shift.hours_diff} hours<br />
                      <small>{shift.minutes_diff} minutes</small>
                    </TableCell>
                    <TableCell>
                      <Chip label={shift.shift_end_time !== '00:00:00' ? 'Completed' : 'In Progress'} color={shift.shift_end_time !== '00:00:00' ? "primary" : "secondary"} variant="outlined" size='small' />
                    </TableCell>
                    <TableCell>{shift.modified_time}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <div className="emptyList">
            <EmptyElement title="No hay turnos registrados" subtitle="Empieza creando un nuevo turno" />
          </div>
        )}

        <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '20px' }}>
          <Button
            onClick={() => setOpenEditor(false)}
            variant="outlined"
            style={{ marginRight: '10px' }}
          >
            CLOSE
          </Button>
        </div>
      </div>
    </Dialog>
  );
}
