import { Button, Dialog, DialogContent, FormControl, InputLabel, MenuItem, Select, TextField, IconButton, Menu, FormControlLabel, Radio, RadioGroup } from '@mui/material';
import { useState, useEffect, useCallback } from 'react';
import { LocalizationProvider, StaticDatePicker } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { BsTrash } from "react-icons/bs";
import TuneIcon from '@mui/icons-material/Tune';
import DateRange from 'Elements/DateRange';
import moment from 'moment';
import { MdOutlineTune } from "react-icons/md";

import _ from 'lodash';
import { IoIosSearch } from "react-icons/io";
import { MdKeyboardArrowDown } from "react-icons/md";



interface Props {
  filters: Array<any>;
  setFilters: any;
  title?: string;
  searchBy?: Array<string>;
  setSearch?: (search: string) => void;
}

export default function FiltersByTable(props: Props) {
  const [open, setOpen] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState<string>('');
  const [filterCondition, setFilterCondition] = useState<string>('contains');
  const [filterValue, setFilterValue] = useState<string>('');
  const [search, setSearch] = useState<string>('');
  const [date, setDate] = useState({
    startDate: moment().startOf('month').format('YYYY-MM-DD'),
    endDate: moment().endOf('month').format('YYYY-MM-DD'),
  });
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [sortBy, setSortBy] = useState<string>('');

  useEffect(() => {
    if (props.filters.length > 0) {
      const appliedFilter = props.filters.find(f => f.action === 'filters');
      if (appliedFilter) {
        setSelectedFilter(appliedFilter.key);
        setFilterCondition(appliedFilter.condition === '=' ? 'contains' : 'not_contains');
        if (appliedFilter.type === 'date_range') {
          setDate(appliedFilter.value);
        } else {
          setFilterValue(appliedFilter.value);
        }
      } else {
        setSelectedFilter(props.filters[0].name);
        setFilterCondition('contains');
        setFilterValue('');
        setDate({
          startDate: moment().startOf('month').format('YYYY-MM-DD'),
          endDate: moment().endOf('month').format('YYYY-MM-DD')
        });
      }
    }
  }, [props.filters]);

  const handleApplyFilters = () => {
    const filter = props.filters.find(f => f.name === selectedFilter);

    if (filter) {
      const newFilter = {
        key: filter.name,
        value: filter.type === 'date_range' ? date : filterValue,
        action: "filters",
        condition: filterCondition === 'contains' ? '=' : '!=',
        type: filter.type,
        label: filter.label,
      };

      props.setFilters((prevFilters: any) => {
        const nonFilters = prevFilters.filter((f:any) => f.action !== 'filters');
        return [...nonFilters, newFilter];
      });
    }

    setOpen(false);
  };

  const updateSearchFilters = useCallback(
    _.debounce((searchTerm) => {
      if (searchTerm.length >= 3) {
        const searchFilters = props.searchBy?.map((searchKey) => ({
          key: searchKey,
          value: searchTerm,
          action: "search",
          condition: '=',
          type: 'search',
          label: 'Search',
        })) || [];

        props.setFilters((prevFilters: any) => [
          ...prevFilters.filter((f: any) => f.action !== 'search'),
          ...searchFilters
        ]);
      } else {
        props.setFilters((prevFilters: any) => prevFilters.filter((f: any) => f.action !== 'search'));
      }
    }, 300), [props.searchBy, props.setFilters]
  );

  const handleSearchChange = (e: any) => {
    const value = e.target.value;
    setSearch(value);
    updateSearchFilters(value);
  };

  const handleClearDialogFilters = () => {
    props.setFilters((prevFilters: any) => prevFilters.filter((f: any) => f.action !== 'filters'));
    setSelectedFilter(props.filters.length > 0 ? props.filters[0].name : '');
    setFilterCondition('contains');
    setFilterValue('');
    setDate({
      startDate: moment().startOf('month').format('YYYY-MM-DD'),
      endDate: moment().endOf('month').format('YYYY-MM-DD')
    });
  };

  const handleSortByClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleSortByClose = () => {
    setAnchorEl(null);
  };

  const handleSortByChange = (value: string) => {
    setSortBy(value);
    const newSort = {
      key: value,
      value: '',
      action: 'order',
      condition: '',
      type: 'order',
      label: props.filters.find(f => f.name === value)?.label,
    };

    props.setFilters((prevFilters: any) => {
      const nonOrders = prevFilters.filter((f:any) => f.action !== 'order');
      return [...nonOrders, newSort];
    });

    setAnchorEl(null);
  };

  return (
    <>
    <div className='filters'>
      <Button
        startIcon={<MdOutlineTune />}
        onClick={() => setOpen(true)}
        style={{ color: '#424242', padding: '5px 20px', fontWeight: '400 !important' }}
        className='filtersButton'
      >
        Filter
      </Button>
    </div>
      
      

      <Dialog 
        className='filtersDialog' 
        open={open} onClose={() => setOpen(false)} 
        fullWidth 
        PaperProps={{ sx: { borderRadius: "10px" } }}
      >
        <DialogContent className='forced_box_editor'>
          <h2 className='filterTitle'>{props.title || 'Filters'}</h2>
          <div className='filtersForm'>
            
            <div className='inputsRowOutlined'>

            <FormControl style={{ width: '350px' }}>
              <TextField
                select
                id="key"
                label="Select Filter"
                value={selectedFilter}
                inputProps={{
                  shrink: true,
                }}
                onChange={(e) => setSelectedFilter(e.target.value)}
                hiddenLabel
              >
                {props.filters.map((filter, index) => (
                    <MenuItem key={index} value={filter.name}>{filter.label}</MenuItem>
                  ))}
              </TextField>
            </FormControl>


              {props.filters.find(f => f.name === selectedFilter)?.type === "text" && (
                <TextField
                  label="Enter Text"
                  variant="outlined"
                  fullWidth
                  inputProps={{
                    shrink: true,
                  }}
                  value={filterValue}
                  onChange={(e) => setFilterValue(e.target.value)}
                />
              )}
              {props.filters.find(f => f.name === selectedFilter)?.type === "date" && (
                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <StaticDatePicker
                    label="Select Date"
                    displayStaticWrapperAs="mobile"
                    value={filterValue ? moment(filterValue) : new Date()}
                    showToolbar={false}
                    onChange={(newValue: any) => {
                      if (newValue) {
                        setFilterValue(newValue.format('YYYY-MM-DD'));
                      }
                    }}
                    renderInput={(params) => <TextField {...params} />}
                  />
                </LocalizationProvider>
              )}
              {props.filters.find(f => f.name === selectedFilter)?.type === "date_range" && (
                <DateRange
                  className='filters_datePicker'
                  label='Date range'
                  variant="outlined"
                  fullWidth
                  value={date}
                  format="MMMM DD, yyyy"
                  style={{ maxWidth: '280px' }}
                  onChange={(e: any) => {
                    if (e.startDate && e.endDate) {
                      const startDate = moment(e.startDate).format('YYYY-MM-DD');
                      const endDate = moment(e.endDate).format('YYYY-MM-DD');
                      setDate({ startDate, endDate });
                    }
                  }}
                  variantDatePicker="side-by-side"
                />
              )}
            </div>

            <div className='buttons'>
              <Button
                color='secondary'
                style={{whiteSpace: 'nowrap'}}
                variant="contained" 
                onClick={handleApplyFilters}
              >
                Apply Filter
              </Button>
            </div>
          </div>
          
        </DialogContent>
      </Dialog>
    </>
  );
}
