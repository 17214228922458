import * as React from 'react';
import MenuButton from './MenuButton';
import PointOfSaleIcon from '@mui/icons-material/PointOfSale';

export default function CashierMenu(props: any) {
    const { setLoading } = props;

    return (
        <>
            <div className='menu_button_container'>
                <MenuButton 
                    label="Sell" 
                    to="/sell" 
                    startIcon={<PointOfSaleIcon />} 
                    setLoading={setLoading} 
                />
            </div>
        </>
    );
}