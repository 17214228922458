import { useEffect, useState } from 'react';
import Box from 'Elements/Box';
import { Button, IconButton, Pagination, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Menu, MenuItem, TextField } from '@mui/material';
import { useSnackbar } from 'notistack';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import AddIcon from '@mui/icons-material/Add';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import EmptyElement from 'Elements/EmptyElement';
import { ApiRequest } from 'GlobalFunctions';
import AlertDialog from 'Elements/AlertDialog';
import FiltersByTable from 'Elements/Filters/FiltersByTable';
import FiltersApplied from 'Elements/Filters/FiltersApplied';
import { Link, useParams } from 'react-router-dom';

import table_free from 'Assets/img/table_free.svg';
import table_ocuppied from 'Assets/img/table_occupied.svg';

import { BsGrid3X2 } from "react-icons/bs";
import { MdOutlineViewList } from "react-icons/md";


export default function Tables(props: any) {
  const { setLoading } = props;
  const { enqueueSnackbar } = useSnackbar();
  const { restaurant_id } = useParams();

  const [tablesList, setTablesList] = useState<any[]>([]);
  const [viewMode, setViewMode] = useState<'grid' | 'list'>('grid');
  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    loadList();
  }, []);

  const loadList = async () => {
    setLoading(true);

    await ApiRequest({
      url: '/tables/ordersTables',
      method: 'get',
      query: { 
        restaurant_id: restaurant_id,
      },
      setResponse: (response: any) => {
        setTablesList(response.list);
        setLoading(false);
      },
    });
  };

  const filteredTables = tablesList.filter(table => 
    table.description.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <>

      <div id="TablesList" className="ModuleContainer">
        <div className="ModuleWrapper">
          <div id="tables_grid_in">
            
              

              <h2>Tables</h2>

              <div className="table_filters">
                <IconButton 
                  onClick={() => setViewMode('grid')}
                  sx={{
                    backgroundColor: viewMode === 'grid' ? '#32B86C' : '#A19B9B',
                    color: '#fff',
                    borderRadius: '4px',
                    '&:hover': {
                      backgroundColor: viewMode === 'grid' ? '#32B86C' : '#A19B9B',
                    }
                  }}
                >
                  <BsGrid3X2 />
                </IconButton>
                <IconButton 
                  onClick={() => setViewMode('list')}
                  sx={{
                    backgroundColor: viewMode === 'list' ? '#32B86C' : '#A19B9B',
                    color: '#fff',
                    borderRadius: '4px',
                    '&:hover': {
                      backgroundColor: viewMode === 'list' ? '#32B86C' : '#A19B9B',
                    }
                  }}
                >
                  <MdOutlineViewList />
                </IconButton>
                <TextField 
                  className="table_filters_search" 
                  label="Table search"
                  fullWidth
                  size='small'
                  variant="outlined"
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                />
              </div>

              {filteredTables.length !== 0 ? (
                <div className={`tables_${viewMode}`}>
                  {filteredTables.map((table, i) => (
                    table.is_occupied ? (
                      <Link className='table_card_wrapper' to={`/sell/${table.restaurant_id}/${table.table_id}/${table.order_id}`} key={i}>
                        <Box className="table_card">
                          <img src={table_ocuppied} alt="table occupied" />
                          <h2>{table.description}</h2>
                        </Box>
                      </Link>
                    ) : (
                      <Link className='table_card_wrapper' to={`/sell/${table.restaurant_id}/${table.table_id}`} key={i}>
                        <Box className="table_card">
                          <img src={table_free} alt="table available" />
                          <h2>{table.description}</h2>
                        </Box>
                      </Link>
                    )
                  ))}
                </div>
              ) : (
                <div className="emptyList">
                  <EmptyElement title="No restaurants registered" subtitle="Start by adding a new restaurant" />
                </div>
              )}
          </div>
        </div>

        

        
      </div>
    </>
  );
}